import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DotdotdotButton } from 'components/DotdotdotButton'
import { Dropdown, DropdownContent } from 'components/Dropdown'
import { EvaluationProrityEditor } from 'components/EvaluationProrityEditor'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from 'components/Form/Select/SelectItemRenderer'
import { HorizontalLoadingIndicator } from 'components/HorizontalLoadingIndicator'
import { Icon } from 'components/Icon'
import { ListTopBar } from 'components/ListTopBar'
import { AccessDeniedHandler } from 'components/AccessDeniedHandler'
import { useRequiredParam } from 'utils/misc'
import { route } from 'utils/routes'
import { Layout } from '../components/Layout'
import '../css/ModelShow.css'
import '../css/Spinner.css'
import {
    useTeamPermissionsForResourceQuery,
    useModelQuery,
} from '../utils/queries'
import { ModelQuestions } from '../components/ModelQuestions'
import { BreadcrumbsToolbar } from '../components/Toolbar/BreadcrumbsToolbar'
import {
    useDeleteModel,
    useUploadModelFromCSV,
    useUpdateModel,
} from '../utils/mutations'
import { confirmModal } from '../components/modals/ConfirmationModal'
import { useShareResourceModal } from '../components/modals/ShareResourceModal'
import { useNameInputModal } from '../components/modals/NameInputModal'
import { Button } from '../components/Button'
import { showToast, ToastType } from '../components/Toast/Toast'
import { ReadOnly } from '../components/ReadOnly'

const ServerIcon = styled.div.attrs((props) => ({
    ...props,
    children: <Icon name="server" />,
}))`
    svg {
        width: 14px;
        height: 14px;
    }
`

const RootInner = styled.div`
    display: grid;
    gap: 48px;
    margin: 0 auto;
    max-width: 778px;
`

const Root = styled.div`
    padding: 40px 40px 96px;
`

export function ModelShowPage() {
    const id = useRequiredParam('id')

    const { t } = useTranslation()

    const modelQuery = useModelQuery(id)

    const model = modelQuery.data || undefined

    const deleteModel = useDeleteModel()

    const uploadModelFromCSV = useUploadModelFromCSV(id)

    const { data: permission, isFetching: teamPermissionIsLoading } =
        useTeamPermissionsForResourceQuery({
            modelId: id,
        })

    const shareResouceModal = useShareResourceModal()

    const triggerShareResource = useCallback(() => {
        if (!model) {
            return
        }
        shareResouceModal(model.name, { modelId: model.id })
    }, [model, shareResouceModal])

    const updateModel = useUpdateModel()
    const openNameInputModal = useNameInputModal()

    const handleCsvUpload = useCallback(async () => {
        try {
            const confirmed = await confirmModal.pop({
                title: 'Import CSV',
                content: `Importing a csv will replace any current questions and ${t('model.outcomes').toLowerCase()}. Are you sure you want to proceed?`,
                confirmButtonText: 'Import',
                cancelButtonText: 'Cancel',
            })

            if (!confirmed) {
                return
            }

            const fileInput = document.createElement('input')
            fileInput.type = 'file'
            fileInput.accept = '.csv'
            fileInput.onchange = async (event) => {
                const file = (event.target as HTMLInputElement).files?.[0]
                if (file) {
                    try {
                        await uploadModelFromCSV.mutateAsync(file)

                        showToast({
                            title: 'CSV imported successfully',
                            type: ToastType.Success,
                        })
                    } catch (e) {
                        // do nothing as the error is handled in the mutation
                    }
                }
            }
            fileInput.click()
        } catch (e) {
            // do nothing
        }
    }, [uploadModelFromCSV])

    return (
        <AccessDeniedHandler>
            <Layout>
                <ListTopBar />
                <HorizontalLoadingIndicator
                    loading={modelQuery.isFetching || deleteModel.isPending}
                />
                {model && (
                    <>
                        <BreadcrumbsToolbar
                            currentPageName={model.name}
                            listPage={{
                                name: t('model.plural'),
                                route: route('models'),
                                icon: <ServerIcon />,
                            }}
                            lhsAux={
                                <>
                                    <Dropdown
                                        trigger={({ onClick }) => (
                                            <DotdotdotButton
                                                onClick={onClick}
                                            />
                                        )}
                                    >
                                        {(dismiss) => (
                                            <DropdownContent $maxWidth="560px">
                                                <SelectBasicItem
                                                    type="button"
                                                    disabled={
                                                        teamPermissionIsLoading ||
                                                        permission !== 'Owner'
                                                    }
                                                    onClick={() => {
                                                        dismiss()
                                                        triggerShareResource()
                                                    }}
                                                >
                                                    <SelectBasicItemIconWrap>
                                                        <Icon name="users" />
                                                    </SelectBasicItemIconWrap>
                                                    Manage permissions
                                                </SelectBasicItem>
                                                <SelectBasicItem
                                                    type="button"
                                                    disabled={
                                                        teamPermissionIsLoading ||
                                                        permission === 'Viewer'
                                                    }
                                                    onClick={() => {
                                                        dismiss()
                                                        openNameInputModal({
                                                            modalTitle: `Rename ${t('model.singular').toLowerCase()}`,
                                                            buttonCtaText:
                                                                'Save',
                                                            buttonCtaSavingText:
                                                                'Saving',
                                                            currentName:
                                                                model.name,
                                                            onConfirm: async (
                                                                newName: string
                                                            ) => {
                                                                await updateModel.mutateAsync(
                                                                    {
                                                                        id,
                                                                        name: newName,
                                                                    }
                                                                )
                                                            },
                                                        })
                                                    }}
                                                >
                                                    <SelectBasicItemIconWrap>
                                                        <Icon name="edit" />
                                                    </SelectBasicItemIconWrap>
                                                    Rename
                                                </SelectBasicItem>
                                                <SelectBasicItem
                                                    type="button"
                                                    disabled={
                                                        teamPermissionIsLoading ||
                                                        permission !== 'Owner'
                                                    }
                                                    onClick={async () => {
                                                        try {
                                                            await confirmModal.pop(
                                                                {
                                                                    title: `Delete ${t('model.singular')}`,
                                                                    content: `Are you sure you want to delete this ${t('model.singular').toLowerCase()}?`,
                                                                    cancelButtonText:
                                                                        'Cancel',
                                                                    confirmButtonText:
                                                                        'Delete',
                                                                    onResolve:
                                                                        () => {
                                                                            deleteModel.mutate(
                                                                                model
                                                                            )
                                                                        },
                                                                }
                                                            )
                                                        } catch (error) {
                                                            // do nothing
                                                        }
                                                        dismiss()
                                                    }}
                                                >
                                                    <SelectBasicItemIconWrap>
                                                        <Icon name="trash" />
                                                    </SelectBasicItemIconWrap>
                                                    Delete
                                                </SelectBasicItem>
                                            </DropdownContent>
                                        )}
                                    </Dropdown>
                                </>
                            }
                            rhsAux={
                                <Button
                                    onClick={handleCsvUpload}
                                    $variant="secondary"
                                    disabled={
                                        teamPermissionIsLoading ||
                                        permission === 'Viewer'
                                    }
                                >
                                    <div className="d-flex align-items-center">
                                        <Icon name="arrowCircleDown" />
                                        <span className="m-l-5">
                                            Import CSV
                                        </span>
                                    </div>
                                </Button>
                            }
                        />
                        {!teamPermissionIsLoading &&
                            permission === 'Viewer' && <ReadOnly />}
                        <Root>
                            <RootInner>
                                <h1>{model.name}</h1>
                                <div>
                                    <h3>{t('model.outcomes')}</h3>
                                    <EvaluationProrityEditor modelId={id} />
                                </div>
                                <ModelQuestions />
                            </RootInner>
                        </Root>
                    </>
                )}
            </Layout>
        </AccessDeniedHandler>
    )
}
