import PasswordValidator from 'password-validator'

const validator = new PasswordValidator()
    .is()
    .min(16)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols()

export const validatePassword = (password: string): boolean => {
    return validator.validate(password) as boolean
}
