import { BaseItem } from 'components/dropdowns/SearchDropdown/itemRenderers/BaseItem'
import React, { ButtonHTMLAttributes } from 'react'
import { useNavigate } from 'react-router'
import { DataRoom } from 'silta-ai-backend'
import styled from 'styled-components'
import { route } from 'utils/routes'

const Content = styled.div`
    min-width: 0;
`

interface DataRoomItemProps
    extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'value'> {
    value: DataRoom
}

export function DataRoomItem({
    value,
    onClick: onClickProp,
    ...rest
}: DataRoomItemProps) {
    const navigate = useNavigate()

    return (
        <BaseItem
            {...rest}
            onClick={(e) => {
                onClickProp?.(e)

                navigate(route('dataRoom', value.id))
            }}
        >
            <Content>{value.name}</Content>
        </BaseItem>
    )
}
