import { useEffect, useMemo } from 'react'
import { DataRoomDocument, ListDataRoomDocumentsParams } from 'silta-ai-backend'
import { invalidateDataRoomDocumentsQuery } from './queries'
import { apiClient } from './clients'

export function useRunningDocumentsProcessingPollEffect(
    dataRoomId: string,
    documents: DataRoomDocument[],
    additionalQueryParams?: Omit<ListDataRoomDocumentsParams, 'dataRoomId'>
) {
    const processedDocuments = useMemo(() => {
        return documents.filter((doc) => doc.status === 'running')
    }, [documents])

    useEffect(
        function pollDocumentsIfRunningProcessing() {
            let mounted = true

            if (dataRoomId && processedDocuments.length > 0) {
                ;(async () => {
                    for (;;) {
                        try {
                            const innerDocuments = await Promise.all(
                                processedDocuments.map(async (doc) =>
                                    apiClient.getDataRoomDocument(doc.id)
                                )
                            )

                            const innerProccessedDocuments =
                                innerDocuments.filter(
                                    (doc) => doc.status === 'running'
                                )

                            if (!mounted) {
                                return
                            }

                            if (
                                processedDocuments.length !==
                                innerProccessedDocuments.length
                            ) {
                                invalidateDataRoomDocumentsQuery(
                                    dataRoomId,
                                    additionalQueryParams
                                )
                                return
                            }
                        } catch (e) {
                            console.warn(
                                'Getting data room documents failed',
                                e
                            )
                        }

                        await new Promise<void>((resolve) => {
                            setTimeout(resolve, 5000)
                        })

                        if (!mounted) {
                            return
                        }
                    }
                })()
            }

            return () => {
                mounted = false
            }
        },
        [dataRoomId, processedDocuments]
    )
}
