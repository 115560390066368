import React, { useCallback, useState } from 'react'
import { toaster } from 'toasterhea'
import cx from 'classnames'
import { Field, Form, Formik } from 'formik'
import { object, string } from 'yup'
import { BaseModal, BaseModalProps } from './BaseModal'
import { Button } from '../Button'
import { RejectionReason } from '../../utils/exceptions'
import { Icon } from '../Icon'
import { Layer } from '../../utils/layers'

import {
    EditorModalCloseButton,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalSpinner,
    EditorModalSpinnerContainer,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'
import { TextField } from '../Form/TextField'

interface NameInputModalProps extends Omit<BaseModalProps, 'children'> {
    modalTitle: string
    placeholderText?: string
    buttonCtaText: string
    buttonCtaSavingText: string
    additionalText?: string
    currentName?: string
    onConfirm: (newName: string) => Promise<void>
}

export const NameInputValidationSchema = object().shape({
    name: string().required('Name is required'),
})

const NameInputModal = ({
    modalTitle,
    placeholderText = 'Add a name',
    buttonCtaText,
    buttonCtaSavingText,
    currentName,
    additionalText,
    onConfirm,
    ...props
}: NameInputModalProps) => {
    const [newName, setNewName] = useState<string>(currentName || '')
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = useCallback(async () => {
        try {
            setIsLoading(true)
            await onConfirm(newName)
            props.onResolve?.()
        } catch (e) {
            console.error('Error saving team', e)
        } finally {
            setIsLoading(false)
        }
    }, [onConfirm, newName, props.onResolve])

    return (
        <BaseModal {...props}>
            <EditorModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>{modalTitle}</EditorModalTitle>
                    <EditorModalCloseButton
                        onClick={() =>
                            props.onReject?.(RejectionReason.CloseButton)
                        }
                    >
                        <Icon name="close2" />
                    </EditorModalCloseButton>
                </EditorModalTitleContainer>
                <EditorModalContentContainer className="p-b-20">
                    <Formik
                        onSubmit={() => {
                            handleSubmit()
                        }}
                        initialValues={{ name: currentName }}
                        validationSchema={NameInputValidationSchema}
                    >
                        <>
                            <Form className={cx('d-flex', 'g-10', 'm-t-20')}>
                                <Field name="name">
                                    {({
                                        field,
                                        form,
                                    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    any) => (
                                        <TextField
                                            {...field}
                                            value={newName || ''}
                                            onChange={(e) => {
                                                field.onChange(e)
                                                setNewName(e.target.value)
                                            }}
                                            placeholder={placeholderText}
                                            errorMessage={
                                                form.touched[field.name] &&
                                                form.errors[field.name]
                                            }
                                            containerClassName="flex-1"
                                            autoFocus
                                        />
                                    )}
                                </Field>
                                <Button
                                    onClick={async () => {
                                        try {
                                            setIsLoading(true)
                                            await onConfirm(newName)
                                            props.onResolve?.()
                                        } catch (e) {
                                            console.error(
                                                'Error saving name',
                                                e
                                            )
                                        } finally {
                                            setIsLoading(false)
                                        }
                                    }}
                                    disabled={!newName || isLoading}
                                    $size="large"
                                >
                                    {isLoading ? (
                                        <EditorModalSpinnerContainer>
                                            {buttonCtaSavingText}
                                            <EditorModalSpinner name="spinner" />
                                        </EditorModalSpinnerContainer>
                                    ) : (
                                        buttonCtaText
                                    )}
                                </Button>
                            </Form>
                            {additionalText && (
                                <p className="secondary">{additionalText}</p>
                            )}
                        </>
                    </Formik>
                </EditorModalContentContainer>
            </EditorModalRoot>
        </BaseModal>
    )
}

const nameInputModal = toaster(NameInputModal, Layer.Modal)

export const useNameInputModal = () => {
    return useCallback(
        async ({
            onConfirm,
            modalTitle,
            buttonCtaText,
            buttonCtaSavingText,
            additionalText,
            currentName,
        }: NameInputModalProps) => {
            try {
                await nameInputModal.pop({
                    onConfirm,
                    modalTitle,
                    buttonCtaText,
                    buttonCtaSavingText,
                    additionalText,
                    currentName,
                })
            } catch (e) {
                // do nothing
            }
        },
        []
    )
}
