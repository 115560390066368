import React, { useCallback, useMemo, useState } from 'react'
import { toaster } from 'toasterhea'
import { Field, Form, Formik } from 'formik'
import { object, string } from 'yup'
import { useTranslation } from 'react-i18next'
import { BaseModal, BaseModalProps } from './BaseModal'
import { Button } from '../Button'
import { RejectionReason } from '../../utils/exceptions'
import { Icon } from '../Icon'
import { Layer } from '../../utils/layers'
import { useCreateReport } from '../../utils/mutations'

import {
    EditorModalButtonsContainer,
    EditorModalCloseButton,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalSpinner,
    EditorModalSpinnerContainer,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'
import { TextField } from '../Form/TextField'
import {
    useAssessmentQuery,
    useAssessmentsQuery,
    useReportTemplatesQuery,
} from '../../utils/queries'
import { Select } from '../Form/Select/Select'
import { useRunReportWithFaultyDocumentsHandling } from '../../utils/reports'

interface CreateReportModalProps extends Omit<BaseModalProps, 'children'> {
    assessmentId?: string
    onConfirm: ({
        name,
        reportTemplateId,
        assessmentId,
    }: {
        name: string
        reportTemplateId: string
        assessmentId: string
    }) => Promise<void>
}

const ReportCreateValidationSchema = object().shape({
    name: string().required('Field is required'),
    reportTemplateId: string().required('Field is required'),
    assessmentId: string().required('Field is required'),
})

const CreateReportModal = ({
    assessmentId: initialAssessmentId,
    onConfirm,
    ...props
}: CreateReportModalProps) => {
    const [name, setName] = useState('')
    const [assessmentId, setAssessmentId] = useState(initialAssessmentId || '')
    const [reportTemplateId, setReportTemplateId] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { data: selectedAssessment } = useAssessmentQuery({ assessmentId })
    const { t } = useTranslation()

    const {
        data: assessments = [],
        isLoading: isAssessmentsLoading,
        isFetchingNextPage: isFetchingMoreAssessments,
        hasNextPage: hasMoreAssessments,
        fetchNextPage: fetchMoreAssessments,
    } = useAssessmentsQuery({})
    const assessmentOptions = useMemo(
        () =>
            assessments.map((assessment) => ({
                label: assessment.name,
                value: assessment.id,
            })),
        [assessments]
    )
    const {
        data: reportTemplates = [],
        isLoading: isReportTemplatesLoading,
        isFetchingNextPage: isFetchingMoreReportTemplates,
        hasNextPage: hasMoreReportTemplates,
        fetchNextPage: fetchMoreReportTemplates,
    } = useReportTemplatesQuery({})
    const reportTemplateOptions = useMemo(
        () =>
            reportTemplates.map((reportTemplate) => ({
                label: reportTemplate.name,
                value: reportTemplate.id,
            })),
        [reportTemplates]
    )

    return (
        <BaseModal {...props}>
            <EditorModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>
                        Create {t('report.singular').toLowerCase()}
                    </EditorModalTitle>
                    <EditorModalCloseButton
                        onClick={() =>
                            props.onReject?.(RejectionReason.CloseButton)
                        }
                    >
                        <Icon name="close2" />
                    </EditorModalCloseButton>
                </EditorModalTitleContainer>
                <EditorModalContentContainer>
                    <p>
                        {t('report.plural')} are generated by the AI based on an
                        {t('assessment.singular').toLowerCase()} and a{' '}
                        {t('reportTemplate.singular').toLowerCase()}.
                    </p>
                    <Formik
                        onSubmit={() => {
                            // todo
                        }}
                        initialValues={{}}
                        validationSchema={ReportCreateValidationSchema}
                    >
                        <Form>
                            <Field name="name">
                                {({
                                    field,
                                    form,
                                }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                any) => (
                                    <TextField
                                        {...field}
                                        value={name || ''}
                                        containerClassName="m-b-30"
                                        label={`${t('report.singular')} name`}
                                        errorMessage={
                                            form.touched[field.name] &&
                                            form.errors[field.name]
                                        }
                                        placeholder={`Enter a title for the ${t('report.singular').toLowerCase()}`}
                                        onChange={(e) => {
                                            field.onChange(e)
                                            setName(e.target.value)
                                        }}
                                    />
                                )}
                            </Field>
                            <Field name="assessmentId">
                                {({
                                    field,
                                    form,
                                }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                any) => (
                                    <Select
                                        {...field}
                                        value={assessmentId}
                                        containerClassName="m-b-30"
                                        errorMessage={
                                            form.touched[field.name] &&
                                            form.errors[field.name]
                                        }
                                        label={t('assessment.singular')}
                                        valueLabels={
                                            selectedAssessment
                                                ? {
                                                      [selectedAssessment.id]:
                                                          selectedAssessment.name,
                                                  }
                                                : {}
                                        }
                                        loadingOptions={isAssessmentsLoading}
                                        loadingMoreOptions={
                                            isFetchingMoreAssessments
                                        }
                                        hasMoreOptions={hasMoreAssessments}
                                        onFetchMoreClick={fetchMoreAssessments}
                                        options={assessmentOptions}
                                        placeholder={`Select an ${t('assessment.singular').toLowerCase()}`}
                                        onChange={(selection: string) => {
                                            form.setFieldValue(
                                                field.name,
                                                selection
                                            )
                                            setAssessmentId(selection)
                                        }}
                                    />
                                )}
                            </Field>
                            <Field name="reportTemplateId">
                                {({
                                    field,
                                    form,
                                }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                any) => (
                                    <Select
                                        {...field}
                                        value={reportTemplateId}
                                        containerClassName="m-b-30"
                                        errorMessage={
                                            form.touched[field.name] &&
                                            form.errors[field.name]
                                        }
                                        label={t('reportTemplate.singular')}
                                        loadingOptions={
                                            isReportTemplatesLoading
                                        }
                                        loadingMoreOptions={
                                            isFetchingMoreReportTemplates
                                        }
                                        hasMoreOptions={hasMoreReportTemplates}
                                        onFetchMoreClick={
                                            fetchMoreReportTemplates
                                        }
                                        options={reportTemplateOptions}
                                        placeholder={`Select a ${t('reportTemplate.singular').toLowerCase()}`}
                                        onChange={(selection: string) => {
                                            form.setFieldValue(
                                                field.name,
                                                selection
                                            )
                                            setReportTemplateId(selection)
                                        }}
                                    />
                                )}
                            </Field>
                        </Form>
                    </Formik>
                </EditorModalContentContainer>
                <EditorModalButtonsContainer>
                    <Button
                        $variant="secondary"
                        onClick={() =>
                            props.onReject?.(RejectionReason.CancelButton)
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            try {
                                setIsLoading(true)
                                await onConfirm({
                                    name,
                                    assessmentId,
                                    reportTemplateId,
                                })
                                props.onResolve?.()
                            } catch (e) {
                                console.error('Error creating report', e)
                            } finally {
                                setIsLoading(false)
                            }
                        }}
                        disabled={
                            !name ||
                            !assessmentId ||
                            !reportTemplateId ||
                            isLoading
                        }
                    >
                        {isLoading ? (
                            <EditorModalSpinnerContainer>
                                Saving
                                <EditorModalSpinner name="spinner" />
                            </EditorModalSpinnerContainer>
                        ) : (
                            'Save'
                        )}
                    </Button>
                </EditorModalButtonsContainer>
            </EditorModalRoot>
        </BaseModal>
    )
}

const modal = toaster(CreateReportModal, Layer.Modal)

export const useCreateReportModal = () => {
    const createReport = useCreateReport()
    const { handleRunReport } = useRunReportWithFaultyDocumentsHandling()

    return useCallback(
        async (assessmentId?: string) => {
            try {
                await modal.pop({
                    assessmentId,
                    onConfirm: async ({
                        name,
                        reportTemplateId,
                        assessmentId,
                    }) => {
                        try {
                            const report = await createReport.mutateAsync({
                                name,
                                reportTemplateId,
                                assessmentId,
                            })
                            handleRunReport(report.id)
                        } catch (e) {
                            console.error('Error creating report', e)
                        }
                    },
                })
            } catch (e) {
                // do nothing
            }
        },
        [createReport]
    )
}
