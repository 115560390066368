import React from 'react'
import styled from 'styled-components'
import { User } from 'silta-ai-backend'
import { UserAvatar } from './UserAvatar'
import { UserAvatarPlaceholder } from './UserAvatarPlaceholder'

import { Icon } from './Icon'
import { themeVariables } from '../themes/themeVariables'

const PrefixIcon = styled(Icon)`
    color: ${themeVariables.colors.secondary};
    margin-right: 8px;
`

const Avatar = styled.div`
    margin-right: 8px;
    display: flex;
    align-items: center;
`

export const ProjectOfficerImage = ({
    assignedToId,
    users,
}: {
    assignedToId: string
    users: User[]
}) => {
    if (!assignedToId || !users) {
        return <PrefixIcon name="userCircle" />
    }
    const user = users?.find((user) => user.id === assignedToId)
    if (!user) {
        return <PrefixIcon name="userCircle" />
    }
    return user?.avatarBlobId ? (
        <Avatar>
            <UserAvatar size={16} avatarBlobId={user.avatarBlobId} />
        </Avatar>
    ) : (
        <Avatar>
            <UserAvatarPlaceholder size={16} userName={user.name} />
        </Avatar>
    )
}
