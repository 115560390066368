import React, { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { Container, toaster } from 'toasterhea'
import AbstractToast from './AbstractToast'
import { themeVariables } from '../../themes/themeVariables'
import { Icon } from '../Icon'

import { Layer } from '../../utils/layers'

const CloseButton = styled.button`
    align-items: center;
    appearance: none;
    color: #525252;
    display: flex;
    flex-shrink: 0;
    height: 24px;
    justify-content: center;
    margin-left: 16px;
    width: 24px;
    padding: 0;
    outline: 0;
    border: 0;
    background: none;
`

const Dot = styled.div`
    background: #525252;
    height: 3px;
    margin-left: 8px;
    margin-right: 8px;
    width: 3px;
`

const Form = styled.form`
    align-items: start;
    display: flex;
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 400px;
`

const IconWrap = styled.div<{ $color?: string }>`
    height: 24px;
    width: 24px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 16px;
    color: ${({ $color = 'inherit' }) => $color};
    svg {
        color: ${({ $color = 'inherit' }) => $color};
    }
`

const Body = styled.div`
    flex-grow: 1;
    min-width: 0;
    padding: 3px 0;
    font-size: 14px;

    p,
    h4,
    ul,
    ol {
        font-size: inherit;
        overflow-wrap: break-word;
    }

    .description {
        margin-top: 10px;
    }

    h4 {
        margin: 0;
        line-height: normal;
        font-weight: ${themeVariables.typography.fontWeight.emphasized};
        font-size: ${themeVariables.typography.fontSizes.large};
    }

    p,
    ul,
    ol {
        margin: 8px 0 0;
        line-height: 20px;
        padding: 0;
    }

    ol {
        list-style-position: inside;
    }
`

const Buttons = styled.div`
    align-items: center;
    display: flex;
    line-height: normal;
    margin-top: 8px;
`

const Button = styled.button`
    background: none;
    border: 0;
    color: ${themeVariables.colors.brand};
    display: block;
    font-size: 14px;
    font-weight: ${themeVariables.typography.fontWeight.normal};
    outline: 0;
    padding: 0;

    &:hover {
        color: ${themeVariables.colors.brand};
    }

    :disabled {
        color: inherit;
        opacity: 0.5;
    }
`

export enum ToastType {
    Success = 'success',
    Warning = 'warn',
    Error = 'error',
}

interface Props {
    cancelLabel?: string
    desc?: ReactNode
    okLabel?: string
    onReject?: (reason?: unknown) => void
    onResolve?: () => void
    title?: string
    type?: ToastType
    canSubmit?: boolean
    autoCloseAfter?: number | boolean
}

const defaultAutoCloseAfter = 3

export default function Toast({
    type = ToastType.Warning,
    title,
    desc,
    okLabel,
    cancelLabel,
    onResolve,
    onReject,
    canSubmit = true,
    autoCloseAfter: autoCloseAfterProp = true,
}: Props) {
    const autoCloseAfter =
        okLabel == null && cancelLabel == null
            ? autoCloseAfterProp === true
                ? defaultAutoCloseAfter
                : autoCloseAfterProp
            : false

    useEffect(() => {
        if (autoCloseAfter === false) {
            return () => void 0
        }

        const timeoutId = setTimeout(
            () => void onReject?.(),
            autoCloseAfter * 1000
        )

        return () => void clearTimeout(timeoutId)
    }, [autoCloseAfter, onReject])

    return (
        <AbstractToast>
            <Form
                onSubmit={(e) => {
                    e.preventDefault()

                    if (canSubmit) {
                        onResolve?.()
                    }
                }}
            >
                <>
                    {type === ToastType.Success && (
                        <IconWrap $color={themeVariables.colors.success}>
                            <Icon name="toastSuccess" />
                        </IconWrap>
                    )}
                    {type === ToastType.Error && (
                        <IconWrap $color={themeVariables.colors.critical}>
                            <Icon name="toastError" />
                        </IconWrap>
                    )}
                    {type === ToastType.Warning && (
                        <IconWrap $color={themeVariables.colors.attention}>
                            <Icon name="toastWarning" />
                        </IconWrap>
                    )}
                </>
                <Body>
                    <h4>{title}</h4>
                    {typeof desc === 'string' ? (
                        <p className="description">{desc}</p>
                    ) : (
                        desc
                    )}
                    {(okLabel || cancelLabel) != null && (
                        <Buttons>
                            {okLabel != null && (
                                <Button type="submit" disabled={!canSubmit}>
                                    {okLabel}
                                </Button>
                            )}
                            {okLabel != null && cancelLabel != null && <Dot />}
                            {cancelLabel != null && (
                                <Button
                                    type="button"
                                    onClick={() => void onReject?.()}
                                >
                                    {cancelLabel}
                                </Button>
                            )}
                        </Buttons>
                    )}
                </Body>
                <CloseButton onClick={() => void onReject?.()} type="button">
                    <Icon name="close" />
                </CloseButton>
            </Form>
        </AbstractToast>
    )
}

export function showToast(props: Props) {
    const toast = toaster(Toast, Layer.Toast)
    return toast.pop(props).catch(() => {
        // do nothing
    })
}

export const ToastContainer = styled(Container)`
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-bottom: 24px;
    padding-right: 24px;
    position: fixed;
    z-index: 12;
`
