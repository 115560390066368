import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

type TextLinkVariant = 'primary' | 'secondary'

export const TextLink = styled(Link)<{
    $variant?: TextLinkVariant
    $disabled?: boolean
}>`
    font-weight: 600;
    cursor: pointer;
    ${({ $variant = 'primary' }) => {
        switch ($variant) {
            case 'secondary':
                return `
                    color: ${themeVariables.colors.primary};
                    text-decoration: underline;
                    text-decoration-style: dashed;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 2px;
                    &:hover {
                        color: ${themeVariables.colors.primary};
                    }
                `
            case 'primary':
            default:
                return `
                    color: ${themeVariables.colors.brand};
                    &:hover {
                        color: ${themeVariables.colors.brand};
                    }
                `
        }
    }}
    ${({ $disabled }) =>
        $disabled &&
        `
        pointer-events: none;
        cursor: not-allowed;
        color: ${themeVariables.colors.secondary};
    `}
`
