import { Icon } from 'components/Icon'
import React, { AllHTMLAttributes, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

export interface FormFieldProps {
    children?: ReactNode
    className?: string
    description?: ReactNode
    errorMessage?: ReactNode
    id?: string
    label?: ReactNode
    labelCta?: ReactNode
}

export const FormFieldRoot = styled.div``

const LabelWrap = styled.div`
    align-items: center;
    display: flex;
    width: 100%;
    height: ${themeVariables.typography.lineHeight.body};

    label {
        flex-grow: 1;
        font-weight: ${themeVariables.typography.fontWeight.bold};
        color: ${themeVariables.colors.primary};
    }
`

const LabelCtaWrap = styled.div``

const Description = styled.small`
    color: ${themeVariables.palettes.neutral700};
`

export const InputWrap = styled.div<{ $invalid?: boolean }>`
    display: flex;
    flex-direction: column;
    border: 1px solid ${themeVariables.colors.border};
    border-radius: 4px;
    width: 100%;

    * + & {
        margin-top: 4px;
    }

    &:hover {
        background-color: ${themeVariables.palettes.neutral200};
    }

    &:focus-within {
        background-color: ${themeVariables.palettes.neutral100};
        border-color: transparent;
        outline: 2px solid ${themeVariables.palettes.info300};
    }

    &:has(input:disabled),
    &:has(textarea:disabled) {
        background-color: ${themeVariables.palettes.neutral300};
        cursor: not-allowed;
    }

    :disabled {
        cursor: not-allowed;
    }

    ${({ $invalid }) =>
        $invalid &&
        css`
            border-color: ${themeVariables.colors.critical};
        `}
`

const ErrorMessage = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 3px;
    color: ${themeVariables.colors.critical};
    font-size: ${themeVariables.typography.fontSizes.caption};
`

export function FormField(props: FormFieldProps) {
    const {
        id,
        children,
        className,
        description,
        errorMessage,
        label,
        labelCta,
    } = props

    return (
        <FormFieldRoot className={className}>
            {(label || labelCta) && (
                <LabelWrap>
                    <label htmlFor={id}>{label}</label>
                    {labelCta && <LabelCtaWrap>{labelCta}</LabelCtaWrap>}
                </LabelWrap>
            )}
            {description && <Description>{description}</Description>}
            <InputWrap $invalid={!!errorMessage}>{children}</InputWrap>
            {errorMessage && (
                <ErrorMessage>
                    <Icon name="warningCircle" />
                    {errorMessage}
                </ErrorMessage>
            )}
        </FormFieldRoot>
    )
}

const FormFieldsetRoot = styled.fieldset`
    & + & {
        margin-top: 24px;
    }

    legend {
        color: ${themeVariables.colors.primary};
        font-size: ${themeVariables.typography.fontSizes.large};
        font-weight: ${themeVariables.typography.fontWeight.emphasized};
        line-height: ${themeVariables.typography.lineHeight.large};
    }
`

interface FormFieldsetProps
    extends Omit<AllHTMLAttributes<HTMLFieldSetElement>, 'title'> {
    title?: ReactNode
}

export function FormFieldset(props: FormFieldsetProps) {
    const { children, title } = props

    return (
        <FormFieldsetRoot>
            {title && <legend>{title}</legend>}
            {children}
        </FormFieldsetRoot>
    )
}
