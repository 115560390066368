import React, { useState } from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FullPageLayout } from '../components/FullPageLayout'
import { route } from '../utils/routes'
import { Sidebar, SidebarSection } from '../components/Sidebar'
import { Tooltip } from '../components/Tooltip'
import { OutcomeBadge } from '../components/OutcomeBadge'
import { themeVariables } from '../themes/themeVariables'
import { RunningLabel } from '../components/RunningLabel'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import {
    getProgressColor,
    useRunningReportPollEffect,
    useRunReportWithFaultyDocumentsHandling,
} from '../utils/reports'
import { useProjectQuery, useReportQuery } from '../utils/queries'
import { useRequiredParam } from '../utils/misc'
import { FileTypeIcon } from '../components/FileTypeIcon'
import { downloadReport } from '../utils/download'
import { Icon } from '../components/Icon'
import { CircularProgressIndicator } from '../components/CircularProgressIndicator'
import { Button } from '../components/Button'
import { useDeleteReport, useUpdateReport } from '../utils/mutations'
import { confirmModal } from '../components/modals/ConfirmationModal'
import { SkeletonParagraph } from '../components/SkeletonParagraph'
import { InfoBox } from '../components/InfoBox'
import { useNameInputModal } from '../components/modals/NameInputModal'

const Root = styled.div`
    margin: 56px auto;
    padding: 0 11px;
    max-width: 800px;
`

const ReportFileContainer = styled.div`
    border-radius: 4px;
    padding: 20px 24px;
    margin-top: 32px;
    box-shadow:
        0px 1px 1px 0px #0000001f,
        0px 0px 0px 1px #3c425729,
        0px 2px 5px 0px #3c425714;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const DownloadLink = styled.button`
    border: none;
    background: none;
    font-weight: ${themeVariables.typography.fontWeight.emphasized};
    color: ${themeVariables.colors.brand};
    &:hover {
        color: ${themeVariables.colors.brand};
    }
    display: flex;
    align-items: center;
    gap: 5px;
`

const ReportIcon = styled(Icon)`
    width: 13px;
    height: 13px;
    svg {
        width: 13px;
        height: 13px;
    }
`

const Summary = styled.p`
    white-space: pre-line;
    margin-bottom: 40px;
`

export function ReportShowPage() {
    const id = useRequiredParam('id')
    const { t } = useTranslation()
    const { data: report = null, isLoading } = useReportQuery(id)
    const { data: project = null, isLoading: isLoadingProject } =
        useProjectQuery({ projectId: report?.assessment.projectId || '' })
    const { handleRunReport, isPending: isRunningReport } =
        useRunReportWithFaultyDocumentsHandling()
    const { mutate: deleteReport, isPending: isDeletingReport } =
        useDeleteReport()
    const [isDownloading, setIsDownloading] = useState(false)
    useRunningReportPollEffect(report)

    const fileName = `${report?.name}.docx`

    const triggerDownloadReport = async () => {
        if (!report || isDownloading) {
            return
        }
        try {
            setIsDownloading(true)
            await downloadReport(report)
        } finally {
            setIsDownloading(false)
        }
    }

    const updateReport = useUpdateReport()
    const openNameInputModal = useNameInputModal()

    return (
        <FullPageLayout
            checkAccess={true}
            closeButtonLink={route('reports')}
            breadcrumbs={[
                {
                    label: (
                        <div
                            className={cx(
                                'd-flex',
                                'align-items-center',
                                'g-5'
                            )}
                        >
                            {report?.name}
                            {report?.status === 'running' && <RunningLabel />}
                        </div>
                    ),
                },
            ]}
            sidebarContent={
                report && (
                    <Sidebar>
                        <SidebarSection title="Properties">
                            <p className={cx('secondary', 'm-t-30')}>Status</p>
                            {report.status === 'error' && (
                                <Tooltip
                                    content={
                                        report.error || 'No error description.'
                                    }
                                >
                                    <OutcomeBadge
                                        label="Error"
                                        color={themeVariables.colors.primary}
                                    />
                                </Tooltip>
                            )}
                            {report.status === 'new' && (
                                <OutcomeBadge
                                    label="New"
                                    color={themeVariables.colors.info}
                                />
                            )}
                            {report.status === 'ready' && (
                                <OutcomeBadge
                                    label="Ready"
                                    color={themeVariables.colors.success}
                                />
                            )}
                            {report.status === 'running' && (
                                <OutcomeBadge
                                    label="Running..."
                                    color={themeVariables.colors.attention}
                                />
                            )}

                            <p className={cx('secondary', 'm-t-30')}>
                                {t('project.singular')}
                            </p>
                            <p>{project?.name}</p>

                            <p className={cx('secondary', 'm-t-30')}>
                                {t('assessment.singular')}
                            </p>
                            <p>{report.assessment.name}</p>

                            <p className={cx('secondary', 'm-t-30')}>
                                {t('reportTemplate.singular')}
                            </p>
                            <p>{report.reportTemplate.name}</p>
                            <p className={cx('secondary', 'm-t-30')}>
                                Progress
                            </p>
                            <p
                                className={cx(
                                    'd-flex',
                                    'g-10',
                                    'align-items-center'
                                )}
                            >
                                <CircularProgressIndicator
                                    progress={report.progress / 100}
                                    color={getProgressColor(report.status)}
                                />
                                {report.progress}%
                            </p>
                        </SidebarSection>
                    </Sidebar>
                )
            }
            headerCTAContent={
                report && (
                    <div className={cx('d-flex', 'g-10', 'align-items-center')}>
                        {report.status === 'new' && (
                            // in case if report is left with "new" status, we need to have a possibility to run it manuually
                            <Button
                                className={cx(
                                    'd-flex',
                                    'g-5',
                                    'align-items-center'
                                )}
                                onClick={() => handleRunReport(report.id)}
                            >
                                <ReportIcon name="repeat" />
                                Run report
                            </Button>
                        )}
                        <Button
                            className={cx(
                                'd-flex',
                                'g-5',
                                'align-items-center'
                            )}
                            onClick={triggerDownloadReport}
                            disabled={isDownloading || !report?.blobId}
                        >
                            <ReportIcon
                                name={isDownloading ? 'spinner' : 'download'}
                            />
                            {isDownloading
                                ? 'Downloading...'
                                : `Download ${t('report.singular')}`}
                        </Button>

                        <Button
                            $variant="secondary"
                            className={cx(
                                'd-flex',
                                'g-5',
                                'align-items-center'
                            )}
                            onClick={() => {
                                openNameInputModal({
                                    modalTitle: `Rename ${t('report.singular').toLowerCase()}`,
                                    buttonCtaText: 'Save',
                                    buttonCtaSavingText: 'Saving',
                                    currentName: report.name,
                                    onConfirm: async (newName: string) => {
                                        await updateReport.mutateAsync({
                                            id,
                                            name: newName,
                                        })
                                    },
                                })
                            }}
                            disabled={updateReport.isPending}
                        >
                            <ReportIcon name="edit" />
                            Rename
                        </Button>
                        <Button
                            $variant="secondary"
                            className={cx(
                                'd-flex',
                                'g-5',
                                'align-items-center'
                            )}
                            onClick={async () => {
                                try {
                                    const confirmed = await confirmModal.pop({
                                        title: `Delete ${t('report.singular').toLowerCase()}`,
                                        content: `Are you sure you want to delete this ${t('report.singular').toLowerCase()}?`,
                                        confirmButtonText: 'Delete',
                                        cancelButtonText: 'Cancel',
                                    })
                                    if (confirmed) {
                                        deleteReport(report.id)
                                    }
                                } catch (_) {
                                    // do nothing
                                }
                            }}
                            disabled={isDeletingReport}
                        >
                            <ReportIcon name="trash" />
                            {isDeletingReport
                                ? 'Deleting...'
                                : `Delete ${t('report.singular')}`}
                        </Button>
                    </div>
                )
            }
        >
            <>
                <HorizontalLoadingIndicator
                    loading={
                        isLoading ||
                        isLoadingProject ||
                        isRunningReport ||
                        isDeletingReport
                    }
                />
                <Root>
                    {report && (
                        <>
                            {report.failedPromptCount > 0 &&
                                report.status !== 'running' && (
                                    <div className={cx('p-b-40')}>
                                        <InfoBox
                                            iconName="info"
                                            backgroundColor={
                                                themeVariables.palettes
                                                    .attention100
                                            }
                                            borderColor={
                                                themeVariables.palettes
                                                    .attention600
                                            }
                                            iconColor={
                                                themeVariables.palettes
                                                    .attention600
                                            }
                                            textColor={
                                                themeVariables.colors.primary
                                            }
                                        >
                                            <p>
                                                {report.failedPromptCount}{' '}
                                                prompt
                                                {report.failedPromptCount !== 1
                                                    ? 's'
                                                    : ''}{' '}
                                                failed when generating this
                                                {t(
                                                    'report.singular'
                                                ).toLowerCase()}
                                                , and{' '}
                                                {report.failedPromptCount !== 1
                                                    ? 'those prompts'
                                                    : 'that prompt'}{' '}
                                                {report.failedPromptCount !== 1
                                                    ? 'were'
                                                    : 'was'}{' '}
                                                left unanswered. You can retry
                                                the failed prompt
                                                {report.failedPromptCount !== 1
                                                    ? 's'
                                                    : ''}{' '}
                                                by clicking the button below.
                                            </p>
                                            <Button
                                                $variant="secondary"
                                                className={cx(
                                                    'd-flex',
                                                    'align-items-center',
                                                    'g-5',
                                                    'm-t-25'
                                                )}
                                                onClick={() =>
                                                    handleRunReport(report.id)
                                                }
                                            >
                                                <ReportIcon name="repeat" />
                                                Retry failed prompts
                                            </Button>
                                        </InfoBox>
                                    </div>
                                )}

                            <h3 className="m-b-25">{report.name}</h3>

                            {report.summaryStatus === 'ready' && (
                                <Summary>{report.summary}</Summary>
                            )}
                            {report.summaryStatus === 'error' && (
                                <p>
                                    There was an error generating the{' '}
                                    {t('report.singular').toLowerCase()}{' '}
                                    summary.
                                </p>
                            )}
                            {(report.summaryStatus === 'running' ||
                                report.status === 'running') && (
                                <SkeletonParagraph
                                    length={1000}
                                    fontSize={14}
                                />
                            )}
                            {report.summaryStatus === 'new' &&
                                report.status !== 'running' && (
                                    <p>
                                        A {t('report.singular').toLowerCase()}{' '}
                                        summary will be shown here once the
                                        {t('report.singular').toLowerCase()}
                                        generation finishes successfully.
                                    </p>
                                )}

                            {report.blobId && (
                                <ReportFileContainer>
                                    <div className={cx('d-flex', 'g-10')}>
                                        <FileTypeIcon fileName={fileName} />
                                        {report.name}
                                    </div>
                                    <DownloadLink
                                        onClick={triggerDownloadReport}
                                        disabled={isDownloading}
                                    >
                                        <>
                                            <ReportIcon
                                                name={
                                                    isDownloading
                                                        ? 'spinner'
                                                        : 'download'
                                                }
                                            />
                                            {isDownloading
                                                ? 'Downloading...'
                                                : 'Download'}
                                        </>
                                    </DownloadLink>
                                </ReportFileContainer>
                            )}
                        </>
                    )}
                </Root>
            </>
        </FullPageLayout>
    )
}
