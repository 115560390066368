import { FormFieldset } from 'components/Form/FormField'
import { TextAreaField } from 'components/Form/TextAreaField'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ModelWithRelations, OutcomeCriterion } from 'silta-ai-backend'
import styled from 'styled-components'
import { toaster } from 'toasterhea'
import { useTranslation } from 'react-i18next'
import { themeVariables } from '../../themes/themeVariables'
import { getUniqueCategoryOptionsWithCount } from '../../utils/categories'
import { RejectionReason } from '../../utils/exceptions'
import { Layer } from '../../utils/layers'
import { useCreateQuestion, useUpdateQuestion } from '../../utils/mutations'
import { BaseButton } from '../BaseButton'
import { Button } from '../Button'
import { QuestionForm } from '../Form/QuestionForm'
import { Icon } from '../Icon'
import { BaseModal, BaseModalProps } from './BaseModal'

const Root = styled.div`
    max-width: 690px;
    width: 90vw;
`

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 32px 16px;
`
const Title = styled.h3`
    margin: 0;
`

const CloseButton = styled(BaseButton)`
    color: ${themeVariables.colors.secondary};
    padding: 8px;
    transform: translateX(8px);
    &:hover {
        background-color: inherit;
    }
`

const ContentContainer = styled.div`
    padding: 0 32px;
    color: ${themeVariables.colors.secondary};
`

const Hr = styled.div`
    background-color: ${themeVariables.colors.border};
    height: 1px;
    margin: 24px 0;
`

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 0 32px 32px;
`

const Spinner = styled(Icon)`
    color: ${themeVariables.colors.backgroundSurface};
    width: 14px;
    height: 14px;
    svg {
        width: 14px;
        height: 14px;
    }
`

const SpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const OutcomesContainer = styled.div`
    align-items: start;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 24px 0;
`

const SingleOutcomeContainer = styled.div`
    display: contents;
    padding: 10px 0;
    & > div {
        display: flex;
        align-items: center;
    }
`

const SingleOutcomeDotContainer = styled.div`
    padding-right: 40px !important;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 0;
`

const OutcomeDot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
`

const OptionalLabel = styled.span`
    color: ${themeVariables.colors.secondary};
    font-weight: ${themeVariables.typography.fontWeight.normal};
`

export interface ModalProps extends Omit<BaseModalProps, 'children'> {
    model: ModelWithRelations
    question?: ModelWithRelations['questions'][number]
    questionNumber?: number
    onConfirm: (
        questionContent: string,
        category: string,
        subCategory: string,
        outcomeCriteria: OutcomeCriterion[],
        hint: string
    ) => Promise<void>
}

const ModelQuestionEditor = ({
    model,
    question,
    questionNumber,
    onConfirm,
    ...props
}: ModalProps) => {
    const [newQuestion, setNewQuestion] = useState(question?.content || '')
    const [category, setCategory] = useState(question?.category1 || '')
    const [subCategory, setSubCategory] = useState(question?.category2 || '')
    const [isLoading, setIsLoading] = useState(false)

    const [hint, setHint] = useState(question?.hint || '')

    const { t } = useTranslation()

    const occuringCategories = useMemo(
        () =>
            getUniqueCategoryOptionsWithCount(model.questions, 'category1').map(
                (q) => ({
                    name: q.value,
                    questionCount: q.count,
                })
            ),
        [model.questions]
    )

    const occuringSubCategories = useMemo(
        () =>
            getUniqueCategoryOptionsWithCount(model.questions, 'category2').map(
                (q) => ({
                    name: q.value,
                    questionCount: q.count,
                })
            ),
        [model.questions]
    )

    const [criterions, setCriterions] = useState<
        Record<string, string | undefined>
    >({})

    useEffect(
        function updateCriterionsFromUpstream() {
            const result: Record<string, string | undefined> = {}

            for (const outcome of model.outcomes) {
                result[outcome.id] =
                    question?.criteria.find(
                        (criteria) => criteria.outcomeId === outcome.id
                    )?.criterion || ''
            }

            setCriterions(result)
        },
        [model, question]
    )

    const outcomeCriteria: OutcomeCriterion[] = useMemo(() => {
        const result: OutcomeCriterion[] = []

        const criterionsCopy = { ...criterions }

        if (question) {
            for (const crit of question.criteria) {
                result.push({
                    ...crit,
                    criterion: criterionsCopy[crit.outcomeId] || '',
                })

                delete criterionsCopy[crit.outcomeId]
            }
        }

        Object.entries(criterionsCopy).forEach(
            ([outcomeId, criterion = '']) => {
                result.push({
                    outcomeId,
                    criterion,
                    id: 'new',
                    questionId: question?.id || 'new',
                })
            }
        )

        return result
    }, [model, question, criterions])

    return (
        <BaseModal {...props}>
            <Root>
                <TitleContainer>
                    <Title>
                        {question
                            ? `Edit Question ${questionNumber}`
                            : 'Add question'}
                    </Title>
                    <CloseButton
                        onClick={() =>
                            props.onReject?.(RejectionReason.CloseButton)
                        }
                    >
                        <Icon name="close2" />
                    </CloseButton>
                </TitleContainer>
                <ContentContainer>
                    <FormFieldset title="Question title">
                        <QuestionForm
                            value={{
                                question: newQuestion,
                                category,
                                subCategory,
                            }}
                            onChange={(value) => {
                                setNewQuestion(value.question || '')
                                setCategory(value.category || '')
                                setSubCategory(value.subCategory || '')
                            }}
                            categories={occuringCategories}
                            subCategories={occuringSubCategories}
                        />
                    </FormFieldset>
                    {model.outcomes && model.outcomes.length > 0 && (
                        <FormFieldset title={t('model.outcomes')}>
                            <OutcomesContainer>
                                {model.outcomes.map((outcome) => (
                                    <SingleOutcomeContainer key={outcome.id}>
                                        <SingleOutcomeDotContainer>
                                            <OutcomeDot
                                                style={{
                                                    backgroundColor:
                                                        outcome.color,
                                                }}
                                            />
                                            <span>{outcome.label}</span>
                                        </SingleOutcomeDotContainer>
                                        <TextAreaField
                                            autoSize
                                            minSize={3}
                                            maxSize={7}
                                            containerClassName="w-100"
                                            value={criterions[outcome.id] || ''}
                                            placeholder={`Enter a new ${t('model.outcome').toLowerCase()} criteria`}
                                            onChange={(e) => {
                                                setCriterions((current) => ({
                                                    ...current,
                                                    [outcome.id]:
                                                        e.target.value,
                                                }))
                                            }}
                                        />
                                    </SingleOutcomeContainer>
                                ))}
                            </OutcomesContainer>
                        </FormFieldset>
                    )}
                    <FormFieldset
                        title={
                            <>
                                Advanced{' '}
                                <OptionalLabel>(optional)</OptionalLabel>
                            </>
                        }
                    >
                        <TextAreaField
                            placeholder="Please write instructions for the AI agent on how to approach answering the question."
                            minSize={3}
                            maxSize={7}
                            value={hint}
                            onChange={(e) => {
                                setHint(e.target.value)
                            }}
                        />
                    </FormFieldset>
                    <Hr />
                </ContentContainer>
                <ButtonsContainer>
                    <Button
                        $variant="secondary"
                        onClick={() =>
                            props.onReject?.(RejectionReason.CancelButton)
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            try {
                                setIsLoading(true)

                                await onConfirm(
                                    newQuestion,
                                    category,
                                    subCategory,
                                    outcomeCriteria,
                                    hint
                                )

                                props.onResolve?.()
                            } finally {
                                setIsLoading(false)
                            }
                        }}
                        disabled={!newQuestion || isLoading}
                    >
                        {isLoading ? (
                            <SpinnerContainer>
                                Saving
                                <Spinner name="spinner" />
                            </SpinnerContainer>
                        ) : (
                            'Save'
                        )}
                    </Button>
                </ButtonsContainer>
            </Root>
        </BaseModal>
    )
}

export const useModelQuestionEditorModal = () => {
    const createQuestion = useCreateQuestion()

    const updateQuestion = useUpdateQuestion()

    const editorModal = toaster(ModelQuestionEditor, Layer.Modal)

    return useCallback(
        async (
            model: ModelWithRelations,
            question?: ModelWithRelations['questions'][number],
            questionNumber?: number
        ) => {
            try {
                await editorModal.pop({
                    model,
                    question,
                    questionNumber,
                    onConfirm: async (
                        questionContent,
                        category,
                        subCategory,
                        outcomeCriteria,
                        hint
                    ) => {
                        if (question) {
                            await updateQuestion.mutateAsync({
                                question,
                                questionRequestData: {
                                    content: questionContent,
                                    hint,
                                    category1: category,
                                    category2: subCategory,
                                },
                                criteria: outcomeCriteria,
                            })
                        } else {
                            // find the highest sortOrder value and add 1 to it
                            const sortOrder =
                                model.questions
                                    .map((q) => q.sortOrder)
                                    .reduce(
                                        (acc, curr) =>
                                            acc > curr ? acc : curr,
                                        0
                                    ) + 1

                            await createQuestion.mutateAsync({
                                questionRequestData: {
                                    modelId: model.id,
                                    content: questionContent,
                                    hint,
                                    category1: category,
                                    category2: subCategory,
                                    sortOrder,
                                },
                                criteria: outcomeCriteria,
                            })
                        }
                    },
                })
            } catch (e) {
                // do nothing
            }
        },
        [editorModal, createQuestion, updateQuestion]
    )
}
