import cx from 'classnames'
import { Table } from 'components/Table'
import { DateRenderer } from 'components/renderers/DateRenderer'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useReportTemplatesQuery } from 'utils/queries'
import { Button } from '../components/Button'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { Icon } from '../components/Icon'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { TableDataLoading } from '../components/TableDataLoading'
import { TableNoContent } from '../components/TableNoContent'
import { useUploadReportTemplate } from '../utils/mutations'
import { route } from '../utils/routes'
import { LoadMoreButton } from '../components/LoadMoreButton'

export function ReportTemplateListPage() {
    const {
        data: reportTemplates,
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useReportTemplatesQuery({})

    const navigate = useNavigate()

    const uploadReportTemplate = useUploadReportTemplate()

    const uploadTemplate = useCallback(() => {
        const fileInput = document.createElement('input')
        fileInput.type = 'file'
        fileInput.accept = '.doc, .docx'
        fileInput.click()
        fileInput.onchange = (e) => {
            const file = (e.target as HTMLInputElement)?.files?.[0]
            if (file) {
                uploadReportTemplate.mutate(file)
            }
        }
    }, [uploadReportTemplate])

    const { t } = useTranslation()

    return (
        <Layout>
            <ListTopBar />
            <HorizontalLoadingIndicator
                loading={
                    uploadReportTemplate.isPending ||
                    isLoading ||
                    isFetchingNextPage
                }
            />
            <div
                className={cx(
                    'p-l-40',
                    'p-r-40',
                    'p-t-30',
                    'p-b-15',
                    'd-flex',
                    'align-items-center',
                    'justify-space-between'
                )}
            >
                <h1>{t('reportTemplate.plural')}</h1>
                <Button
                    $size="medium"
                    onClick={uploadTemplate}
                    disabled={uploadReportTemplate.isPending}
                >
                    <div className="d-flex align-items-center">
                        <Icon name="plus" />
                        <span className="m-l-5">
                            Create {t('reportTemplate.singular')}
                        </span>
                    </div>
                </Button>
            </div>
            <Table
                columns={[
                    {
                        title: 'Name',
                        key: 'name',
                        width: 'minmax(min-content, 90%)',
                    },
                    {
                        title: 'Last update',
                        key: 'updatedAt',
                        render: (template) => (
                            <DateRenderer value={template.updatedAt} />
                        ),
                    },
                ]}
                data={reportTemplates}
                onRowClick={(template) => {
                    navigate(route('reportTemplate', template.id))
                }}
                stickyHeader
            />
            {isLoading && <TableDataLoading />}
            {!isLoading && reportTemplates.length === 0 && (
                <TableNoContent
                    icon="package"
                    title={`Create first ${t('reportTemplate.singular')}`}
                    description={`${t('reportTemplate.plural')} define the structure of ${t('report.plural')}.`}
                    buttonText={`Create ${t('reportTemplate.singular')}`}
                    buttonOnClick={uploadTemplate}
                />
            )}
            {hasNextPage && (
                <LoadMoreButton
                    onClick={fetchNextPage}
                    isLoading={isFetchingNextPage}
                />
            )}
        </Layout>
    )
}
