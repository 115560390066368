import { AssessmentInnerLayout } from 'components/AssessmentPageLayout'
import { CircularProgressIndicator } from 'components/CircularProgressIndicator'
import { Table, TableRoot } from 'components/Table'
import { ToolbarButton } from 'components/Toolbar'
import { AssessmentNavToolbar } from 'components/Toolbar/AssessmentNavToolbar'
import { useCreateReportModal } from 'components/modals/CreateReportModal'
import { DateRenderer } from 'components/renderers/DateRenderer'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import cx from 'classnames'
import { useRequiredParam } from 'utils/misc'
import {
    useAssessmentQuery,
    useReportsQuery,
    useTeamPermissionsForResourceQuery,
} from 'utils/queries'
import { getProgressColor } from 'utils/reports'
import { route } from 'utils/routes'
import { TableDataLoading } from '../components/TableDataLoading'
import { TableNoContent } from '../components/TableNoContent'
import { LoadMoreButton } from '../components/LoadMoreButton'
import { ReadOnly } from '../components/ReadOnly'

const Root = styled.div`
    padding-bottom: 96px;

    ${TableRoot} {
        border-top: 0;
    }
`

export function AssessmentShowReportsPage() {
    const assessmentId = useRequiredParam('id')

    const { t } = useTranslation()

    const { data: assessment = null } = useAssessmentQuery({ assessmentId })

    const navigate = useNavigate()

    const {
        data: reports,
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useReportsQuery({
        assessmentId,
    })

    const createReport = useCreateReportModal()

    const { data: permission, isFetching: teamPermissionIsLoading } =
        useTeamPermissionsForResourceQuery({
            assessmentId,
        })

    return (
        <AssessmentInnerLayout
            assessmentId={assessmentId}
            toolbars={
                <AssessmentNavToolbar
                    assessmentId={assessmentId}
                    rhsAux={
                        <ToolbarButton
                            onClick={() => {
                                createReport(assessmentId)
                            }}
                            prepend={
                                <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5 1.5V8.5M1.5 5H8.5"
                                        stroke="#30313D"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            }
                        >
                            Create report
                        </ToolbarButton>
                    }
                    answersCount={assessment?.answers.length}
                    reportsCount={reports.length}
                />
            }
        >
            {!teamPermissionIsLoading && permission === 'Viewer' && (
                <ReadOnly />
            )}
            <Root>
                <Table
                    columns={[
                        {
                            key: 'name',
                            title: 'Name',
                            render: (report) => report.name,
                            width: 'minmax(min-content, 40%)',
                        },
                        {
                            title: 'Template',
                            key: 'template',
                            render: (report) => report.reportTemplate.name,
                        },
                        {
                            title: 'Last update',
                            key: 'updatedAt',
                            render: (report) => (
                                <DateRenderer value={report.updatedAt} />
                            ),
                        },
                        {
                            title: 'Progress',
                            key: 'progress',
                            render: (report) => (
                                <div
                                    className={cx(
                                        'd-flex',
                                        'g-10',
                                        'align-items-center'
                                    )}
                                >
                                    <CircularProgressIndicator
                                        progress={report.progress / 100}
                                        color={getProgressColor(report.status)}
                                    />
                                    {report.progress}%
                                </div>
                            ),
                        },
                    ]}
                    data={reports}
                    onRowClick={(report) => {
                        navigate(route('report', report.id))
                    }}
                    stickyHeader
                />
            </Root>
            {isLoading && <TableDataLoading />}
            {!isLoading && reports.length === 0 && (
                <TableNoContent
                    icon="barChart"
                    title={`Create first ${t('report.singular')}`}
                    description={`${t('report.plural')} are summaries of ${t('assessment.plural').toLowerCase()} in downloadable document form.`}
                    buttonText={`Create ${t('report.singular')}`}
                    buttonOnClick={() => createReport(assessmentId)}
                />
            )}
            {hasNextPage && (
                <LoadMoreButton
                    onClick={fetchNextPage}
                    isLoading={isFetchingNextPage}
                />
            )}
        </AssessmentInnerLayout>
    )
}
