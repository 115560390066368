import React, { useMemo } from 'react'
import { toaster } from 'toasterhea'
import cx from 'classnames'
import styled from 'styled-components'
import groupBy from 'lodash/groupBy'
import { DataRoomDocumentWithRelations } from 'silta-ai-backend'
import { useTranslation } from 'react-i18next'
import { BaseModal, BaseModalProps } from './BaseModal'
import { Button } from '../Button'
import { RejectionReason } from '../../utils/exceptions'
import { Icon } from '../Icon'
import { Layer } from '../../utils/layers'
import {
    EditorModalButtonsContainer,
    EditorModalCloseButton,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'
import { themeVariables } from '../../themes/themeVariables'

const DocumentSourceContainer = styled.div`
    padding: 12px 16px;
    background-color: ${themeVariables.colors.backgroundContainer};
    margin-bottom: 10px;
`

interface FaultyDocumentsModalProps extends Omit<BaseModalProps, 'children'> {
    projectDataRoomDocuments: DataRoomDocumentWithRelations[]
    precedentDocuments: DataRoomDocumentWithRelations[]
    target: 'assessment' | 'report'
}

const DocumentList = ({
    headerText,
    documents,
}: {
    headerText: string
    documents: DataRoomDocumentWithRelations[]
}) => {
    const documentsWithErrorsCount = useMemo(() => {
        return documents.filter((document) => document.status === 'error')
            .length
    }, [documents])
    const documentsProcessingCount = useMemo(() => {
        return documents.filter((document) => document.status === 'running')
            .length
    }, [documents])
    return (
        <DocumentSourceContainer>
            <p className="m-b-5">{headerText}</p>
            <p className={cx('m-b-0', 'emphasized')}>
                {documentsWithErrorsCount > 0 && (
                    <span>
                        <strong>
                            {documentsWithErrorsCount} document
                            {documentsWithErrorsCount > 1 ? 's' : ''}
                        </strong>{' '}
                        had an error while processing
                    </span>
                )}
                {documentsWithErrorsCount > 0 &&
                    documentsProcessingCount > 0 && <span> and </span>}
                {documentsProcessingCount > 0 && (
                    <span>
                        <strong>
                            {documentsProcessingCount} document
                            {documentsProcessingCount > 1 ? 's' : ''}
                        </strong>
                        {documentsProcessingCount > 1 ? ' are ' : ' is '}
                        still being processed
                    </span>
                )}
                .
            </p>
        </DocumentSourceContainer>
    )
}

const FaultyDocumentsModal = ({
    projectDataRoomDocuments,
    precedentDocuments,
    target,
    ...props
}: FaultyDocumentsModalProps) => {
    const { t } = useTranslation()

    const groupedPredecentDocuments = groupBy(precedentDocuments, 'dataRoomId')
    return (
        <BaseModal {...props}>
            <EditorModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>Are you sure?</EditorModalTitle>
                    <EditorModalCloseButton
                        onClick={() =>
                            props.onReject?.(RejectionReason.CloseButton)
                        }
                    >
                        <Icon name="close2" />
                    </EditorModalCloseButton>
                </EditorModalTitleContainer>
                <EditorModalContentContainer>
                    <p>
                        There are some issues with the documents included in
                        this {t(`${target}.singular`).toLowerCase()}:
                    </p>
                    <DocumentList
                        headerText={`${t('project.singular')} ${t('project.dataRoom')}:`}
                        documents={projectDataRoomDocuments}
                    />

                    {Object.entries(groupedPredecentDocuments).map(
                        ([dataRoomId, documents]) => (
                            <DocumentList
                                key={dataRoomId}
                                headerText={`${t('dataRoom.singular')} "${documents[0]?.dataRoom?.name}":`}
                                documents={documents}
                            />
                        )
                    )}
                    <p>
                        These documents will not be included in the{' '}
                        {t(`${target}.singular`).toLowerCase()}. Are you sure
                        you want to continue?
                    </p>
                </EditorModalContentContainer>
                <EditorModalButtonsContainer>
                    <Button
                        $variant="secondary"
                        onClick={() =>
                            props.onReject?.(RejectionReason.CancelButton)
                        }
                        className={cx('p-l-20', 'p-r-20')}
                    >
                        No
                    </Button>
                    <Button
                        onClick={() => {
                            props.onResolve?.(true)
                        }}
                        className={cx('p-l-20', 'p-r-20')}
                    >
                        Yes
                    </Button>
                </EditorModalButtonsContainer>
            </EditorModalRoot>
        </BaseModal>
    )
}

export const openFaultyDocumentsModal = async (
    projectDataRoomDocuments: DataRoomDocumentWithRelations[],
    precedentDocuments: DataRoomDocumentWithRelations[],
    target: 'assessment' | 'report'
) => {
    const faultyDocumentsModal = toaster(FaultyDocumentsModal, Layer.Modal)
    try {
        await faultyDocumentsModal.pop({
            projectDataRoomDocuments,
            precedentDocuments,
            target,
        })
        return true
    } catch (e) {
        // do nothing
        return false
    }
}
