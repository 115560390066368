import React from 'react'
import styled from 'styled-components'
import { BaseButton } from './BaseButton'
import { themeVariables } from '../themes/themeVariables'
import { Icon } from './Icon'

const Container = styled.div<{ small?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${({ small }) => (small ? '5px 0 0' : '40px 0 20px')};
`

const StyledButton = styled(BaseButton)<{ small?: boolean }>`
    height: ${({ small }) => (small ? '32px' : '56px')};
    border-radius: 50px;
    border: 1px solid ${themeVariables.colors.border};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0 ${({ small }) => (small ? '15px' : '25px')};
`

export const LoadMoreButton = ({
    onClick,
    isLoading,
    small,
}: {
    onClick: () => void
    isLoading: boolean
    small?: boolean
}) => {
    return (
        <Container small={small}>
            <StyledButton
                disabled={isLoading}
                onClick={(e) => {
                    e.stopPropagation()
                    onClick()
                }}
                small={small}
            >
                <Icon name={isLoading ? 'spinner' : 'refresh'} />
                <span>{isLoading ? 'Loading...' : 'Load More'}</span>
            </StyledButton>
        </Container>
    )
}
