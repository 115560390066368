import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Icon } from './Icon'
import { apiClient } from '../utils/clients'

const SizedIcon = styled(Icon)<{ $size: number }>`
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    svg {
        width: ${({ $size }) => $size}px;
        height: ${({ $size }) => $size}px;
    }
`

const SizedImage = styled.img<{ $size: number }>`
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    border-radius: 50%;
`

export const UserAvatar = ({
    avatarBlobId,
    size = 16,
}: {
    avatarBlobId: string
    size?: number
}) => {
    const [localFileUrl, setLocalFileUrl] = useState<string>()

    useEffect(() => {
        async function fetchAvatar() {
            if (avatarBlobId) {
                try {
                    const blob = await apiClient.getAvatar(avatarBlobId)
                    setLocalFileUrl(URL.createObjectURL(blob))
                } catch (error) {
                    console.error('Error fetching avatar', error)
                }
            }
        }
        fetchAvatar()
    }, [avatarBlobId])

    return localFileUrl ? (
        <SizedImage src={localFileUrl} $size={size} alt="User avatar" />
    ) : (
        <SizedIcon name="spinnerBlueThin" $size={size} />
    )
}
