function parentSibling(
    currentElement: HTMLElement,
    siblingTagName: string
): HTMLElement | null {
    let currentSibling: HTMLElement | null = currentElement.parentElement

    while (
        currentSibling &&
        currentSibling.tagName.toLowerCase() !== siblingTagName.toLowerCase()
    ) {
        currentSibling = currentSibling.parentElement
    }

    return currentSibling
}

export function nextParentSibling(
    currentElement: HTMLElement,
    siblingTagName: string
): HTMLElement | null {
    const nextSibling = parentSibling(
        currentElement,
        siblingTagName
    )?.nextSibling

    if (!nextSibling || !(nextSibling instanceof HTMLElement)) {
        return null
    }

    return nextSibling
}

export function prevParentSibling(
    currentElement: HTMLElement,
    siblingTagName: string
): HTMLElement | null {
    const prevSibling = parentSibling(
        currentElement,
        siblingTagName
    )?.previousSibling

    if (!prevSibling || !(prevSibling instanceof HTMLElement)) {
        return null
    }

    return prevSibling
}
