import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { DataRoomDocument } from 'silta-ai-backend'

const projectFocusedDocument = atom<DataRoomDocument | null>({
    key: 'projectFocusedDocument',
    default: null,
})

export const useProjectFocusedDocument = (): DataRoomDocument | null =>
    useRecoilValue(projectFocusedDocument)

export const useUpdateProjectFocusedDocument = (): ((
    document: DataRoomDocument | null
) => void) => {
    const setFocusedDocument = useSetRecoilState(projectFocusedDocument)

    return (document: DataRoomDocument | null) => setFocusedDocument(document)
}
