import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { Field, Form, Formik } from 'formik'
import { object, ref, string } from 'yup'
import { themeVariables } from '../themes/themeVariables'
import { Logo } from '../components/Logo'
import { TextField } from '../components/Form/TextField'
import { Button } from '../components/Button'
import { useRequiredParam } from '../utils/misc'
import { usePasswordResetTokenCheckQuery } from '../utils/queries'
import { route } from '../utils/routes'
import { TextLink } from '../components/TextLink'
import { Icon } from '../components/Icon'

import { validatePassword } from '../utils/password'
import { BaseButton } from '../components/BaseButton'
import { useSetNewPassword } from '../utils/mutations'

export const NewPasswordValidationSchema = object().shape({
    newPassword: string()
        .required('Field is required')
        .test('is-valid-password', 'Invalid password', (value) =>
            validatePassword(value || '')
        ),
    newPasswordRepeat: string()
        .required('Field is required')
        .oneOf([ref('newPassword'), null], 'Passwords must match'),
})

const FullPageLayoutContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${themeVariables.colors.backgroundContainer};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
`

const ContentContainer = styled.div`
    max-width: 538px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${themeVariables.colors.backgroundSurface};
    margin: 37px 0;
    padding: 60px;
    box-shadow:
        0px 15px 35px 0px #3c425714,
        0px 5px 15px 0px #0000001f;
`

export const NewPasswordPage = () => {
    const token = useRequiredParam('token')
    const { data: isValid, isLoading } = usePasswordResetTokenCheckQuery(token)
    const { mutate: setNewPassword, isPending: isSavingNewPassword } =
        useSetNewPassword()
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [newPasswordVisible, setNewPasswordVisible] = useState(false)
    const [newPasswordConfirmationVisible, setNewPasswordConfirmationVisible] =
        useState(false)

    const formIsValid = useMemo(() => {
        return NewPasswordValidationSchema.isValidSync({
            newPassword: password,
            newPasswordRepeat: passwordConfirmation,
        })
    }, [password, passwordConfirmation])

    return (
        <FullPageLayoutContainer>
            <Logo className="m-t-60" />
            <h1 className="m-t-75">
                {isLoading
                    ? 'Checking your password reset link...'
                    : isValid
                      ? 'Set your new password'
                      : 'The password reset link is invalid'}
            </h1>
            <ContentContainer>
                {isLoading && (
                    <div>
                        <Icon name="spinner" />
                    </div>
                )}
                {!isLoading && (
                    <>
                        {isValid && (
                            <>
                                <p className={cx('secondary')}>
                                    Password should be at least 16 characters
                                    long. Should contain upper and lower case
                                    characters. Should contain at least one
                                    number. Should contain at least one special
                                    character.
                                </p>
                                <Formik
                                    onSubmit={() => {
                                        if (formIsValid) {
                                            setNewPassword({
                                                token,
                                                newPassword: password,
                                            })
                                        }
                                    }}
                                    initialValues={{}}
                                    validationSchema={
                                        NewPasswordValidationSchema
                                    }
                                >
                                    <Form
                                        className={cx(
                                            'd-flex',
                                            'g-20',
                                            'w-100',
                                            'flex-column'
                                        )}
                                    >
                                        <Field name="newPassword">
                                            {({
                                                field,
                                                form,
                                            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            any) => (
                                                <TextField
                                                    {...field}
                                                    label="New password"
                                                    placeholder="Enter your new password"
                                                    type={
                                                        newPasswordVisible
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    value={password}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setPassword(
                                                            e.target.value
                                                        )
                                                    }}
                                                    errorMessage={
                                                        form.touched[
                                                            field.name
                                                        ] &&
                                                        form.errors[field.name]
                                                    }
                                                    disabled={
                                                        isSavingNewPassword
                                                    }
                                                    additionalInnerContent={
                                                        <BaseButton
                                                            onClick={() =>
                                                                setNewPasswordVisible(
                                                                    !newPasswordVisible
                                                                )
                                                            }
                                                        >
                                                            <Icon
                                                                className="secondary"
                                                                name={
                                                                    newPasswordVisible
                                                                        ? 'eyeOff'
                                                                        : 'eye'
                                                                }
                                                            />
                                                        </BaseButton>
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <Field name="newPasswordRepeat">
                                            {({
                                                field,
                                                form,
                                            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            any) => (
                                                <TextField
                                                    {...field}
                                                    label="Confirm new password"
                                                    placeholder="Repeat your new password"
                                                    type={
                                                        newPasswordConfirmationVisible
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    value={passwordConfirmation}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setPasswordConfirmation(
                                                            e.target.value
                                                        )
                                                    }}
                                                    errorMessage={
                                                        form.touched[
                                                            field.name
                                                        ] &&
                                                        form.errors[field.name]
                                                    }
                                                    disabled={
                                                        isSavingNewPassword
                                                    }
                                                    additionalInnerContent={
                                                        <BaseButton
                                                            onClick={() =>
                                                                setNewPasswordConfirmationVisible(
                                                                    !newPasswordConfirmationVisible
                                                                )
                                                            }
                                                        >
                                                            <Icon
                                                                className="secondary"
                                                                name={
                                                                    newPasswordVisible
                                                                        ? 'eyeOff'
                                                                        : 'eye'
                                                                }
                                                            />
                                                        </BaseButton>
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <Button
                                            type="submit"
                                            disabled={
                                                !formIsValid ||
                                                isSavingNewPassword
                                            }
                                            $size="large"
                                            className="m-t-20"
                                        >
                                            {isSavingNewPassword
                                                ? 'Saving...'
                                                : 'Save'}
                                        </Button>
                                    </Form>
                                </Formik>
                            </>
                        )}
                        {!isValid && (
                            <div className="text-center">
                                <span>
                                    Try to request your password reset link
                                    again{' '}
                                    <TextLink to={route('forgotPassword')}>
                                        here.
                                    </TextLink>
                                </span>
                            </div>
                        )}
                    </>
                )}
            </ContentContainer>
        </FullPageLayoutContainer>
    )
}
