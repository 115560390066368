import React, { useState, useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'
import { toaster } from 'toasterhea'
import AvatarEditor, { CroppedRect } from 'react-avatar-editor'
import MaskSvg from '../../assets/images/mask.svg'

import { Layer } from '../../utils/layers'
import { BaseModal, BaseModalProps } from './BaseModal'
import { Icon } from '../Icon'
import { Button } from '../Button'
import { Slider } from '../Slider'
import {
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalSpinner,
    EditorModalSpinnerContainer,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'
import { RejectionReason } from '../../utils/exceptions'
import { themeVariables } from '../../themes/themeVariables'
import { BaseButton } from '../BaseButton'

type MaskOption = 'none' | 'round'

type Props = {
    imageUrl: string
    onCrop: (file: File) => Promise<void>
    mask?: MaskOption
} & Omit<BaseModalProps, 'children'>

const MAX_WIDTH = 300

const StyledAvatarEditor = styled(AvatarEditor)<{ $mask: MaskOption }>`
    width: 100% !important;
    height: auto !important;

    ${({ $mask }) =>
        $mask === 'round' &&
        css`
            mask: url(${MaskSvg}) center;
            mask-size: 100% 100%;
        `}
`

const AvatarEditorWrap = styled.div`
    display: flex;
    background-color: black;
`

const ZoomSlider = styled(Slider)`
    width: 50%;
    min-width: 200px;
    margin: 0 12px;
`

const ZoomControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
`

const ZoomIcon = styled(Icon)`
    color: ${themeVariables.colors.primary};
    width: 20px;
`

const ButtonContainer = styled.div`
    display: flex;
    gap: 5px;
    margin-top: 20px;
    padding-bottom: 20px;
    justify-content: flex-end;
`

// only width is considered because images returned from the cropper will always be squared
export const getCroppedAndResizedBlob = async (
    imageUrl: string,
    cropInfo: CroppedRect
): Promise<Blob | null> => {
    const imageElement = document.createElement('img')
    await new Promise((resolve) => {
        imageElement.onload = () => resolve(null)
        imageElement.src = imageUrl
    })
    let canvas = document.createElement('canvas')
    const cropCanvasContext = canvas.getContext(
        '2d'
    ) as CanvasRenderingContext2D
    const x = Math.round(imageElement.width * cropInfo.x)
    const y = Math.round(imageElement.height * cropInfo.y)
    const width = Math.round(imageElement.width * cropInfo.width)
    const height = Math.round(imageElement.height * cropInfo.height)
    canvas.width = width
    canvas.height = height
    cropCanvasContext.drawImage(
        imageElement,
        x,
        y,
        width,
        height,
        0,
        0,
        width,
        height
    )

    if (canvas.width > MAX_WIDTH) {
        const resizedCanvas = document.createElement('canvas')
        const resizedCanvasContext = resizedCanvas.getContext(
            '2d'
        ) as CanvasRenderingContext2D
        // Start with original image size
        resizedCanvas.width = canvas.width
        resizedCanvas.height = canvas.height
        // Draw the original image on the (temp) resizing canvas
        resizedCanvasContext.drawImage(canvas, 0, 0)

        // Quickly reduce the size by 50% each time in few iterations until the size is less then
        // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
        // created with direct reduction of very big image to small image
        while (resizedCanvas.width * 0.5 > MAX_WIDTH) {
            resizedCanvas.width *= 0.5
            resizedCanvas.height *= 0.5
            resizedCanvasContext.drawImage(
                resizedCanvas,
                0,
                0,
                resizedCanvas.width,
                resizedCanvas.height
            )
        }

        // Now do final resize for the resizingCanvas to meet the dimension requirments
        // directly to the output canvas, that will output the final image
        resizedCanvas.width = MAX_WIDTH
        resizedCanvas.height =
            (resizedCanvas.width * canvas.height) / canvas.width
        resizedCanvasContext.drawImage(
            canvas,
            0,
            0,
            resizedCanvas.width,
            resizedCanvas.height
        )
        canvas = resizedCanvas
    }

    return new Promise<Blob | null>((resolve) => {
        canvas.toBlob(resolve)
    })
}

const CropImageModal = ({
    imageUrl,
    onCrop,
    mask = 'round',
    ...props
}: Props) => {
    const editorRef = useRef<AvatarEditor>(null)
    const [sliderValue, setSliderValue] = useState<number>(1)
    const [isSaving, setIsSaving] = useState(false)
    const onSave = useCallback(async () => {
        if (editorRef.current) {
            const blob = await getCroppedAndResizedBlob(
                imageUrl,
                editorRef.current.getCroppingRect()
            )

            if (!blob) {
                console.warn('Failed to get the blob')

                return
            }

            setIsSaving(true)
            try {
                await onCrop(new File([blob], 'image.jpg'))
                props.onResolve?.()
            } catch (error) {
                console.error('Failed to save the image', error)
            } finally {
                setIsSaving(false)
            }
        }
    }, [onCrop, editorRef, imageUrl])
    return (
        <BaseModal {...props}>
            {(close) => (
                <EditorModalRoot>
                    <EditorModalTitleContainer>
                        <EditorModalTitle>
                            Scale and crop your image
                        </EditorModalTitle>
                        <BaseButton type="button" onClick={close}>
                            <Icon name="close2" />
                        </BaseButton>
                    </EditorModalTitleContainer>
                    <EditorModalContentContainer>
                        <div>
                            <AvatarEditorWrap>
                                <StyledAvatarEditor
                                    ref={editorRef}
                                    image={imageUrl}
                                    width={1024}
                                    height={1024}
                                    border={[0, 0]}
                                    borderRadius={0}
                                    color={[255, 255, 255, 0.6]} // RGBA
                                    scale={(100 + sliderValue) / 100}
                                    rotate={0}
                                    $mask={mask}
                                />
                            </AvatarEditorWrap>
                            <ZoomControls>
                                <ZoomIcon name="zoomOut" />
                                <ZoomSlider
                                    min={0}
                                    max={200}
                                    value={sliderValue}
                                    onChange={setSliderValue}
                                />
                                <ZoomIcon name="zoomIn" />
                            </ZoomControls>
                        </div>
                        <ButtonContainer>
                            <Button
                                $variant="secondary"
                                onClick={() =>
                                    void close(RejectionReason.CancelButton)
                                }
                            >
                                Cancel
                            </Button>
                            <Button onClick={onSave} disabled={isSaving}>
                                {isSaving ? (
                                    <EditorModalSpinnerContainer>
                                        Saving
                                        <EditorModalSpinner name="spinner" />
                                    </EditorModalSpinnerContainer>
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </ButtonContainer>
                    </EditorModalContentContainer>
                </EditorModalRoot>
            )}
        </BaseModal>
    )
}

const modal = toaster(CropImageModal, Layer.Modal)

export const useCropImageModal = () => {
    return useCallback(
        async ({ imageUrl, onCrop }: Pick<Props, 'imageUrl' | 'onCrop'>) => {
            try {
                return await modal.pop({ imageUrl, onCrop })
            } catch (_) {
                return null
            }
        },
        []
    )
}
