import { FormField } from 'components/Form/FormField'
import React, { InputHTMLAttributes, ReactNode, RefObject, useRef } from 'react'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { FormFieldSize } from '../../types/form'

function inputSizeMixin({ $size: size = 'large' }: { $size?: FormFieldSize }) {
    switch (size) {
        case 'small':
            return css`
                padding: 2px 8px;
                font-size: ${themeVariables.typography.fontSizes.caption};
                line-height: ${themeVariables.typography.lineHeight.caption};
            `
        case 'medium':
            return css`
                padding: 6px 8px;
            `
        case 'extraLarge':
            return css`
                padding: 18px 12px;
            `
        case 'large':
        default:
            return css`padding: 10px; 8px`
    }
}

const TextInput = styled.input<{ $size?: FormFieldSize }>`
    box-sizing: border-box;
    background: transparent;
    border-radius: 0;
    border: 0;
    line-height: 1.5em;
    outline: 0 !important;
    width: 100%;

    ${inputSizeMixin}

    &::placeholder {
        color: ${themeVariables.colors.secondary};
        opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
        /* Edge 12-18 */
        color: ${themeVariables.colors.secondary};
    }
`

const InnerWrap = styled.div`
    align-items: stretch;
    cursor: text;
    display: flex;
`

function additionalContentPaddingMixin({
    $size: size = 'large',
}: {
    $size?: FormFieldSize
}) {
    switch (size) {
        case 'small':
            return css`
                padding-right: 8px;
            `
        case 'medium':
            return css`
                padding-right: 8px;
            `
        case 'extraLarge':
            return css`
                padding-right: 12px;
            `
        case 'large':
        default:
            return css`
                padding-right: 8px;
            `
    }
}

const AdditionalContentWrap = styled.div<{ $size?: FormFieldSize }>`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    pointer-events: none;

    input,
    button {
        pointer-events: auto;
    }

    ${additionalContentPaddingMixin}

    &:empty {
        display: none;
    }
`

const PrefixWrap = styled.div`
    &:empty {
        display: none;
    }
`

interface TextFieldProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'label' | 'size'> {
    additionalInnerContent?: ReactNode
    additionalLabel?: ReactNode
    allowOnePassword?: boolean
    containerClassName?: string
    errorMessage?: ReactNode
    inputRef?: RefObject<HTMLInputElement>
    label?: ReactNode
    prefixContent?: ReactNode
    size?: FormFieldSize
}

export function TextField(props: TextFieldProps) {
    const {
        additionalInnerContent,
        additionalLabel,
        allowOnePassword = false,
        containerClassName,
        errorMessage,
        inputRef,
        label,
        prefixContent,
        size = 'large',
        ...rest
    } = props

    const innerWrapRef = useRef<HTMLDivElement>(null)

    const innerInputRef = useRef<HTMLInputElement>(null)

    const finalInputRef = inputRef || innerInputRef

    return (
        <FormField
            className={containerClassName}
            errorMessage={errorMessage}
            id={props.id}
            label={label}
            labelCta={additionalLabel}
        >
            <InnerWrap
                ref={innerWrapRef}
                onMouseDown={(e) => {
                    if (
                        e.target instanceof HTMLTextAreaElement ||
                        e.target instanceof HTMLInputElement
                    ) {
                        return
                    }

                    setTimeout(() => {
                        finalInputRef.current?.focus()
                    }, 0)

                    e.preventDefault()
                }}
            >
                <PrefixWrap>{prefixContent}</PrefixWrap>
                <TextInput
                    {...rest}
                    ref={finalInputRef}
                    data-1p-ignore={allowOnePassword ? undefined : true}
                    $size={size}
                />
                <AdditionalContentWrap $size={size}>
                    {additionalInnerContent}
                </AdditionalContentWrap>
            </InnerWrap>
        </FormField>
    )
}
