import z from 'zod'

const TeamSchema = z.object({
    id: z.string(),
    name: z.string(),
})

export type Team = z.infer<typeof TeamSchema>

const SELECTED_TEAMS_KEY = 'silta-ai-selected-teams'

const TeamsMapSchema = z.record(z.string(), TeamSchema)

export function storeCurrentTeam(userId: string, team: Team) {
    const validatedTeam = TeamSchema.parse(team)
    const teamsMap = getTeamsMap()
    teamsMap[userId] = validatedTeam
    localStorage.setItem(SELECTED_TEAMS_KEY, JSON.stringify(teamsMap))
}

export function clearStoredTeam(userId: string) {
    const teamsMap = getTeamsMap()
    delete teamsMap[userId]
    localStorage.setItem(SELECTED_TEAMS_KEY, JSON.stringify(teamsMap))
}

export function getStoredTeam(userId: string): Team | null {
    const teamsMap = getTeamsMap()
    return teamsMap[userId] || null
}

export const getCurrentTeamId = (userId: string): string | null => {
    const team = getStoredTeam(userId)
    return team?.id ?? null
}

function getTeamsMap(): Record<string, Team> {
    try {
        return TeamsMapSchema.parse(
            JSON.parse(localStorage.getItem(SELECTED_TEAMS_KEY) || '{}')
        )
    } catch (_) {
        return {}
    }
}

export type TeamMemberOrInvite = {
    inviteId?: string
    type: 'teamMember' | 'invite'
    userId?: string
    userName?: string
    userEmail: string
    avatarBlobId?: string
    role: string
}

export type TeamOrInvite = {
    id?: string
    teamId: string
    teamName?: string
    type: 'team' | 'invite'
    role: string
}
