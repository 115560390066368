import { BaseItem } from 'components/dropdowns/SearchDropdown/itemRenderers/BaseItem'
import { FileTypeIcon } from 'components/FileTypeIcon'
import { SelectBasicItemIconWrap } from 'components/Form/Select/SelectItemRenderer'
import React, { ButtonHTMLAttributes } from 'react'
import { useNavigate } from 'react-router'
import { DataRoomDocumentWithRelations } from 'silta-ai-backend'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { route } from 'utils/routes'

const DataRoomName = styled.div`
    color: ${themeVariables.colors.secondary};
`

interface DocumentItemProps
    extends Omit<
        ButtonHTMLAttributes<HTMLButtonElement>,
        'children' | 'value'
    > {
    value: DataRoomDocumentWithRelations
}

export function DocumentItem({
    value,
    onClick: onClickProp,
    ...rest
}: DocumentItemProps) {
    const navigate = useNavigate()

    return (
        <BaseItem
            {...rest}
            onClick={(e) => {
                onClickProp?.(e)

                navigate(route('dataRoom', value.dataRoomId))
            }}
        >
            <SelectBasicItemIconWrap>
                <FileTypeIcon fileName={value.originalFileName} />
            </SelectBasicItemIconWrap>
            <div>
                <strong>{value.originalFileName}</strong>
                <DataRoomName>{value.dataRoom.name}</DataRoomName>
            </div>
        </BaseItem>
    )
}
