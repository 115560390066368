import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { object, string } from 'yup'
import { themeVariables } from '../themes/themeVariables'
import { Logo } from '../components/Logo'
import { TextField } from '../components/Form/TextField'
import { Button } from '../components/Button'
import { useCreatePasswordResetRequest } from '../utils/mutations'

const FullPageLayoutContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${themeVariables.colors.backgroundContainer};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
`

const ContentContainer = styled.div`
    max-width: 538px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${themeVariables.colors.backgroundSurface};
    margin: 37px 0;
    padding: 60px;
    box-shadow:
        0px 15px 35px 0px #3c425714,
        0px 5px 15px 0px #0000001f;
`

const StyledButton = styled(Button)`
    padding: 10.5px 20px;
`

const validationSchema = object({
    email: string().email('Invalid email format').required('Email is required'),
})

export const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('')
    const [requestSent, setRequestSent] = useState(false)

    const { mutate, isPending } = useCreatePasswordResetRequest()

    const emailIsInvalid = useMemo(
        () => !!email && !validationSchema.isValidSync({ email }),
        [email]
    )
    return (
        <FullPageLayoutContainer>
            <Logo className="m-t-60" />
            <h1 className="m-t-75">Forgot your password?</h1>
            <ContentContainer>
                {requestSent ? (
                    <>
                        <h3>Success!</h3>
                        <p>
                            We have sent you an email with a link to reset your
                            password.
                        </p>
                    </>
                ) : (
                    <>
                        <h3>Provide your email address</h3>
                        <p>We will send you a link to reset your password.</p>
                        <div className={cx('d-flex', 'g-10', 'w-100')}>
                            <TextField
                                placeholder="Enter your email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                containerClassName="flex-1"
                                errorMessage={
                                    emailIsInvalid ? 'Invalid email format' : ''
                                }
                                disabled={isPending}
                            />
                            <StyledButton
                                disabled={emailIsInvalid || isPending}
                                onClick={() => {
                                    if (!emailIsInvalid) {
                                        mutate(email, {
                                            onSuccess: () => {
                                                setRequestSent(true)
                                            },
                                        })
                                    }
                                }}
                            >
                                {isPending ? 'Sending...' : 'Send'}
                            </StyledButton>
                        </div>
                    </>
                )}
            </ContentContainer>
        </FullPageLayoutContainer>
    )
}
