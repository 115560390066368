import cx from 'classnames'
import { AccessDeniedHandler } from 'components/AccessDeniedHandler'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DotdotdotButton } from '../components/DotdotdotButton'
import { Dropdown, DropdownContent } from '../components/Dropdown'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from '../components/Form/Select/SelectItemRenderer'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { BreadcrumbsToolbar } from '../components/Toolbar/BreadcrumbsToolbar'
import { route } from '../utils/routes'

import { Button } from '../components/Button'
import { Icon } from '../components/Icon'
import { confirmModal } from '../components/modals/ConfirmationModal'
import { DateRenderer } from '../components/renderers/DateRenderer'
import { themeVariables } from '../themes/themeVariables'
import { downloadReportTemplate } from '../utils/download'
import { useRequiredParam } from '../utils/misc'
import {
    useDeleteReportTemplate,
    useUpdateReportTemplate,
} from '../utils/mutations'
import {
    useTeamPermissionsForResourceQuery,
    useReportTemplateQuery,
} from '../utils/queries'
import { useShareResourceModal } from '../components/modals/ShareResourceModal'
import { useNameInputModal } from '../components/modals/NameInputModal'
import { ReadOnly } from '../components/ReadOnly'

const Pad = styled.div`
    padding: 40px;
`

const ButtonIcon = styled(Icon)`
    width: 16px;
    height: 16px;
    svg {
        width: 16px;
        height: 16px;
    }
`

const TemplateContainer = styled.div`
    border-radius: 16px;
    background-color: ${themeVariables.colors.backgroundContainer};
    padding: 48px 40px;
    max-width: 770px;
    margin: 24px auto 0;
`

export function ReportTemplateShowPage() {
    const reportTemplateId = useRequiredParam('id')
    const { t } = useTranslation()

    const { data: reportTemplate, isFetching } =
        useReportTemplateQuery(reportTemplateId)

    const updateReportTemplate = useUpdateReportTemplate()
    const openNameInputModal = useNameInputModal()

    const deleteTemplate = useDeleteReportTemplate()

    const triggerTemplateDelete = useCallback(async () => {
        try {
            await confirmModal.pop({
                title: `Delete ${t('reportTemplate.singular').toLowerCase()}`,
                content: `Are you sure you want to delete "${reportTemplate?.name}" ${t('reportTemplate.singular').toLowerCase()}?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                onResolve: () => {
                    deleteTemplate.mutate(reportTemplateId)
                },
            })
        } catch (_) {
            // do nothing
        }
    }, [reportTemplate?.name, deleteTemplate])

    const { data: permission, isFetching: teamPermissionIsLoading } =
        useTeamPermissionsForResourceQuery({
            reportTemplateId,
        })

    const shareResouceModal = useShareResourceModal()

    const triggerShareResource = useCallback(() => {
        if (!reportTemplate) {
            return
        }
        shareResouceModal(reportTemplate.name, {
            reportTemplateId: reportTemplate.id,
        })
    }, [reportTemplate, shareResouceModal])

    return (
        <AccessDeniedHandler>
            <Layout>
                <div>
                    <ListTopBar />
                    <HorizontalLoadingIndicator
                        loading={isFetching || deleteTemplate.isPending}
                    />

                    <BreadcrumbsToolbar
                        currentPageName={reportTemplate?.name || ''}
                        listPage={{
                            name: t('reportTemplate.plural'),
                            route: route('reportTemplates'),
                            icon: 'package',
                        }}
                        lhsAux={
                            <>
                                <Dropdown
                                    trigger={({ onClick }) => (
                                        <DotdotdotButton onClick={onClick} />
                                    )}
                                >
                                    {(dismiss) => (
                                        <DropdownContent>
                                            <SelectBasicItem
                                                type="button"
                                                disabled={
                                                    teamPermissionIsLoading ||
                                                    permission !== 'Owner'
                                                }
                                                onClick={() => {
                                                    dismiss()
                                                    triggerShareResource()
                                                }}
                                            >
                                                <SelectBasicItemIconWrap>
                                                    <Icon name="users" />
                                                </SelectBasicItemIconWrap>
                                                Manage permissions
                                            </SelectBasicItem>
                                            <SelectBasicItem
                                                type="button"
                                                disabled={
                                                    deleteTemplate.isPending ||
                                                    teamPermissionIsLoading ||
                                                    permission !== 'Owner'
                                                }
                                                onClick={() => {
                                                    dismiss()
                                                    triggerTemplateDelete()
                                                }}
                                            >
                                                <SelectBasicItemIconWrap>
                                                    <Icon name="trash" />
                                                </SelectBasicItemIconWrap>
                                                Delete
                                            </SelectBasicItem>
                                        </DropdownContent>
                                    )}
                                </Dropdown>
                            </>
                        }
                    />
                    {!teamPermissionIsLoading && permission === 'Viewer' && (
                        <ReadOnly />
                    )}
                    <Pad>
                        {reportTemplate && (
                            <TemplateContainer>
                                <h1>{reportTemplate.name}</h1>
                                <div
                                    className={cx(
                                        'secondary',
                                        'd-flex',
                                        'g-5',
                                        'm-b-40'
                                    )}
                                >
                                    Last updated:
                                    <DateRenderer
                                        value={reportTemplate.updatedAt}
                                    />
                                </div>
                                <div className={cx('d-flex', 'g-10')}>
                                    <Button
                                        className={cx('d-flex', 'g-5')}
                                        onClick={() =>
                                            downloadReportTemplate(
                                                reportTemplate
                                            )
                                        }
                                    >
                                        <ButtonIcon name="download" />
                                        Download
                                    </Button>
                                    <Button
                                        $variant="secondary"
                                        className={cx('d-flex', 'g-5')}
                                        onClick={() =>
                                            openNameInputModal({
                                                onConfirm: async (newName) => {
                                                    await updateReportTemplate.mutateAsync(
                                                        {
                                                            id: reportTemplate.id,
                                                            name: newName,
                                                        }
                                                    )
                                                },
                                                currentName:
                                                    reportTemplate.name,
                                                modalTitle: `Rename ${t('reportTemplate.singular').toLowerCase()}`,
                                                buttonCtaText: 'Save',
                                                buttonCtaSavingText: 'Saving',
                                            })
                                        }
                                        disabled={
                                            teamPermissionIsLoading ||
                                            permission === 'Viewer'
                                        }
                                    >
                                        <ButtonIcon name="edit" />
                                        Rename
                                    </Button>
                                    <Button
                                        $variant="secondary"
                                        className={cx('d-flex', 'g-5')}
                                        disabled={
                                            deleteTemplate.isPending ||
                                            teamPermissionIsLoading ||
                                            permission !== 'Owner'
                                        }
                                        onClick={triggerTemplateDelete}
                                    >
                                        <ButtonIcon name="trash" />
                                        Delete
                                    </Button>
                                </div>
                            </TemplateContainer>
                        )}
                    </Pad>
                </div>
            </Layout>
        </AccessDeniedHandler>
    )
}
