import { StorageKey } from 'consts'
import uniq from 'lodash-es/uniq'
import { z } from 'zod'

export function retrieveRecentSearches(): string[] {
    try {
        return uniq(
            z
                .array(z.string())
                .parse(
                    JSON.parse(
                        localStorage.getItem(StorageKey.RecentSearches) || ''
                    )
                )
        ).slice(0, 5)
    } catch (_) {
        return []
    }
}

export function addRecentSearch(value: string): void {
    localStorage.setItem(
        StorageKey.RecentSearches,
        JSON.stringify([value, ...retrieveRecentSearches()])
    )
}

export function deleteRecentSearch(value: string): void {
    localStorage.setItem(
        StorageKey.RecentSearches,
        JSON.stringify(retrieveRecentSearches().filter((v) => value !== v))
    )
}
