import cx from 'classnames'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { apiClient } from 'utils/clients'
import { array, object, string } from 'yup'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/Button'
import { FullPageLayout } from '../../components/FullPageLayout'
import { Select } from '../../components/Form/Select/Select'
import { TextField } from '../../components/Form/TextField'
import { InfoBox } from '../../components/InfoBox'
import {
    useAssessmentDraft,
    useUpdateAssessmentDraft,
} from '../../state/AssessmentDraft.state'
import { themeVariables } from '../../themes/themeVariables'
import { useCreateAssessment } from '../../utils/mutations'
import { route } from '../../utils/routes'
import {
    useDataRoomsQuery,
    useModelsQuery,
    useProjectQuery,
    useProjectsQuery,
    useUsersQuery,
} from '../../utils/queries'
import { TextLink } from '../../components/TextLink'

const AssesmentCreateValidationSchema = object().shape({
    name: string().required('Field is required'),
    project: string().required('Field is required'),
    modelId: string().optional(),
    precedentDataRoomIds: array(string()).min(0),
})

const FilterLink = styled(TextLink)`
    appearance: none;
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: 500;
    height: fit-content;
    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
    font-size: ${themeVariables.typography.fontSizes.large};
    line-height: ${themeVariables.typography.lineHeight.large};
    padding: 8px 16px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    background-color: ${themeVariables.colors.backgroundSurface};
    border: none;
    margin: 1px;
    color: ${themeVariables.colors.primary};
    font-weight: 500;
    &:hover,
    &:active {
        background-color: ${themeVariables.colors.backgroundContainer};
        color: ${themeVariables.colors.primary};
    }
    box-shadow:
        0px 1px 1px 0px #0000001f,
        0px 0px 0px 1px #3c425729,
        0px 2px 5px 0px #3c425714;
`

const FilterLinkColoredText = styled.span`
    color: ${themeVariables.colors.brand};
    font-size: ${themeVariables.typography.fontSizes.body};
    font-weight: ${themeVariables.typography.fontWeight.normal};
`

export default function AssessmentCreatePage() {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [params] = useSearchParams()
    const preselectedProjectId = params.get('projectId')

    const [projectSearchTerm, setProjectSearchTerm] = useState('')
    const [modelSearchTerm, setModelSearchTerm] = useState('')
    const [precedentsSearchTerm, setPrecedentsSearchTerm] = useState('')

    const updateAssessmentDraft = useUpdateAssessmentDraft()

    const createAssessment = useCreateAssessment()

    const {
        name,
        projectId,
        modelId,
        precedentDataRoomIds,
        questions,
        assignedToId,
    } = useAssessmentDraft()

    const {
        data: projects,
        isLoading: isProjectsLoading,
        isFetchingNextPage: isFetchingMoreProjects,
        hasNextPage: hasMoreProjects,
        fetchNextPage: fetchMoreProjects,
    } = useProjectsQuery({
        search: projectSearchTerm ?? undefined,
    })

    const { data: preselectedProject } = useProjectQuery({
        projectId: preselectedProjectId ?? undefined,
    })

    const {
        data: models,
        isLoading: isModelsLoading,
        isFetchingNextPage: isFetchingMoreModels,
        hasNextPage: hasMoreModels,
        fetchNextPage: fetchMoreModels,
    } = useModelsQuery({
        search: modelSearchTerm ?? undefined,
    })

    const {
        data: precedentsDataRooms,
        isLoading: isPrecedentsDataRoomsLoading,
        isFetchingNextPage: isFetchingMorePrecedentsDataRooms,
        hasNextPage: hasMorePrecedentsDataRooms,
        fetchNextPage: fetchMorePrecedentsDataRooms,
    } = useDataRoomsQuery({
        hasNoProject: true,
        search: precedentsSearchTerm ?? undefined,
    })

    const {
        data: users,
        isLoading: isUsersLoading,
        isFetchingNextPage: isFetchingMoreUsers,
        hasNextPage: hasMoreUsers,
        fetchNextPage: fetchMoreUsers,
    } = useUsersQuery({})

    const selectedProjectIdDataRoomId = useMemo(
        () =>
            projectId
                ? projects.find((project) => project.id === projectId)
                      ?.dataRoomId
                : undefined,
        [projectId, projects]
    )

    const projectLabels = useMemo(() => {
        const labels: Record<string, string> = {}
        projects.forEach((project) => {
            labels[project.id] = project.name
        })
        if (preselectedProject) {
            labels[preselectedProject.id] = preselectedProject.name
        }
        return labels
    }, [projects, preselectedProject])

    const modelLabels = useMemo(
        () =>
            models.map((model) => ({
                label: model.name,
                value: model.id,
            })),
        [models]
    )

    const precedentsLabels = useMemo(() => {
        const labels: Record<string, string> = {}
        precedentsDataRooms.forEach((dataRoom) => {
            labels[dataRoom.id] = dataRoom.name
        })
        return labels
    }, [precedentsDataRooms])

    const handleModelChange = async (newModelId: string | undefined) => {
        if (!newModelId) {
            updateAssessmentDraft({
                modelId: undefined,
                questions: [],
                filteredQuestions: [],
                selectedCategories1: [],
                selectedCategories2: [],
            })
            return
        }
        try {
            const model = await apiClient.getModel(newModelId)
            updateAssessmentDraft({
                modelId: newModelId,
                questions: model.questions,
                filteredQuestions: model.questions,
                selectedCategories1: [],
                selectedCategories2: [],
            })
        } catch (err) {
            console.error(`Error fetching model (${newModelId}): ${err}`)
        }
    }

    useEffect(() => {
        if (preselectedProjectId) {
            updateAssessmentDraft({
                projectId: preselectedProjectId,
            })
        }
    }, [preselectedProjectId])

    return (
        <FullPageLayout
            breadcrumbs={[{ label: `Create ${t('assessment.singular')}` }]}
            closeButtonLink={route('assessments')}
            headerCTAContent={
                <div className={cx('d-flex', 'g-10')}>
                    <Button
                        onClick={() => {
                            navigate(route('assessments'))
                        }}
                        $variant="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            createAssessment.mutate()
                        }}
                        $variant="primary"
                        disabled={
                            !name || !projectId || createAssessment.isPending
                        }
                    >
                        <div className={cx('d-flex', 'align-items-center')}>
                            <span className="m-l-5">
                                {`Run ${t('assessment.singular')}`}
                            </span>
                        </div>
                    </Button>
                </div>
            }
        >
            <div
                className={cx(
                    'main-container',
                    'd-flex',
                    'm-t-60',
                    'm-l-auto',
                    'm-r-auto'
                )}
            >
                <Formik
                    onSubmit={() =>
                        navigate(route('assessmentCreateQuestions'))
                    }
                    initialValues={{}}
                    validationSchema={AssesmentCreateValidationSchema}
                >
                    <Form className="w-100">
                        <Field name="name" id="name">
                            {({
                                field,
                                form,
                            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            any) => (
                                <TextField
                                    {...field}
                                    value={name || ''}
                                    containerClassName="m-b-30"
                                    label={
                                        <h3>
                                            {t('assessment.singular')} title
                                        </h3>
                                    }
                                    errorMessage={
                                        form.touched[field.name] &&
                                        form.errors[field.name]
                                    }
                                    placeholder={`Enter a title for the ${t('assessment.singular')}`}
                                    onChange={(e) => {
                                        field.onChange(e)
                                        updateAssessmentDraft({
                                            name: e.target.value,
                                        })
                                    }}
                                />
                            )}
                        </Field>
                        <Field name="project" id="project">
                            {({
                                field,
                                form,
                            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            any) => (
                                <Select
                                    {...field}
                                    value={projectId}
                                    valueLabels={projectLabels}
                                    containerClassName="m-b-30"
                                    errorMessage={
                                        form.touched[field.name] &&
                                        form.errors[field.name]
                                    }
                                    label={
                                        <h3>
                                            {t('project.singular')} to assess
                                        </h3>
                                    }
                                    loadingOptions={isProjectsLoading}
                                    loadingMoreOptions={isFetchingMoreProjects}
                                    hasMoreOptions={hasMoreProjects}
                                    onFetchMoreClick={fetchMoreProjects}
                                    options={projects.map((project) => ({
                                        value: project.id,
                                        label: project.name,
                                    }))}
                                    onSearchChange={setProjectSearchTerm}
                                    placeholder={`Select a ${t('project.singular')} to assess`}
                                    onChange={(selection: string) => {
                                        form.setFieldValue(
                                            field.name,
                                            selection
                                        )
                                        // field.onChange(selection || '')
                                        updateAssessmentDraft({
                                            projectId: selection,
                                        })
                                    }}
                                />
                            )}
                        </Field>
                        <Field name="modelId" id="modelId">
                            {({
                                field,
                            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            any) => (
                                <Select
                                    {...field}
                                    value={modelId}
                                    valueLabels={modelLabels}
                                    containerClassName="m-b-30"
                                    label={
                                        <h3>{t('model.singular')} to apply</h3>
                                    }
                                    additionalLabel="Optional"
                                    loadingOptions={isModelsLoading}
                                    loadingMoreOptions={isFetchingMoreModels}
                                    hasMoreOptions={hasMoreModels}
                                    onFetchMoreClick={fetchMoreModels}
                                    options={models.map((model) => ({
                                        value: model.id,
                                        label: model.name,
                                    }))}
                                    onSearchChange={setModelSearchTerm}
                                    placeholder={`Select a ${t('model.singular')} to apply`}
                                    onChange={(selection: string) =>
                                        handleModelChange(selection)
                                    }
                                />
                            )}
                        </Field>
                        <Field
                            name="precedentDataRoomIds"
                            id="precedentDataRoomIds"
                        >
                            {({
                                field,
                            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            any) => (
                                <Select
                                    {...field}
                                    multiple={true}
                                    value={precedentDataRoomIds}
                                    valueLabels={precedentsLabels}
                                    containerClassName="m-b-30"
                                    label={<h3>{t('dataRoom.plural')}</h3>}
                                    additionalLabel="Optional"
                                    disabled={!projectId}
                                    loadingOptions={
                                        isPrecedentsDataRoomsLoading
                                    }
                                    loadingMoreOptions={
                                        isFetchingMorePrecedentsDataRooms
                                    }
                                    hasMoreOptions={hasMorePrecedentsDataRooms}
                                    onFetchMoreClick={
                                        fetchMorePrecedentsDataRooms
                                    }
                                    options={precedentsDataRooms
                                        .filter(
                                            (dataRoom) =>
                                                dataRoom.id !==
                                                selectedProjectIdDataRoomId
                                        )
                                        .map((dataRoom) => ({
                                            value: dataRoom.id,
                                            label: dataRoom.name,
                                        }))}
                                    onSearchChange={setPrecedentsSearchTerm}
                                    placeholder={`Connect ${t('dataRoom.plural')}`}
                                    onChange={(
                                        selection: string[] | undefined
                                    ) =>
                                        updateAssessmentDraft({
                                            precedentDataRoomIds:
                                                selection || [],
                                        })
                                    }
                                />
                            )}
                        </Field>
                        <Field name="assignedToId" id="assignedToId">
                            {({
                                field,
                                form,
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            }: any) => (
                                <Select
                                    {...field}
                                    value={assignedToId}
                                    // containerClassName="m-b-30"
                                    errorMessage={
                                        form.touched[field.name] &&
                                        form.errors[field.name]
                                    }
                                    label={<h3>{t('assessment.assignee')}</h3>}
                                    options={(users || []).map((user) => ({
                                        value: user.id,
                                        label: user.name,
                                    }))}
                                    additionalLabel="Optional"
                                    placeholder={`Select a ${t('assessment.singular')} ${t('assessment.assignee')}`}
                                    loadingOptions={isUsersLoading}
                                    loadingMoreOptions={isFetchingMoreUsers}
                                    hasMoreOptions={hasMoreUsers}
                                    onFetchMoreClick={fetchMoreUsers}
                                    onChange={(selection: string) => {
                                        form.setFieldValue(
                                            field.name,
                                            selection
                                        )
                                        field.onChange(selection || '')
                                        updateAssessmentDraft({
                                            assignedToId: selection,
                                        })
                                    }}
                                    disabled={isUsersLoading}
                                />
                            )}
                        </Field>
                        <InfoBox
                            iconName="info"
                            borderColor="transparent"
                            backgroundColor={themeVariables.palettes.info100}
                            textColor={themeVariables.colors.primary}
                            iconColor={themeVariables.palettes.brand600}
                            className={cx('m-t-30', 'm-b-40')}
                        >
                            If you don&apos;t select a model, your{' '}
                            {t('assessment.singular')} will start empty and you
                            can add questions manually.
                        </InfoBox>

                        <FilterLink
                            to={route('assessmentCreateQuestions')}
                            $disabled={!questions?.length}
                        >
                            <span>
                                {questions?.length || 0} questions selected
                            </span>{' '}
                            <FilterLinkColoredText>
                                Click to filter
                            </FilterLinkColoredText>
                        </FilterLink>
                    </Form>
                </Formik>
            </div>
        </FullPageLayout>
    )
}
