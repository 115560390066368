import React, { ReactNode, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { themeVariables } from 'themes/themeVariables'
import { route } from '../utils/routes'
import { Icon, IconName } from './Icon'
import { SidebarDropdownMenu } from './SidebarDropdownMenu'
import { TeamSelector } from './TeamSelector'

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    background-color: ${themeVariables.colors.backgroundSurface};
`

const SideBar = styled.div`
    min-width: 288px;
    max-width: 288px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`

const SideBarInner = styled.div`
    height: 100%;
    padding: 20px;
`

const StickyPart = styled.div`
    position: sticky;
    top: 20px;
    margin-bottom: auto;
`

const PoweredByContainer = styled.div`
    position: sticky;
    border-top: 1px solid ${themeVariables.colors.border};
    bottom: 0;
    padding: 20px;
    color: ${themeVariables.colors.secondary};
    font-weight: ${themeVariables.typography.fontWeight.bold};
    text-transform: uppercase;
    font-size: 11px;
`

const ContanentContainer = styled.div`
    flex: 1;
    box-shadow:
        -1px 0 3px rgb(0 0 0 / 0.025),
        -10px 109px 50px 0px #a3acba1a;
`

const Menu = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 25px;
`

const MenuItemLink = styled(NavLink)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: ${themeVariables.colors.primary};
    padding: 8px 10px;
    &:hover {
        background-color: ${themeVariables.colors.backgroundContainer};
        color: ${themeVariables.colors.primary};
    }
    &.active {
        color: ${themeVariables.colors.brand};
        font-weight: ${themeVariables.typography.fontWeight.bold};
        background-color: ${themeVariables.colors.backgroundContainer};
    }
`

export const Layout = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { t } = useTranslation()
    const links = useMemo(
        () => [
            {
                url: route('home'),
                iconName: 'home',
                label: 'Home',
            },
            {
                url: route('assessments'),
                iconName: 'pieChart',
                label: t('pageNames.assessments'),
            },
            {
                url: route('projects'),
                iconName: 'grid',
                label: t('pageNames.projects'),
            },
            {
                url: route('dataRooms'),
                iconName: 'database',
                label: t('pageNames.dataRooms'),
            },
            {
                url: route('models'),
                iconName: 'server',
                label: t('pageNames.models'),
            },
            {
                url: route('reports'),
                iconName: 'barChart',
                label: t('pageNames.reports'),
            },
            {
                url: route('reportTemplates'),
                iconName: 'package',
                label: t('pageNames.reportTemplates'),
            },
        ],
        []
    )
    return (
        <MainContainer>
            <SideBar>
                <SideBarInner>
                    <StickyPart>
                        <SidebarDropdownMenu />
                        <Menu>
                            {links.map((menuLinkDefinition, index) => (
                                <li key={index}>
                                    <MenuItemLink
                                        to={menuLinkDefinition.url}
                                        className={({ isActive }) =>
                                            isActive ? 'active' : ''
                                        }
                                    >
                                        <Icon
                                            name={
                                                menuLinkDefinition.iconName as IconName
                                            }
                                        />
                                        {menuLinkDefinition.label}
                                    </MenuItemLink>
                                </li>
                            ))}
                        </Menu>
                        <TeamSelector />
                    </StickyPart>
                </SideBarInner>
                <PoweredByContainer>
                    <span>Powered by Silta Finance</span>
                </PoweredByContainer>
            </SideBar>
            <ContanentContainer>{children}</ContanentContainer>
        </MainContainer>
    )
}
