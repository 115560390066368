import React, { useState } from 'react'
import styled from 'styled-components'
import { Dropdown, DropdownContent } from './Dropdown'
import { BaseButton } from './BaseButton'
import { UsernameAndIcon } from './UsernameAndIcon'
import { Icon } from './Icon'
import { SelectBasicItem } from './Form/Select/SelectItemRenderer'
import { themeVariables } from '../themes/themeVariables'
import { LoadMoreButton } from './LoadMoreButton'

const UsersDropdownTitle = styled.div`
    font-weight: 500;
    color: ${themeVariables.colors.secondary};
    padding: 2px 0 8px 12px;
    margin-bottom: 4px;
`

const UserRow = styled(SelectBasicItem)`
    width: 100%
    height: 100%
`

const CheckIcon = styled(Icon)`
    margin-left: auto;
    margin-right: 8px;
`

const UserSelectionTrigger = styled(BaseButton)`
    padding-left: 0;
`

interface User {
    id: string
    name: string
    avatarBlobId?: string | null
}

interface UserSelectionProps {
    selectedUser?: User
    users: User[]
    onUserSelect: (userId: string) => Promise<void>
    hasMoreUsers?: boolean
    isLoading?: boolean
    onLoadMoreUsersClick?: () => void
}

export const UserSelection = ({
    selectedUser,
    users,
    onUserSelect,
    hasMoreUsers,
    onLoadMoreUsersClick,
    isLoading = false,
}: UserSelectionProps) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Dropdown
            trigger={({ onClick }) => (
                <UserSelectionTrigger
                    onClick={(e) => {
                        e.stopPropagation()
                        setIsOpen(true)
                        onClick()
                    }}
                >
                    <UsernameAndIcon
                        user={selectedUser}
                        fallBackForLackOfUser="Unassigned"
                        className="p-l-10"
                    >
                        {isOpen ? (
                            <Icon name="chevronUpSmall" />
                        ) : (
                            <Icon name="chevronDownSmall" />
                        )}
                    </UsernameAndIcon>
                </UserSelectionTrigger>
            )}
            onClose={() => {
                setIsOpen(false)
            }}
        >
            {(dismiss) => (
                <DropdownContent $maxWidth="560px" className="p-u-5 p-b-5">
                    <UsersDropdownTitle>Assign to</UsersDropdownTitle>
                    {users?.map((user) => (
                        <UserRow
                            key={user.id}
                            type="button"
                            onClick={async (e) => {
                                e.stopPropagation()
                                await onUserSelect(user.id)
                                setIsOpen(false)
                                dismiss()
                            }}
                        >
                            <UsernameAndIcon
                                user={user}
                                fallBackForLackOfUser="Unassigned"
                            />
                            {selectedUser?.id === user.id && (
                                <CheckIcon
                                    name="checkSmall"
                                    className=""
                                ></CheckIcon>
                            )}
                        </UserRow>
                    ))}
                    {hasMoreUsers && onLoadMoreUsersClick && (
                        <LoadMoreButton
                            small
                            onClick={onLoadMoreUsersClick}
                            isLoading={isLoading}
                        />
                    )}
                </DropdownContent>
            )}
        </Dropdown>
    )
}
