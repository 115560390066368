import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { themeVariables } from '../themes/themeVariables'
import { Icon } from './Icon'
import { AccessDeniedHandler } from './AccessDeniedHandler'

const FullPageLayoutContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${themeVariables.colors.backgroundSurface};
    display: flex;
`

const FullPageLayoutHeader = styled.header`
    width: 100%;
    border-bottom: 1px solid ${themeVariables.colors.border};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 10px;
    min-height: 60px;
`

const FullPageLayoutHeaderLeft = styled.div`
    display: flex;
    align-items: center;
`

const CloseButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    appearance: none;
`

const CloseIcon = styled(Icon)`
    color: ${themeVariables.colors.secondary};
    svg {
        width: 10px;
        height: 19px;
    }
`

const Separator = styled.div`
    width: 1px;
    height: 12px;
    background-color: ${themeVariables.colors.secondary};
    margin: 0 20px 0 10px;
`

const Breadcrumb = styled.span<{ $secondary?: boolean }>`
    color: ${({ $secondary }) =>
        $secondary
            ? themeVariables.colors.secondary
            : themeVariables.colors.primary};
    a {
        color: ${({ $secondary }) =>
            $secondary
                ? themeVariables.colors.secondary
                : themeVariables.colors.primary};
    }
`

const BreadcrumbIcon = styled(Icon)`
    margin: 0 10px;
`

export const FullPageLayout = ({
    children,
    breadcrumbs,
    closeButtonLink,
    headerCTAContent,
    sidebarContent,
    checkAccess = false,
}: {
    children: ReactNode
    breadcrumbs: {
        label: ReactNode
        link?: string
        secondary?: boolean
    }[]
    closeButtonLink?: string
    headerCTAContent?: ReactNode
    sidebarContent?: ReactNode
    checkAccess?: boolean
}) => {
    const content = (
        <FullPageLayoutContainer>
            <div className="flex-1">
                <FullPageLayoutHeader>
                    <FullPageLayoutHeaderLeft>
                        {closeButtonLink && (
                            <>
                                <CloseButton as={Link} to={closeButtonLink}>
                                    <CloseIcon name="close2" />
                                </CloseButton>
                                <Separator />
                            </>
                        )}

                        <div className={cx('d-flex', 'align-items-center')}>
                            {breadcrumbs.map((item, index: number) => (
                                <div
                                    key={index}
                                    className={cx(
                                        'd-flex',
                                        'align-items-center'
                                    )}
                                >
                                    <Breadcrumb $secondary={item.secondary}>
                                        {item.link ? (
                                            <Link to={item.link}>
                                                {item.label}
                                            </Link>
                                        ) : (
                                            item.label
                                        )}
                                    </Breadcrumb>
                                    {index !== breadcrumbs.length - 1 && (
                                        <BreadcrumbIcon name="chevronRight" />
                                    )}
                                </div>
                            ))}
                        </div>
                    </FullPageLayoutHeaderLeft>
                    {headerCTAContent}
                </FullPageLayoutHeader>
                <div>{children}</div>
            </div>
            {sidebarContent}
        </FullPageLayoutContainer>
    )

    return checkAccess ? (
        <AccessDeniedHandler>{content}</AccessDeniedHandler>
    ) : (
        content
    )
}
