import React, { useCallback } from 'react'
import styled from 'styled-components'
import { toaster } from 'toasterhea'
import { Layer } from '../../utils/layers'
import { themeVariables } from '../../themes/themeVariables'
import { RejectionReason } from '../../utils/exceptions'
import { BaseButton } from '../BaseButton'
import { Icon } from '../Icon'
import { BaseModal, BaseModalProps } from './BaseModal'

const Root = styled.div`
    max-width: 600px;
    width: 90vw;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
`

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 16px;
`

const Title = styled.h3`
    margin: 0;
`

const CloseButton = styled(BaseButton)`
    color: ${themeVariables.colors.secondary};
    padding: 8px;
    transform: translateX(8px);
    &:hover {
        background-color: inherit;
    }
`

const ContentContainer = styled.div`
    padding: 0 24px 24px;
    color: ${themeVariables.colors.secondary};
    overflow-y: auto;
    flex-grow: 1;
`

interface CategorySummaryModalProps extends Omit<BaseModalProps, 'children'> {
    category: string
    summary: string
}

export const CategorySummaryModal = ({
    category,
    summary,
    ...props
}: CategorySummaryModalProps) => (
    <BaseModal {...props}>
        <Root>
            <TitleContainer>
                <Title>{category} summary</Title>
                <CloseButton
                    onClick={() =>
                        props.onReject?.(RejectionReason.CloseButton)
                    }
                >
                    <Icon name="close2" />
                </CloseButton>
            </TitleContainer>
            <ContentContainer>
                {summary.split('\n\n').map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </ContentContainer>
        </Root>
    </BaseModal>
)

const modal = toaster(CategorySummaryModal, Layer.Modal)

export const useCategorySummaryModal = () => {
    return useCallback(async (category: string, summary: string) => {
        try {
            await modal.pop({
                category,
                summary,
            })
        } catch (e) {
            // do nothing
        }
    }, [])
}
