import React, {
    FocusEventHandler,
    ReactNode,
    useMemo,
    useRef,
    useState,
} from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import { themeVariables } from '../../themes/themeVariables'
import { Icon } from '../Icon'
import { FormFieldSize, SelectableOption } from '../../types/form'
import { SelectBasicItem } from './Select/SelectItemRenderer'

const DropdownContainer = styled.div`
    width: 100%;
`

const DropdownButton = styled.div<{
    $isOpen: boolean
    $disabled?: boolean
    $invalid?: boolean
    $size: FormFieldSize
}>`
    position: relative;
    background-color: ${themeVariables.colors.backgroundSurface};
    border: 1px solid ${themeVariables.colors.border};
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow:
        0px 1px 1px 0px #0000001f,
        0px 2px 5px 0px #30313d14;

    ${({ $isOpen }) =>
        $isOpen &&
        `
        border-color: ${themeVariables.palettes.brand300};
        outline: 1px solid ${themeVariables.palettes.brand300};
    `}

    ${({ $disabled }) =>
        $disabled &&
        `
        background-color: ${themeVariables.palettes.neutral200};
        cursor: not-allowed;
        color: ${themeVariables.colors.secondary};
    `}

    ${({ $invalid }) =>
        $invalid &&
        `
        border-color: ${themeVariables.colors.critical};
        outline: 1px solid ${themeVariables.colors.critical};
    `}

    ${({ $size }) => {
        switch ($size) {
            case 'small':
                return `
                padding: 2px 8px;
                font-size: ${themeVariables.typography.fontSizes.caption};
                line-height: ${themeVariables.typography.lineHeight.caption};
                `
            case 'medium':
                return `padding: 4px 4px 4px 8px;`
            case 'extraLarge':
                return `padding: 18px 12px;`
            case 'large':
            default:
                return `padding: 10px; 8px`
        }
    }}
`

const DropdownContent = styled.div`
    position: absolute;
    left: 0;
    bottom: -8px;
    transform: translateY(100%);
    width: 100%;
    border-radius: 4px;
    background-color: ${themeVariables.colors.backgroundSurface};
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
    padding: 8px 0;
    box-shadow:
        0px 8px 12px 0px #091e4226,
        0px 0px 1px 0px #091e424f;
`

const DropdownOptionsLoading = styled.div`
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${themeVariables.colors.disabled};
`

const DropdownLabelContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 3px;
    justify-content: space-between;
`

const DropdownStyledLabel = styled.div`
    font-weight: ${themeVariables.typography.fontWeight.bold};
    color: ${themeVariables.colors.primary};
`

const DropdownAdditionalLabelContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${themeVariables.colors.secondary};
`

const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${themeVariables.colors.secondary};
`

const Spinner = styled(Icon)`
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 16px;
        height: 16px;
    }
`
const ErrorMessage = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 3px;
    color: ${themeVariables.colors.critical};
    font-size: ${themeVariables.typography.fontSizes.caption};
`

const Input = styled.input`
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    flex: 1;
    outline: none;
    min-width: 210px;

    &:disabled {
        background-color: ${themeVariables.colors.backgroundContainer};
        cursor: not-allowed;
    }
`

export const SelectBasicItemIconWrap = styled.div`
    align-items: center;
    color: ${themeVariables.palettes.neutral700};
    display: flex;
    height: 16px;
    justify-content: center;
    width: 16px;
`

export const InputWithAutoComplete = ({
    options,
    value,
    placeholder,
    label,
    additionalLabel,
    disabled = false,
    errorMessage,
    loadingOptions = false,
    onInputChange,
    onSelect,
    containerClassName,
    size = 'large',
    prefixContent,
    onBlur,
    id,
    name,
    rightSideContent,
}: {
    options?: SelectableOption[]
    value: string
    placeholder?: string
    label?: ReactNode
    additionalLabel?: ReactNode
    disabled?: boolean
    errorMessage?: ReactNode
    loadingOptions?: boolean
    onInputChange: (value: string) => void
    onSelect: (value: string) => void
    containerClassName?: string
    size?: FormFieldSize
    prefixContent?: ReactNode
    onBlur?: FocusEventHandler<HTMLSelectElement>
    id?: string
    name?: string
    rightSideContent?: ReactNode
}) => {
    const [isOpen, setIsOpen] = useState(false)

    // for handling the blur event which is used by Formik
    const nativeInput = useMemo(() => {
        const el = document.createElement('select')
        el.setAttribute('id', id ?? '')
        el.setAttribute('name', name ?? '')
        return el
    }, [id, name])

    const triggerOnBlur = (val: string | undefined) => {
        setTimeout(() => {
            onBlur?.({
                ...new FocusEvent('blur'),
                currentTarget: nativeInput,
                target: nativeInput,
                id,
                name,
                value: val,
            } as unknown as React.FocusEvent<HTMLSelectElement>)
        }, 0)
    }

    const changeOpenState = (newOpenState: boolean, newValue?: string) => {
        if (isOpen === newOpenState) {
            // don't do anything if the state is the same
            return
        }
        setIsOpen(newOpenState)
        if (!newOpenState) {
            // trigger blur if we are closing the dropdown
            triggerOnBlur(newValue || value)
        }
    }

    const inputRef = useRef<HTMLInputElement>(null)

    return (
        <OutsideClickHandler onOutsideClick={() => changeOpenState(false)}>
            <DropdownContainer className={containerClassName}>
                {(label || additionalLabel) && (
                    <DropdownLabelContainer>
                        {label && (
                            <DropdownStyledLabel>{label}</DropdownStyledLabel>
                        )}
                        {additionalLabel && (
                            <DropdownAdditionalLabelContainer>
                                {additionalLabel}
                            </DropdownAdditionalLabelContainer>
                        )}
                    </DropdownLabelContainer>
                )}
                <DropdownButton
                    onClick={() => {
                        if (!disabled) {
                            changeOpenState(!isOpen)
                        }
                    }}
                    $isOpen={isOpen}
                    $disabled={disabled}
                    $invalid={!!errorMessage}
                    $size={size}
                >
                    <div className={cx('d-flex', 'w-100')}>
                        {prefixContent}
                        {/* <SelectInputContainer> */}
                        <Input
                            ref={inputRef}
                            value={value || ''}
                            type="text"
                            placeholder={placeholder}
                            onChange={(e) => onInputChange(e.target.value)}
                            disabled={disabled}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onSelect(e.currentTarget.value)
                                    changeOpenState(false)
                                }
                            }}
                        />
                        {/* </SelectInputContainer> */}
                    </div>
                    <div>
                        {rightSideContent}
                        <IconsContainer>
                            {loadingOptions ? <Spinner name="spinner" /> : null}
                        </IconsContainer>
                    </div>

                    {isOpen && value && options && options.length > 0 && (
                        <DropdownContent>
                            {loadingOptions ? (
                                <DropdownOptionsLoading>
                                    Loading...
                                </DropdownOptionsLoading>
                            ) : (
                                options.map((option, index) => (
                                    <SelectBasicItem
                                        key={index}
                                        type="button"
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            onSelect(option.value)
                                            changeOpenState(false)
                                        }}
                                    >
                                        {option.label}
                                    </SelectBasicItem>
                                ))
                            )}
                        </DropdownContent>
                    )}
                </DropdownButton>
                {errorMessage && (
                    <ErrorMessage>
                        <Icon name="warningCircle" />
                        {errorMessage}
                    </ErrorMessage>
                )}
            </DropdownContainer>
        </OutsideClickHandler>
    )
}
