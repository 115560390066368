import React, { useCallback, useMemo, useState } from 'react'
import { toaster } from 'toasterhea'
import styled from 'styled-components'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { PermissionRole } from 'silta-ai-backend'
import { BaseModal, BaseModalProps } from './BaseModal'
import {
    EditorModalButtonsContainer,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalSpinner,
    EditorModalSpinnerContainer,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'
import { Layer } from '../../utils/layers'
import { Button } from '../Button'
import { RejectionReason } from '../../utils/exceptions'
import { RelatedResource } from '../../types/sharing'
import { useUserPermissionsForResourcesQuery } from '../../utils/queries'
import { Icon } from '../Icon'
import { themeVariables } from '../../themes/themeVariables'
import { Separator } from '../Separator'
import { Dropdown, DropdownContent } from '../Dropdown'
import { BaseButton } from '../BaseButton'
import { SelectBasicItem } from '../Form/Select/SelectItemRenderer'
import { useCreatePermission } from '../../utils/mutations'

interface ShareRelatedResourcesModalProps
    extends Omit<BaseModalProps, 'children'> {
    recipientName: string
    recipientTeamId?: string
    recipientEmail?: string
    relatedResources: RelatedResource[]
    checkableResourceIds: string[]
}

const ResourceElementContainer = styled.li`
    padding: 12px 16px;
    background-color: ${themeVariables.colors.backgroundContainer};
    margin-bottom: 10px;
`

const ResourceElementNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${themeVariables.colors.primary};
    border-bottom: 1px solid ${themeVariables.colors.border};
    padding-bottom: 10px;
    margin-bottom: 10px;
    &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
`

const RoleButton = styled(BaseButton)`
    background-color: transparent;
    padding: 4px 8px;
    border-radius: 4px;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
`

const RotatingIcon = styled(Icon)`
    transition: transform 0.2s ease-in-out;
    &.isOpen {
        transform: rotate(180deg);
    }
`

const SelectItem = styled(SelectBasicItem)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const ShareRelatedResourcesModal = ({
    recipientName,
    recipientTeamId,
    recipientEmail,
    relatedResources,
    checkableResourceIds,
    ...props
}: ShareRelatedResourcesModalProps) => {
    const [isSaving, setIsSaving] = useState(false)
    const [newRoles, setNewRoles] = useState<
        Record<string, PermissionRole | undefined>
    >({})

    const createPermission = useCreatePermission()

    const { t } = useTranslation()

    const handlePermissionCreate = useCallback(async (): Promise<boolean> => {
        setIsSaving(true)
        try {
            await Promise.all(
                Object.entries(newRoles).map(([resourceId, role]) => {
                    if (!role) {
                        return Promise.resolve()
                    }
                    const resourcePointer = relatedResources.find(
                        (resource) => resource.id === resourceId
                    )?.pointer
                    return createPermission.mutateAsync({
                        role,
                        ...resourcePointer,
                        teamId: recipientTeamId,
                        email: recipientEmail,
                    })
                })
            )
            return true
        } catch (error) {
            console.error('Error creating permission', error)
            return false
        } finally {
            setIsSaving(false)
        }
    }, [newRoles])

    const { data: permissionsForCheckableResources = [], isFetching } =
        useUserPermissionsForResourcesQuery(
            relatedResources
                .filter((resource) => {
                    return checkableResourceIds.includes(resource.id)
                })
                .map((resource) => resource.pointer),
            recipientTeamId,
            recipientEmail
        )

    const resourcesForWhichUserHasNoPermissionsGropedBytype = useMemo(() => {
        if (isFetching || (!isFetching && !permissionsForCheckableResources)) {
            return {}
        }

        return relatedResources
            .filter((resource) => checkableResourceIds.includes(resource.id))
            .filter((resource) => {
                return permissionsForCheckableResources.find(
                    (permission) =>
                        permission.resourceId === resource.id &&
                        !permission.role
                )
            })
            .reduce(
                (acc, resource) => {
                    if (!acc[resource.type]) {
                        acc[resource.type] = []
                    }
                    acc[resource.type]?.push(resource)
                    return acc
                },
                {} as Record<string, RelatedResource[]>
            )
    }, [isFetching, permissionsForCheckableResources])

    const hasResourcesForWhichWeCanGrantAccess = useMemo(() => {
        return (
            Object.keys(resourcesForWhichUserHasNoPermissionsGropedBytype)
                .length > 0
        )
    }, [resourcesForWhichUserHasNoPermissionsGropedBytype])

    const nonCheckableResourcesGroupedByType = useMemo(() => {
        return relatedResources
            .filter((resource) => {
                return !checkableResourceIds.includes(resource.id)
            })
            .reduce(
                (acc, resource) => {
                    if (!acc[resource.type]) {
                        acc[resource.type] = []
                    }
                    acc[resource.type]?.push(resource)
                    return acc
                },
                {} as Record<string, RelatedResource[]>
            )
    }, [relatedResources, checkableResourceIds])

    const hasNonCheckableResources = useMemo(() => {
        return (
            nonCheckableResourcesGroupedByType &&
            Object.keys(nonCheckableResourcesGroupedByType).length > 0
        )
    }, [nonCheckableResourcesGroupedByType])

    const resourceNames = useMemo<Record<string, string>>(() => {
        return {
            'Precedent Databases': t('precedentDatabase.plural'),
            Project: t('project.singular'),
        }
    }, [])

    return (
        <BaseModal>
            <EditorModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>Related permissions</EditorModalTitle>
                </EditorModalTitleContainer>
                <EditorModalContentContainer>
                    {isFetching && (
                        <div className={cx('m-t-25', 'm-b-25')}>
                            <Icon name="spinner" />
                        </div>
                    )}
                    {!isFetching && (
                        <>
                            {hasResourcesForWhichWeCanGrantAccess && (
                                <div>
                                    <p className="primary">
                                        Note that{' '}
                                        <strong>{recipientName}</strong> does
                                        not have access to the following
                                        resources. Do you want to grant access?
                                    </p>
                                    <ul className={cx('p-0', 'm-0')}>
                                        {Object.keys(
                                            resourcesForWhichUserHasNoPermissionsGropedBytype
                                        ).map((resourceType, index) => (
                                            <ResourceElementContainer
                                                key={index}
                                            >
                                                <p className="m-b-5">
                                                    {resourceType}
                                                </p>
                                                {Object.values(
                                                    resourcesForWhichUserHasNoPermissionsGropedBytype[
                                                        resourceType
                                                    ] ?? []
                                                ).map((resource, index) => (
                                                    <ResourceElementNameContainer
                                                        key={index}
                                                    >
                                                        <span className="emphasized">
                                                            {
                                                                resourceNames[
                                                                    resource
                                                                        .type
                                                                ]
                                                            }
                                                        </span>
                                                        <Dropdown
                                                            trigger={({
                                                                onClick,
                                                                isOpen,
                                                            }) => (
                                                                <RoleButton
                                                                    type="button"
                                                                    onClick={(
                                                                        event
                                                                    ) => {
                                                                        event.stopPropagation()
                                                                        onClick()
                                                                    }}
                                                                    disabled={
                                                                        isSaving
                                                                    }
                                                                >
                                                                    {newRoles[
                                                                        resource
                                                                            .id
                                                                    ] ??
                                                                        'No access'}
                                                                    <RotatingIcon
                                                                        className={cx(
                                                                            {
                                                                                isOpen,
                                                                            }
                                                                        )}
                                                                        name="chevronDown"
                                                                    />
                                                                </RoleButton>
                                                            )}
                                                        >
                                                            {(dismiss) => (
                                                                <DropdownContent>
                                                                    <SelectItem
                                                                        type="button"
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            event.stopPropagation()
                                                                            setNewRoles(
                                                                                {
                                                                                    ...newRoles,
                                                                                    [resource.id]:
                                                                                        'Owner',
                                                                                }
                                                                            )
                                                                            dismiss()
                                                                        }}
                                                                    >
                                                                        Owner
                                                                        {newRoles[
                                                                            resource
                                                                                .id
                                                                        ] ===
                                                                            'Owner' && (
                                                                            <Icon name="check" />
                                                                        )}
                                                                    </SelectItem>
                                                                    <SelectItem
                                                                        type="button"
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            event.stopPropagation()
                                                                            setNewRoles(
                                                                                {
                                                                                    ...newRoles,
                                                                                    [resource.id]:
                                                                                        'Editor',
                                                                                }
                                                                            )
                                                                            dismiss()
                                                                        }}
                                                                    >
                                                                        Editor
                                                                        {newRoles[
                                                                            resource
                                                                                .id
                                                                        ] ===
                                                                            'Editor' && (
                                                                            <Icon name="check" />
                                                                        )}
                                                                    </SelectItem>
                                                                    <SelectItem
                                                                        type="button"
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            event.stopPropagation()
                                                                            setNewRoles(
                                                                                {
                                                                                    ...newRoles,
                                                                                    [resource.id]:
                                                                                        'Viewer',
                                                                                }
                                                                            )
                                                                            dismiss()
                                                                        }}
                                                                    >
                                                                        Viewer
                                                                        {newRoles[
                                                                            resource
                                                                                .id
                                                                        ] ===
                                                                            'Viewer' && (
                                                                            <Icon name="check" />
                                                                        )}
                                                                    </SelectItem>
                                                                    <SelectItem
                                                                        type="button"
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            event.stopPropagation()
                                                                            setNewRoles(
                                                                                {
                                                                                    ...newRoles,
                                                                                    [resource.id]:
                                                                                        undefined,
                                                                                }
                                                                            )
                                                                            dismiss()
                                                                        }}
                                                                    >
                                                                        No
                                                                        access
                                                                        {!newRoles[
                                                                            resource
                                                                                .id
                                                                        ] && (
                                                                            <Icon name="check" />
                                                                        )}
                                                                    </SelectItem>
                                                                </DropdownContent>
                                                            )}
                                                        </Dropdown>
                                                    </ResourceElementNameContainer>
                                                ))}
                                            </ResourceElementContainer>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {hasResourcesForWhichWeCanGrantAccess &&
                                hasNonCheckableResources && (
                                    <Separator
                                        className={cx('m-t-25', 'm-b-25')}
                                    />
                                )}
                            {hasNonCheckableResources && (
                                <div>
                                    <p className="primary">
                                        Note that{' '}
                                        <strong>{recipientName}</strong> may not
                                        have access to the following resources.
                                        Only the owner of those resources can
                                        grant access.
                                    </p>
                                    <ul className={cx('p-0', 'm-0')}>
                                        {Object.keys(
                                            nonCheckableResourcesGroupedByType
                                        ).map((resourceType, index) => (
                                            <ResourceElementContainer
                                                key={index}
                                            >
                                                <p className="m-b-5">
                                                    {resourceType}
                                                </p>
                                                {Object.values(
                                                    nonCheckableResourcesGroupedByType[
                                                        resourceType
                                                    ] ?? []
                                                ).map((resource, index) => (
                                                    <ResourceElementNameContainer
                                                        key={index}
                                                    >
                                                        <span className="emphasized">
                                                            {resource.name}
                                                        </span>
                                                    </ResourceElementNameContainer>
                                                ))}
                                            </ResourceElementContainer>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {!hasResourcesForWhichWeCanGrantAccess &&
                                !hasNonCheckableResources && (
                                    <p className="primary">
                                        <span className="emphasized">
                                            {recipientName}
                                        </span>{' '}
                                        already has access to all related
                                        resources.
                                    </p>
                                )}
                        </>
                    )}
                </EditorModalContentContainer>
                <EditorModalButtonsContainer>
                    <Button
                        $variant="secondary"
                        onClick={() =>
                            props.onReject?.(RejectionReason.CancelButton)
                        }
                    >
                        Close
                    </Button>
                    {hasResourcesForWhichWeCanGrantAccess && (
                        <Button
                            onClick={async () => {
                                const success = await handlePermissionCreate()
                                if (success) {
                                    props.onResolve?.()
                                }
                            }}
                            disabled={
                                isSaving ||
                                Object.values(newRoles).every((role) => !role)
                            }
                        >
                            {isSaving ? (
                                <EditorModalSpinnerContainer>
                                    Saving
                                    <EditorModalSpinner name="spinner" />
                                </EditorModalSpinnerContainer>
                            ) : (
                                'Save'
                            )}
                        </Button>
                    )}
                </EditorModalButtonsContainer>
            </EditorModalRoot>
        </BaseModal>
    )
}

const shareRelatedResourcesModal = toaster(
    ShareRelatedResourcesModal,
    Layer.Modal
)

export const useShareRelatedResourcesModal = () => {
    return useCallback(
        async (
            recipientName: string,
            relatedResources: RelatedResource[],
            checkableResourceIds: string[],
            recipientTeamId?: string,
            recipientEmail?: string
        ) => {
            try {
                await shareRelatedResourcesModal.pop({
                    recipientName,
                    recipientTeamId,
                    recipientEmail,
                    relatedResources,
                    checkableResourceIds,
                })
            } catch (e) {
                // do nothing
            }
        },
        []
    )
}
