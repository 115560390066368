import { BaseItem } from 'components/dropdowns/SearchDropdown/itemRenderers/BaseItem'
import React, { ButtonHTMLAttributes } from 'react'
import { useNavigate } from 'react-router'
import { AssessmentListItem } from 'silta-ai-backend'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { route } from 'utils/routes'

const Content = styled.div`
    min-width: 0;
`

const overflowCss = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const AssessmentName = styled.strong`
    ${overflowCss};

    display: block;
`

const AssessmentDetails = styled.div`
    ${overflowCss};

    color: ${themeVariables.colors.secondary};
`

const AssessmentCustomId = styled.span`
    margin-right: 10px;
`

interface AssessmentItemProps
    extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'value'> {
    value: AssessmentListItem
}

export function AssessmentItem({
    value,
    onClick: onClickProp,
    ...rest
}: AssessmentItemProps) {
    const navigate = useNavigate()

    return (
        <BaseItem
            {...rest}
            onClick={(e) => {
                onClickProp?.(e)

                navigate(route('assessment', value.id))
            }}
        >
            <Content>
                <AssessmentName>{value.name}</AssessmentName>
                <AssessmentDetails>
                    <AssessmentCustomId>
                        {value.project.customId}
                    </AssessmentCustomId>
                    {value.project.name}
                </AssessmentDetails>
            </Content>
        </BaseItem>
    )
}
