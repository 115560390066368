import cx from 'classnames'
import { Table } from 'components/Table'
import {
    ProjectsLineupItem,
    SearchDropdown,
} from 'components/dropdowns/SearchDropdown'
import { DateRenderer } from 'components/renderers/DateRenderer'
import React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useProjectsQuery } from 'utils/queries'
import { Button } from '../components/Button'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { Icon } from '../components/Icon'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { TableDataLoading } from '../components/TableDataLoading'
import { TableNoContent } from '../components/TableNoContent'
import { route } from '../utils/routes'
import { UserAvatar } from '../components/UserAvatar'
import { UserAvatarPlaceholder } from '../components/UserAvatarPlaceholder'
import { LoadMoreButton } from '../components/LoadMoreButton'

const projectsSearchDropdown = <SearchDropdown lineup={[ProjectsLineupItem]} />

export default function ProjectListPage() {
    const {
        data: projects,
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useProjectsQuery({})

    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <Layout>
            <ListTopBar searchComponent={projectsSearchDropdown} />
            <HorizontalLoadingIndicator
                loading={isLoading || isFetchingNextPage}
            />
            <div
                className={cx(
                    'p-l-40',
                    'p-r-40',
                    'p-t-30',
                    'p-b-15',
                    'd-flex',
                    'align-items-center',
                    'justify-space-between'
                )}
            >
                <h1>{t('pageNames.projects')}</h1>
                <Button
                    $size="medium"
                    onClick={() => navigate(route('projectCreate'))}
                >
                    <div className="d-flex align-items-center">
                        <Icon name="plus" />
                        <span className="m-l-5">
                            Upload {t('project.singular')}
                        </span>
                    </div>
                </Button>
            </div>
            <Table
                columns={[
                    {
                        title: 'Name',
                        key: 'name',
                        render: (project) => (
                            <span className="emphasized">{project.name}</span>
                        ),
                        width: 'minmax(min-content, 70%)',
                    },
                    {
                        title: 'Country',
                        key: 'country',
                    },
                    {
                        title: 'Sector',
                        key: 'sector',
                    },
                    {
                        title: t('project.assignee'),
                        key: 'assignedTo',
                        render: (project) => (
                            <div
                                className={cx(
                                    'd-flex',
                                    'g-10',
                                    'align-items-center'
                                )}
                            >
                                {project.assignedTo?.avatarBlobId ? (
                                    <UserAvatar
                                        size={22}
                                        avatarBlobId={
                                            project.assignedTo.avatarBlobId
                                        }
                                    />
                                ) : (
                                    <UserAvatarPlaceholder
                                        size={22}
                                        userName={
                                            project.assignedTo?.name || 'N/A'
                                        }
                                    />
                                )}
                                <span>{project.assignedTo?.name || 'N/A'}</span>
                            </div>
                        ),
                    },
                    {
                        title: 'Last update',
                        key: 'updatedAt',
                        render: (project) => (
                            <DateRenderer value={project.updatedAt} />
                        ),
                    },
                ]}
                data={projects}
                onRowClick={(project) => {
                    navigate(route('project', project.id))
                }}
                stickyHeader
            />
            {isLoading && <TableDataLoading />}
            {!isLoading && projects.length === 0 && (
                <TableNoContent
                    icon="grid"
                    title={`Upload first ${t('project.singular')}`}
                    description={`${t('project.plural')} contain metadata and documents about a project being assessed.`}
                    buttonText={`Upload ${t('project.singular')}`}
                    buttonOnClick={() => navigate(route('projectCreate'))}
                />
            )}
            {hasNextPage && (
                <LoadMoreButton
                    onClick={fetchNextPage}
                    isLoading={isFetchingNextPage}
                />
            )}
        </Layout>
    )
}
