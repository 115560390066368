import React from 'react'
import { AssessmentWithRelations, Outcome } from 'silta-ai-backend'
import { Dropdown, DropdownContent } from '../Dropdown'
import { OutcomeBadge, AnswerBadgeDot } from '../OutcomeBadge'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from '../Form/Select/SelectItemRenderer'
import { useIsAnswerBeingUpdated, useUpdateAnswer } from '../../utils/mutations'

export const OutcomeDropdown = ({
    answer,
    outcomes,
    triggerClassName,
    disabled = false,
}: {
    answer: AssessmentWithRelations['answers'][0]
    outcomes: Outcome[]
    triggerClassName?: string
    disabled?: boolean
}) => {
    const { outcome } = answer
    const updateAnswer = useUpdateAnswer(answer)
    const isAnswerBeingUpdated = useIsAnswerBeingUpdated(answer.id)

    return (
        <>
            {!!outcome && (
                <Dropdown
                    trigger={({ onClick }) => (
                        <OutcomeBadge
                            onClick={() => {
                                if (!disabled) {
                                    onClick()
                                }
                            }}
                            color={outcome.color}
                            label={outcome.label}
                            className={triggerClassName}
                            $disabled={disabled}
                        />
                    )}
                >
                    {(dismiss) => (
                        <DropdownContent>
                            {outcomes.map(
                                ({ id, label, color: backgroundColor }) => (
                                    <SelectBasicItem
                                        key={id}
                                        disabled={
                                            outcome.id === id ||
                                            isAnswerBeingUpdated
                                        }
                                        onClick={() => {
                                            dismiss()

                                            updateAnswer.mutate({
                                                outcomeId: id,
                                            })
                                        }}
                                    >
                                        <SelectBasicItemIconWrap>
                                            <AnswerBadgeDot
                                                style={{
                                                    backgroundColor,
                                                }}
                                            />
                                        </SelectBasicItemIconWrap>
                                        {label}
                                    </SelectBasicItem>
                                )
                            )}
                        </DropdownContent>
                    )}
                </Dropdown>
            )}
        </>
    )
}
