import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Icon } from '../components/Icon'
import { themeVariables } from '../themes/themeVariables'
import { UserAvatar } from './UserAvatar'
import { UserAvatarPlaceholder } from './UserAvatarPlaceholder'

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const StyledIcon = styled(Icon)`
    color: ${themeVariables.colors.secondary};
`

const Username = styled.span`
    font-size: 14px;
`

interface UsernameAndIconProps {
    user?: {
        id: string
        name: string
        avatarBlobId?: string | null
    }
    fallBackForLackOfUser: string
    children?: ReactNode
    className?: string
}

export const UsernameAndIcon = ({
    user,
    fallBackForLackOfUser,
    children,
    className,
}: UsernameAndIconProps) => {
    return (
        <Container className={className}>
            {!user ? (
                <StyledIcon name="userCircle" />
            ) : (
                <>
                    {user.avatarBlobId ? (
                        <UserAvatar avatarBlobId={user.avatarBlobId} />
                    ) : (
                        <UserAvatarPlaceholder userName={user.name} />
                    )}
                </>
            )}
            <Username>{user ? user.name : fallBackForLackOfUser}</Username>
            {children && children}
        </Container>
    )
}
