import { BaseItem } from 'components/dropdowns/SearchDropdown/itemRenderers/BaseItem'
import React, { ButtonHTMLAttributes } from 'react'
import { useNavigate } from 'react-router'
import { ReportListItem } from 'silta-ai-backend'
import styled from 'styled-components'
import { route } from 'utils/routes'

const Content = styled.div`
    min-width: 0;
`

interface ReportItemProps
    extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'value'> {
    value: ReportListItem
}

export function ReportItem({
    value,
    onClick: onClickProp,
    ...rest
}: ReportItemProps) {
    const navigate = useNavigate()

    return (
        <BaseItem
            {...rest}
            onClick={(e) => {
                onClickProp?.(e)

                navigate(route('report', value.id))
            }}
        >
            <Content>{value.name}</Content>
        </BaseItem>
    )
}
