import { AnswerDisplay } from 'components/AnswerDisplay'
import { AssessmentInnerLayout } from 'components/AssessmentPageLayout'
import { AssessmentAnswersFilterToggle } from 'components/Filter/AssessmentAnswersFilterToggle'
import { ClearFilterButton, FilterItem } from 'components/Filter/FilterBar'
import { Icon } from 'components/Icon'
import { Toolbar, ToolbarButton } from 'components/Toolbar'
import { AssessmentNavToolbar } from 'components/Toolbar/AssessmentNavToolbar'
import { ReviewStatusDropdown } from 'components/dropdowns/ReviewStatusDropdown'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import uniq from 'lodash/uniq'
import flattenDeep from 'lodash/flattenDeep'
import { themeVariables } from 'themes/themeVariables'
import { ReviewStatus } from 'types/answers'
import { useFilteredAnswers } from 'utils/answers'
import {
    useAssessmentOutcomes,
    useAssessmentOutcomesById,
} from 'utils/assessments'
import { AssessmentAnswersFilter } from 'utils/filters'
import { useExtract, useRequiredParam } from 'utils/misc'
import {
    useAssessmentQuery,
    useTeamPermissionsForAllResourcesQuery,
    useTeamPermissionsForResourceQuery,
} from 'utils/queries'
import { route } from 'utils/routes'
import { OutcomeDropdown } from '../components/dropdowns/OutcomeDropdown'
import { useAddQuestionModal } from '../components/modals/AddQuestionModal'
import { NewQuestion } from '../components/NewQuestion'
import { useCreateReportModal } from '../components/modals/CreateReportModal'
import { ReadOnly } from '../components/ReadOnly'

const RootInner = styled.div`
    display: grid;
    gap: 16px;
    margin: 0 auto;
    max-width: 778px;
`

const Root = styled.div`
    padding: 40px 40px 96px;
`

const ZeroState = styled.div`
    color: ${themeVariables.palettes.neutral700};
    padding: 96px 0;
    text-align: center;
`

const FilterOptionDisplayRoot = styled.div`
    align-items: center;
    display: flex;
    gap: 6px;
    padding-right: 8px;
    white-space: nowrap;

    small {
        color: ${themeVariables.colors.secondary};
    }
`

interface FilterOptionDisplayProps {
    label: string
    questionCount: number
}

function FilterOptionDisplay({
    label,
    questionCount,
}: FilterOptionDisplayProps) {
    return (
        <FilterOptionDisplayRoot>
            <div>{label}</div>
            <small>
                {questionCount} question{questionCount !== 1 ? 's' : ''}
            </small>
        </FilterOptionDisplayRoot>
    )
}

const OutcomeOptionDot = styled.div`
    border-radius: 50%;
    height: 10px;
    width: 10px;
`

const OutcomeOptionRoot = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
`

interface OutcomeOptionProps {
    color: string
    label: string
    questionCount: number
}

function OutcomeOption({
    label,
    color: background,
    questionCount,
}: OutcomeOptionProps) {
    return (
        <OutcomeOptionRoot>
            <OutcomeOptionDot
                style={{
                    background,
                }}
            />
            <FilterOptionDisplay label={label} questionCount={questionCount} />
        </OutcomeOptionRoot>
    )
}

export function AssessmentShowAnswersPage() {
    const assessmentId = useRequiredParam('id')

    const { t } = useTranslation()

    const [displayFilter, setDisplayFilter] = useState(false)

    const { data: assessment = null } = useAssessmentQuery({ assessmentId })

    const outcomes = useAssessmentOutcomes(assessment)

    const outcomesById = useAssessmentOutcomesById(assessment)

    const navigate = useNavigate()

    const filter = AssessmentAnswersFilter.useValue()

    const openAddQuestionModal = useAddQuestionModal(assessmentId)

    const unfilteredAnswers = assessment?.answers || []

    const createReport = useCreateReportModal()

    const answers = useFilteredAnswers({
        answers: unfilteredAnswers,
        filter,
        outcomesById,
    })

    const questionNumbers = useMemo(() => {
        const lut: Record<string, number> = {}

        unfilteredAnswers.forEach((a, index) => {
            lut[a.id] = index + 1
        })

        return lut
    }, [unfilteredAnswers])

    const categoryFilterOptions = useExtract(unfilteredAnswers, (answers) => {
        const counts: Partial<Record<string, number>> = {}

        for (const answer of answers) {
            const category = answer.sourceQuestion?.category1

            if (category) {
                counts[category] = (counts[category] || 0) + 1
            }
        }

        return Object.entries(counts)
            .map(([id, questionCount = 0]) => ({
                id,
                label: id,
                questionCount,
            }))
            .sort(({ id: a }, { id: b }) => a.localeCompare(b))
    })

    const outcomeFilterOptions = useExtract(
        unfilteredAnswers,
        (answers) => {
            const counts: Partial<Record<string, number>> = {}

            for (const { outcomeId } of answers) {
                if (!outcomeId) {
                    continue
                }

                counts[outcomeId] = (counts[outcomeId] || 0) + 1
            }

            return outcomes.map((outcome) => ({
                color: outcome.color,
                id: outcome.label,
                label: outcome.label,
                questionCount: counts[outcome.id] || 0,
            }))
        },
        [outcomes]
    )

    const statusFilterOptions = useExtract(unfilteredAnswers, (answers) => {
        const counts: Partial<Record<string, number>> = {}

        for (const { status } of answers) {
            counts[status] = (counts[status] || 0) + 1
        }

        return [
            {
                id: 'new',
                label: 'New',
                questionCount: counts.new || 0,
            },
            {
                id: 'running',
                label: 'Running',
                questionCount: counts.running || 0,
            },
            {
                id: 'ready',
                label: 'Ready',
                questionCount: counts.ready || 0,
            },
            {
                id: 'error',
                label: 'Error',
                questionCount: counts.error || 0,
            },
        ]
    })

    const reviewStatusFilterOptions = useExtract(
        unfilteredAnswers,
        (answers) => {
            const counts: Partial<Record<ReviewStatus, number>> = {}

            for (const answer of answers) {
                const { reviewStatus } = answer

                counts[reviewStatus] = (counts[reviewStatus] || 0) + 1
            }

            return [
                {
                    id: 'ReadyForReview',
                    label: 'Ready for review',
                    questionCount: counts.ReadyForReview || 0,
                },
                {
                    id: 'Approved',
                    questionCount: counts.Approved || 0,
                },
                {
                    id: 'Rejected',
                    questionCount: counts.Rejected || 0,
                },
            ]
        }
    )

    const dataRoomIds = useMemo(
        () =>
            uniq(
                flattenDeep(
                    assessment
                        ? assessment.answers.map((a) =>
                              a.sourceMaterials
                                  .filter((sm) => sm.dataRoomId)
                                  .map((sm) => sm.dataRoomId as string)
                          )
                        : []
                )
            ),
        [assessment]
    )

    const {
        data: permissionsToDataRooms,
        isLoading: isFetchingPermissionsToDataRooms,
    } = useTeamPermissionsForAllResourcesQuery(
        dataRoomIds.map((id) => ({ dataRoomId: id }))
    )

    const { data: permission, isFetching: teamPermissionIsLoading } =
        useTeamPermissionsForResourceQuery({
            assessmentId,
        })

    if (!assessment) {
        return null
    }

    return (
        <AssessmentInnerLayout
            assessmentId={assessmentId}
            toolbars={
                <AssessmentNavToolbar
                    assessmentId={assessmentId}
                    lhsAux={
                        <AssessmentAnswersFilterToggle
                            value={displayFilter}
                            onToggle={(newValue) => {
                                setDisplayFilter(newValue)
                            }}
                        />
                    }
                    rhsAux={
                        <>
                            <ToolbarButton
                                onClick={openAddQuestionModal}
                                disabled={
                                    teamPermissionIsLoading ||
                                    permission === 'Viewer'
                                }
                                prepend={<Icon name="plusSmall" />}
                            >
                                Add question
                            </ToolbarButton>
                            <ToolbarButton
                                disabled={!assessment.answers.length}
                                onClick={() => {
                                    createReport(assessmentId)
                                }}
                                prepend={<Icon name="plusSmall" />}
                            >
                                Create {t('report.singular')}
                            </ToolbarButton>
                        </>
                    }
                    answersCount={assessment?.answers.length}
                    reportsCount={assessment?._count.reports}
                />
            }
        >
            {displayFilter && (
                <Toolbar>
                    <FilterItem
                        label="Category"
                        labelPlural="Categories"
                        fieldKey="category"
                        value={filter.category}
                        options={categoryFilterOptions}
                        optionLabelRenderer={(option) => (
                            <FilterOptionDisplay
                                label={option.label}
                                questionCount={option.questionCount}
                            />
                        )}
                    />
                    <FilterItem
                        label="Lead"
                        labelPlural="Leads"
                        fieldKey="lead"
                        value={filter.lead}
                        options={[]}
                    />
                    <FilterItem
                        label="Outcome"
                        labelPlural="Outcomes"
                        fieldKey="outcome"
                        value={filter.outcome}
                        options={outcomeFilterOptions}
                        optionLabelRenderer={(option) => (
                            <OutcomeOption
                                label={option.label}
                                color={option.color}
                                questionCount={option.questionCount}
                            />
                        )}
                    />
                    <FilterItem
                        label="Status"
                        labelPlural="Statuses"
                        fieldKey="status"
                        value={filter.status}
                        options={statusFilterOptions}
                        optionLabelRenderer={(option) => (
                            <FilterOptionDisplay
                                label={option.label}
                                questionCount={option.questionCount}
                            />
                        )}
                    />
                    <FilterItem
                        label="Review status"
                        labelPlural="Review statuses"
                        fieldKey="reviewStatus"
                        value={filter.reviewStatus}
                        options={reviewStatusFilterOptions}
                        optionLabelRenderer={(option) => (
                            <FilterOptionDisplay
                                label={option.label || option.id}
                                questionCount={option.questionCount}
                            />
                        )}
                    />
                    <ClearFilterButton filter={filter} />
                </Toolbar>
            )}
            {!teamPermissionIsLoading && permission === 'Viewer' && (
                <ReadOnly />
            )}
            <Root>
                <RootInner>
                    {answers.length > 0 &&
                    !isFetchingPermissionsToDataRooms &&
                    permissionsToDataRooms ? (
                        <>
                            {answers.map((answer) => (
                                <AnswerDisplay
                                    key={answer.id}
                                    no={questionNumbers[answer.id] as number}
                                    answer={answer}
                                    permissionsToDataRooms={
                                        permissionsToDataRooms
                                    }
                                    actionDropdown={
                                        <ReviewStatusDropdown answer={answer} />
                                    }
                                    outcomeDropdown={
                                        <OutcomeDropdown
                                            answer={answer}
                                            outcomes={outcomes}
                                        />
                                    }
                                    onClick={(_, answer) => {
                                        navigate(
                                            route(
                                                'answer',
                                                answer.assessmentId,
                                                answer.id
                                            )
                                        )
                                    }}
                                />
                            ))}
                            <NewQuestion assessmentId={assessmentId} />
                        </>
                    ) : (
                        <>
                            <ZeroState>No questions.</ZeroState>
                            <NewQuestion assessmentId={assessmentId} />
                        </>
                    )}
                </RootInner>
            </Root>
        </AssessmentInnerLayout>
    )
}
