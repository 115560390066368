import { NavTabs } from 'components/NavTabs'
import React, { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { route } from 'utils/routes'
import { Toolbar } from '.'

interface AssessmentNavToolbar {
    assessmentId: string
    lhsAux?: ReactNode
    rhsAux?: ReactNode
    answersCount?: number
    reportsCount?: number
}

export function AssessmentNavToolbar(props: AssessmentNavToolbar) {
    const { assessmentId, lhsAux, rhsAux, answersCount, reportsCount } = props
    const { t } = useTranslation()
    return (
        <Toolbar>
            <Toolbar $grow>
                <NavTabs>
                    <NavLink to={route('assessment', assessmentId)} end>
                        <small>Summary</small>
                    </NavLink>
                    <NavLink to={route('assessmentAnswers', assessmentId)}>
                        <small>
                            Answers{' '}
                            {typeof answersCount !== 'undefined' && (
                                <span className="secondary">
                                    {answersCount}
                                </span>
                            )}
                        </small>
                    </NavLink>
                    <NavLink to={route('assessmentReports', assessmentId)}>
                        <small>
                            {t('report.plural')}{' '}
                            {typeof reportsCount !== 'undefined' && (
                                <span className="secondary">
                                    {reportsCount}
                                </span>
                            )}
                        </small>
                    </NavLink>
                </NavTabs>
                {lhsAux}
            </Toolbar>
            {rhsAux && <Toolbar>{rhsAux} </Toolbar>}
        </Toolbar>
    )
}
