import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { apiClient } from '../utils/clients'

interface AccessDeniedContextType {
    accessDenied: boolean
    setCustomAccessDeniedHandler: (handler: (() => void) | null) => void
    resetAccessDenied: () => void
}

const AccessDeniedContext = createContext<AccessDeniedContextType | undefined>(
    undefined
)

const useAccessDeniedProvider = (): AccessDeniedContextType => {
    const [accessDenied, setAccessDenied] = useState(false)
    const customAccessDeniedHandlerRef = useRef<(() => void) | null>(null)

    useEffect(() => {
        const handleAccessDenied = () => {
            if (customAccessDeniedHandlerRef.current) {
                customAccessDeniedHandlerRef.current()
            } else {
                setAccessDenied(true)
            }
        }
        apiClient.setAccessDeniedHandler(handleAccessDenied)
        return () => apiClient.setAccessDeniedHandler(undefined)
    }, [])

    return {
        accessDenied,
        setCustomAccessDeniedHandler: (handler: (() => void) | null) => {
            customAccessDeniedHandlerRef.current = handler
        },
        resetAccessDenied: () => {
            setAccessDenied(false)
        },
    }
}

const useAccessDenied = (): AccessDeniedContextType => {
    const accessDenied = useContext(AccessDeniedContext)
    if (!accessDenied) {
        throw new Error(
            'useAccessDenied must be used within a AccessDeniedProvider'
        )
    }
    return accessDenied
}

const AccessDeniedProvider = ({ children }: { children: ReactNode }) => {
    const accessDenied = useAccessDeniedProvider()
    return (
        <AccessDeniedContext.Provider value={accessDenied}>
            {children}
        </AccessDeniedContext.Provider>
    )
}

export { AccessDeniedProvider, useAccessDenied }
