import React from 'react'
import styled from 'styled-components'
import { Icon } from 'components/Icon'
import { useTeamSelection } from 'utils/useTeamSelection'
import { Select } from 'components/Form/Select/Select'
import { Toolbar } from 'components/Toolbar'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from '../themes/themeVariables'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2rem;
    width: 469px;
    margin-top: 120px;
`

const Title = styled.h1`
    font-size: ${themeVariables.typography.fontSizes.heading3};
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 1rem;
    color: ${themeVariables.colors.primary};
    letter-spacing: -0.4px;
`

const Message = styled.p`
    font-size: ${themeVariables.typography.fontSizes.body};
    margin-bottom: 2rem;
    color: ${themeVariables.colors.secondary};
`

const StyledIcon = styled(Icon)`
    align-items: start;
`

const BackButton = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${themeVariables.colors.primary};
    font-size: ${themeVariables.typography.fontSizes.body};

    svg {
        margin-right: 0.5rem;
        transform: rotate(180deg);
    }
`

const TeamLabel = styled.div`
    margin-bottom: 0.5rem;
    font-size: ${themeVariables.typography.fontSizes.large};
    font-weight: ${themeVariables.typography.fontWeight.bold};
`

export function AccessDeniedPage() {
    const navigate = useNavigate()
    const { teamRoles, currentTeamId, switchTeam } = useTeamSelection()
    return (
        <Layout>
            <ListTopBar />
            <Toolbar>
                <BackButton onClick={() => navigate(-1)}>
                    <Icon name="chevronRight" /> Back
                </BackButton>
            </Toolbar>
            <Container>
                <ContentWrapper>
                    <StyledIcon name="filelock" />
                    <div>
                        <Title>You do not have access</Title>
                        <Message>
                            This page is only accessible for certain teams.
                            Please try to switch the team or contact your team
                            owner.
                        </Message>
                        <TeamLabel>Team you&apos;re currently on</TeamLabel>
                        <Select
                            options={
                                teamRoles?.map((team) => ({
                                    label: team.team.name,
                                    value: team.teamId,
                                })) ?? []
                            }
                            value={currentTeamId ?? ''}
                            onChange={(selection) => {
                                if (selection && selection !== currentTeamId) {
                                    switchTeam(selection)
                                }
                            }}
                            clearable={false}
                        />
                    </div>
                </ContentWrapper>
            </Container>
        </Layout>
    )
}
