import { useState, useEffect } from 'react'
import { useCurrentUserQuery, resetAllQueries } from 'utils/queries'
import { getCurrentTeamId, storeCurrentTeam } from 'utils/team'
import { apiClient } from 'utils/clients'
import { useNavigate } from 'react-router'
import { route } from './routes'
import { useAccessDenied } from '../providers/AccessDeniedProvider'

export function useTeamSelection() {
    const { data: user, isFetching } = useCurrentUserQuery()
    const [currentTeamId, setCurrentTeamId] = useState<string | null>(null)
    const navigate = useNavigate()
    const { setCustomAccessDeniedHandler, resetAccessDenied } =
        useAccessDenied()
    useEffect(() => {
        if (!user?.id || !user?.teamRoles) return
        const storedTeamId = getCurrentTeamId(user.id)
        const isValidTeam = user.teamRoles.some(
            (team) => team.teamId === storedTeamId
        )
        setCurrentTeamId(
            isValidTeam ? storedTeamId : user.teamRoles[0]?.teamId ?? null
        )
    }, [user?.id, user?.teamRoles])

    const switchTeam = async (teamId: string) => {
        if (!user?.id || !user?.teamRoles) return

        const selectedTeam = user.teamRoles.find(
            (team) => team.teamId === teamId
        )
        if (!selectedTeam) {
            console.error(`Team with id ${teamId} not found`)
            return
        }

        storeCurrentTeam(user.id, selectedTeam.team)
        setCurrentTeamId(teamId)
        apiClient.setSelectedTeam(teamId)
        setCustomAccessDeniedHandler(() => {
            navigate(route('home'))
        })
        resetAccessDenied()
        await resetAllQueries()
        setCustomAccessDeniedHandler(null)
    }

    return {
        currentTeamId,
        teamRoles: user?.teamRoles,
        switchTeam,
        isFetchingTeamData: isFetching,
    }
}
