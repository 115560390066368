import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import defaultTranslation from './default.json'
import adbTranslation from './adb.json'

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * Note that the i18n mechanism is not used for translations per se!
 * It's used for changing the domain specific language based on the build configuration
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
i18n.use(initReactI18next).init({
    resources: {
        default: {
            translation: defaultTranslation,
        },
        adb: {
            translation: adbTranslation,
        },
    },
    lng: process.env.REACT_APP_UI_THEME || 'default',
    fallbackLng: 'default',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false,
    },
})

export default i18n
