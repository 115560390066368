import React, { useCallback, useState } from 'react'
import { toaster } from 'toasterhea'
import { Team } from 'silta-ai-backend'
import cx from 'classnames'
import { Field, Formik } from 'formik'
import { object, string } from 'yup'
import { BaseModal, BaseModalProps } from './BaseModal'
import { Button } from '../Button'
import { RejectionReason } from '../../utils/exceptions'
import { Icon } from '../Icon'
import { Layer } from '../../utils/layers'
import { useCreateTeamInvite } from '../../utils/mutations'

import {
    EditorModalCloseButton,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalSpinner,
    EditorModalSpinnerContainer,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'
import { TextField } from '../Form/TextField'

export const InviteTeamMembeValidationSchema = object().shape({
    email: string().required('Field is required').email('Invalid email'),
})

interface InviteTeamMemberModalProps extends Omit<BaseModalProps, 'children'> {
    onConfirm: (newName: string) => Promise<void>
}

const InviteTeamMemberModal = ({
    onConfirm,
    ...props
}: InviteTeamMemberModalProps) => {
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const submit = useCallback(async () => {
        try {
            setIsLoading(true)
            await onConfirm(email)
            props.onResolve?.()
        } catch (e) {
            console.error('Error inviting team member', e)
        } finally {
            setIsLoading(false)
        }
    }, [onConfirm, props.onResolve, email])

    return (
        <BaseModal {...props}>
            <EditorModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>Invite member</EditorModalTitle>
                    <EditorModalCloseButton
                        onClick={() =>
                            props.onReject?.(RejectionReason.CloseButton)
                        }
                    >
                        <Icon name="close2" />
                    </EditorModalCloseButton>
                </EditorModalTitleContainer>
                <EditorModalContentContainer>
                    <Formik
                        onSubmit={submit}
                        initialValues={{
                            email: undefined,
                        }}
                        validationSchema={InviteTeamMembeValidationSchema}
                    >
                        <Field name="email">
                            {({
                                field,
                                form,
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            }: any) => (
                                <div className={cx('d-flex', 'g-10', 'm-t-20')}>
                                    <TextField
                                        {...field}
                                        value={email}
                                        type="email"
                                        onChange={(e) => {
                                            field.onChange(e)
                                            setEmail(e.target.value)
                                        }}
                                        errorMessage={
                                            form.touched[field.name] &&
                                            form.errors[field.name]
                                        }
                                        placeholder="Email address"
                                        containerClassName="flex-1"
                                    />
                                    <Button
                                        onClick={submit}
                                        type="button"
                                        disabled={!email || isLoading}
                                        $size="large"
                                    >
                                        {isLoading ? (
                                            <EditorModalSpinnerContainer>
                                                Inviting
                                                <EditorModalSpinner name="spinner" />
                                            </EditorModalSpinnerContainer>
                                        ) : (
                                            'Invite'
                                        )}
                                    </Button>
                                </div>
                            )}
                        </Field>
                    </Formik>
                </EditorModalContentContainer>
            </EditorModalRoot>
        </BaseModal>
    )
}

const inviteModal = toaster(InviteTeamMemberModal, Layer.Modal)

export const useInviteTeamMemberModal = () => {
    const createTeamInvite = useCreateTeamInvite()

    return useCallback(
        async (team: Team) => {
            try {
                await inviteModal.pop({
                    onConfirm: async (email) => {
                        await createTeamInvite.mutateAsync({
                            email,
                            teamId: team.id,
                        })
                    },
                })
            } catch (e) {
                // do nothing
            }
        },
        [createTeamInvite]
    )
}
