import uniqueId from 'lodash/uniqueId'
import React, {
    HTMLAttributes,
    ReactNode,
    RefObject,
    useRef,
    useSyncExternalStore,
} from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

const Root = styled.div<{ $display?: 'inline' | 'inline-block' }>`
    display: ${({ $display }) => $display};
`

const Point = styled.div`
    background: ${themeVariables.palettes.warning500};
    border-radius: 50%;
    height: 10px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 10px;
    z-index: 19999;
`

interface AnchorComponentProps {
    x: number
    y: number
}

export type AnchorTranslateFn = (rect: DOMRect | undefined) => [number, number]

interface AnchorProps<T extends AnchorComponentProps = AnchorComponentProps>
    extends Omit<HTMLAttributes<HTMLDivElement>, 'translate'> {
    component(props: T): ReactNode
    componentProps: Omit<T, 'x' | 'y'>
    display?: 'inline' | 'inline-block'
    indicateOrigin?: boolean
    translate?: AnchorTranslateFn
    innerRef?: RefObject<HTMLDivElement>
}

const AnchorComponentId = uniqueId('AnchorContainer-')

function getAnchorContainer() {
    let el = document.getElementById(AnchorComponentId)

    if (!el) {
        el = document.createElement('div')

        el.id = AnchorComponentId

        document.body.appendChild(el)
    }

    return el
}

function DefaultTranslate(rect: DOMRect | undefined): [number, number] {
    return rect ? [rect.x, rect.y] : [0, 0]
}

/**
 * Mounts given component inside `#AnchorContainer-*` element and passes
 * wrapped element's coordinates to it.
 */
export function Anchor<T extends AnchorComponentProps = AnchorComponentProps>({
    indicateOrigin = false,
    translate = DefaultTranslate,
    component: Component,
    componentProps,
    children,
    display,
    innerRef,
    ...props
}: AnchorProps<T>) {
    const localRef = useRef<HTMLDivElement>(null)

    const ref = innerRef || localRef

    const [x, y] = useBoundingClientRect(ref, translate)

    return (
        <Root {...props} ref={ref} $display={display}>
            {children}
            {Component &&
                createPortal(
                    <Component {...(componentProps as T)} x={x} y={y} />,
                    getAnchorContainer()
                )}
            {indicateOrigin &&
                createPortal(
                    <OriginIndicator x={x} y={y} />,
                    getAnchorContainer()
                )}
        </Root>
    )
}

function resizeSubscribe(cb: () => void) {
    window.addEventListener('resize', cb)

    window.addEventListener('scroll', cb)

    return () => {
        window.removeEventListener('resize', cb)

        window.removeEventListener('scroll', cb)
    }
}

function OriginIndicator({ x, y: yProp }: { x: number; y: number }) {
    const y = yProp + window.scrollY

    return (
        <Point
            style={{
                transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
            }}
        />
    )
}

export function useBoundingClientRect<T>(
    ref: RefObject<HTMLDivElement>,
    translate: (rect: DOMRect | undefined) => T
): T {
    return JSON.parse(
        useSyncExternalStore(resizeSubscribe, () =>
            JSON.stringify(translate(ref.current?.getBoundingClientRect()))
        )
    ) as T
}
