import cx from 'classnames'
import { Table } from 'components/Table'
import { DateRenderer } from 'components/renderers/DateRenderer'
import React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useAssessmentsQuery } from 'utils/queries'
import { AssessmentProgress } from '../components/AssessmentProgress'
import { Button } from '../components/Button'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { Icon } from '../components/Icon'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { TableDataLoading } from '../components/TableDataLoading'
import { TableNoContent } from '../components/TableNoContent'
import { route } from '../utils/routes'
import { LoadMoreButton } from '../components/LoadMoreButton'

export default function AssessmentListPage() {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const {
        data: assessments,
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useAssessmentsQuery({})

    return (
        <Layout>
            <ListTopBar />
            <HorizontalLoadingIndicator
                loading={isLoading || isFetchingNextPage}
            />
            <div
                className={cx(
                    'p-l-40',
                    'p-r-40',
                    'p-t-30',
                    'p-b-15',
                    'd-flex',
                    'align-items-center',
                    'justify-space-between'
                )}
            >
                <h1>{t('pageNames.assessments')}</h1>
                <Button
                    $size="medium"
                    onClick={() => navigate(route('assessmentCreate'))}
                >
                    <div className="d-flex align-items-center">
                        <Icon name="plus" />
                        <span className="m-l-5">
                            Create {t('assessment.singular')}
                        </span>
                    </div>
                </Button>
            </div>
            <Table
                columns={[
                    {
                        key: 'name',
                        title: 'Name',
                        render: (assessment) => (
                            <span className="emphasized">
                                {assessment.name}
                            </span>
                        ),
                        width: 'minmax(min-content, 20%)',
                    },
                    {
                        key: 'projectId',
                        title: `${t('project.singular')} ID`,
                        render: (assessment) => assessment.project.customId,
                    },
                    {
                        key: 'projectName',
                        title: `${t('project.singular')} name`,
                        render: (assessment) => assessment.project.name,
                    },
                    {
                        key: 'evaluationCriteria',
                        title: t('model.singular'),
                        render: (assessment) => assessment.model?.name,
                    },
                    {
                        key: 'createdAt',
                        title: 'Created',
                        render: (assessment) => (
                            <DateRenderer value={assessment.createdAt} />
                        ),
                        width: 'minmax(min-content, 10%)',
                    },
                    {
                        key: 'progress',
                        title: 'Progress',
                        render: (assessment) => (
                            <AssessmentProgress
                                assessment={assessment}
                                showPercentage
                            />
                        ),
                    },
                ]}
                data={assessments}
                onRowClick={(assessment) => {
                    navigate(route('assessment', assessment.id))
                }}
                stickyHeader
            />
            {isLoading && <TableDataLoading />}
            {!isLoading && assessments.length === 0 && (
                <TableNoContent
                    icon="pieChart"
                    title="Create first Assessment"
                    description="Assessments are Evaluation Criteria applied to a Project."
                    buttonText="Create Assessment"
                    buttonOnClick={() => navigate(route('assessmentCreate'))}
                />
            )}
            {hasNextPage && (
                <LoadMoreButton
                    onClick={fetchNextPage}
                    isLoading={isFetchingNextPage}
                />
            )}
        </Layout>
    )
}
