import { useEffect, useState } from 'react'

export function useDebouncedValue<T>(value: T, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(
        function updateDebounceValue() {
            let mounted = true

            const handler = setTimeout(() => {
                if (mounted) {
                    setDebouncedValue(value)
                }
            }, delay)

            return () => {
                mounted = false

                clearTimeout(handler)
            }
        },
        [value, delay]
    )

    return debouncedValue
}
