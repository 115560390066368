import { SelectBasicItem as PrestyledSelectBasicItem } from 'components/Form/Select/SelectItemRenderer'
import React, { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

export const BaseItemSidePadding = '20px'

const SelectBasicItem = styled(PrestyledSelectBasicItem)`
    padding-left: ${BaseItemSidePadding};
    padding-right: ${BaseItemSidePadding};

    strong {
        font-weight: ${themeVariables.typography.fontWeight.emphasized};
    }

    &:focus {
        background-color: ${themeVariables.palettes.neutral200};
        outline: 0;
    }
`

interface BaseItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export function BaseItem(props: BaseItemProps) {
    const { children, type = 'button', ...rest } = props

    return (
        <SelectBasicItem {...rest} type={type}>
            {children}
        </SelectBasicItem>
    )
}
