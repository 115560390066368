import React, { useCallback, useState } from 'react'
import { toaster } from 'toasterhea'
import { Field, Form, Formik } from 'formik'
import { object, ref, string } from 'yup'
import cx from 'classnames'
import { BaseModal, BaseModalProps } from './BaseModal'
import { Button } from '../Button'
import { RejectionReason } from '../../utils/exceptions'
import { Icon } from '../Icon'
import { Layer } from '../../utils/layers'
import { useUpdateMyProfile } from '../../utils/mutations'

import {
    EditorModalButtonsContainer,
    EditorModalCloseButton,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalSpinner,
    EditorModalSpinnerContainer,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'
import { TextField } from '../Form/TextField'
import { BaseButton } from '../BaseButton'
import { InfoBox } from '../InfoBox'
import { themeVariables } from '../../themes/themeVariables'
import { validatePassword } from '../../utils/password'

interface ChangePasswordModalProps extends Omit<BaseModalProps, 'children'> {
    onConfirm: ({
        oldPassword,
        newPassword,
    }: {
        oldPassword: string
        newPassword: string
    }) => Promise<void>
}

export const ChangePasswordValidationSchema = object().shape({
    newPassword: string()
        .required('Field is required')
        .test('is-valid-password', 'Invalid password', (value) =>
            validatePassword(value || '')
        ),
    newPasswordRepeat: string()
        .required('Field is required')
        .oneOf([ref('newPassword'), null], 'Passwords must match'),
    oldPassword: string().required('Field is required'),
})

const ChangePasswordModal = ({
    onConfirm,
    ...props
}: ChangePasswordModalProps) => {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('')
    const [oldPasswordVisible, setOldPasswordVisible] = useState(false)
    const [newPasswordVisible, setNewPasswordVisible] = useState(false)
    const [newPasswordRepeatVisible, setNewPasswordRepeatVisible] =
        useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)

    return (
        <BaseModal {...props}>
            <EditorModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>Change password</EditorModalTitle>
                    <EditorModalCloseButton
                        onClick={() =>
                            props.onReject?.(RejectionReason.CloseButton)
                        }
                    >
                        <Icon name="close2" />
                    </EditorModalCloseButton>
                </EditorModalTitleContainer>
                <EditorModalContentContainer>
                    <Formik
                        onSubmit={() => {
                            // todo
                        }}
                        initialValues={{}}
                        validationSchema={ChangePasswordValidationSchema}
                    >
                        <Form>
                            <div className="m-b-30">
                                <Field name="oldPassword">
                                    {({
                                        field,
                                        form,
                                    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    any) => (
                                        <TextField
                                            {...field}
                                            label="Current password"
                                            value={oldPassword || ''}
                                            errorMessage={
                                                form.touched[field.name] &&
                                                form.errors[field.name]
                                            }
                                            placeholder="Enter current password"
                                            type={
                                                oldPasswordVisible
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            onChange={(e) => {
                                                field.onChange(e)
                                                setOldPassword(e.target.value)
                                            }}
                                            additionalInnerContent={
                                                <BaseButton
                                                    onClick={() =>
                                                        setOldPasswordVisible(
                                                            !oldPasswordVisible
                                                        )
                                                    }
                                                >
                                                    <Icon
                                                        className="secondary"
                                                        name={
                                                            oldPasswordVisible
                                                                ? 'eyeOff'
                                                                : 'eye'
                                                        }
                                                    />
                                                </BaseButton>
                                            }
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="m-b-30">
                                <Field name="newPassword">
                                    {({
                                        field,
                                        form,
                                    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    any) => (
                                        <TextField
                                            {...field}
                                            label="New password"
                                            value={newPassword || ''}
                                            errorMessage={
                                                form.touched[field.name] &&
                                                form.errors[field.name]
                                            }
                                            placeholder="Enter new password"
                                            type={
                                                newPasswordVisible
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            onChange={(e) => {
                                                field.onChange(e)
                                                setNewPassword(e.target.value)
                                            }}
                                            additionalInnerContent={
                                                <BaseButton
                                                    onClick={() =>
                                                        setNewPasswordVisible(
                                                            !newPasswordVisible
                                                        )
                                                    }
                                                >
                                                    <Icon
                                                        className="secondary"
                                                        name={
                                                            newPasswordVisible
                                                                ? 'eyeOff'
                                                                : 'eye'
                                                        }
                                                    />
                                                </BaseButton>
                                            }
                                        />
                                    )}
                                </Field>
                                <p className={cx('secondary', 'm-t-10')}>
                                    Use at least 16 characters long. Contains
                                    upper and lower case characters. Contains at
                                    least one number. Contains at least one
                                    special character.
                                </p>
                            </div>
                            <div className="m-b-30">
                                <Field name="newPasswordRepeat">
                                    {({
                                        field,
                                        form,
                                    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    any) => (
                                        <TextField
                                            {...field}
                                            label="Confirm new password"
                                            value={newPasswordRepeat || ''}
                                            errorMessage={
                                                form.touched[field.name] &&
                                                form.errors[field.name]
                                            }
                                            placeholder="Confirm new password"
                                            type={
                                                newPasswordRepeatVisible
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            onChange={(e) => {
                                                field.onChange(e)
                                                setNewPasswordRepeat(
                                                    e.target.value
                                                )
                                            }}
                                            additionalInnerContent={
                                                <BaseButton
                                                    onClick={() =>
                                                        setNewPasswordRepeatVisible(
                                                            !newPasswordRepeatVisible
                                                        )
                                                    }
                                                >
                                                    <Icon
                                                        className="secondary"
                                                        name={
                                                            newPasswordRepeatVisible
                                                                ? 'eyeOff'
                                                                : 'eye'
                                                        }
                                                    />
                                                </BaseButton>
                                            }
                                        />
                                    )}
                                </Field>
                            </div>
                        </Form>
                    </Formik>
                    {error && (
                        <InfoBox
                            backgroundColor={
                                themeVariables.palettes.critical100
                            }
                            borderColor={themeVariables.palettes.critical600}
                            textColor={themeVariables.colors.primary}
                            iconColor={themeVariables.palettes.critical600}
                            iconName="warningCircle"
                        >
                            Error occurred while changing password
                        </InfoBox>
                    )}
                </EditorModalContentContainer>
                <EditorModalButtonsContainer>
                    <Button
                        $variant="secondary"
                        onClick={() =>
                            props.onReject?.(RejectionReason.CancelButton)
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            try {
                                setIsLoading(true)
                                setError(false)
                                await onConfirm({
                                    oldPassword,
                                    newPassword,
                                })
                                props.onResolve?.()
                            } catch (e) {
                                console.error('Error changing password', e)
                                setError(true)
                            } finally {
                                setIsLoading(false)
                            }
                        }}
                        disabled={
                            !ChangePasswordValidationSchema.isValidSync({
                                oldPassword,
                                newPassword,
                                newPasswordRepeat,
                            }) || isLoading
                        }
                    >
                        {isLoading ? (
                            <EditorModalSpinnerContainer>
                                Saving
                                <EditorModalSpinner name="spinner" />
                            </EditorModalSpinnerContainer>
                        ) : (
                            'Save'
                        )}
                    </Button>
                </EditorModalButtonsContainer>
            </EditorModalRoot>
        </BaseModal>
    )
}

const modal = toaster(ChangePasswordModal, Layer.Modal)

export const useChangePasswordModal = () => {
    const updateMyProfile = useUpdateMyProfile()

    return useCallback(async () => {
        try {
            await modal.pop({
                onConfirm: async ({ oldPassword, newPassword }) => {
                    await updateMyProfile.mutateAsync({
                        oldPassword,
                        newPassword,
                    })
                },
            })
        } catch (e) {
            // do nothing
        }
    }, [updateMyProfile])
}
