import React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { themeVariables } from '../themes/themeVariables'
import { Icon } from './Icon'

const Container = styled.div`
    background-color: ${themeVariables.palettes.brand100};
    padding: 16px 40px;
`

const WarningIcon = styled(Icon)`
    color: ${themeVariables.palettes.brand700};
    width: 20px;
    height: 20px;
    svg {
        width: 20px;
        height: 20px;
    }
`
export const ReadOnly = () => {
    return (
        <Container>
            <div className={cx('d-flex', 'g-10', 'align-items-center')}>
                <WarningIcon name="warningCircleOutline" />
                <span className={cx('emphasized', 'large')}>
                    This resource is read-only
                </span>
            </div>
        </Container>
    )
}
