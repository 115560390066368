import React, { useMemo } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { useNavigate } from 'react-router'
import { Dropdown, DropdownContent, DropdownItemSeparator } from './Dropdown'
import { BaseButton } from './BaseButton'
import { SelectBasicItem } from './Form/Select/SelectItemRenderer'
import { themeVariables } from '../themes/themeVariables'
import { Icon } from './Icon'
import { useTeamSelection } from '../utils/useTeamSelection'
import { route } from '../utils/routes'
import { useNameInputModal } from './modals/NameInputModal'
import { useCreateTeam } from '../utils/mutations'
import { showToast, ToastType } from './Toast/Toast'

const TeamDropdownButton = styled(BaseButton)`
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: ${themeVariables.colors.backgroundContainer};
    padding: 10px 8px;

    &:hover {
        background-color: ${themeVariables.palettes.neutral300};
    }
`

const BiggerSelectBasicItem = styled(SelectBasicItem)`
    padding: 12px;
`

const TeamOption = styled(BiggerSelectBasicItem)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.active {
        background-color: ${themeVariables.colors.brand};
        color: ${themeVariables.colors.backgroundSurface};
    }
`

const TeamSettingButton = styled(BiggerSelectBasicItem)`
    display: flex;
    align-items: center;
    gap: 10px;
`

const TeamSettingButtonIcon = styled(Icon)`
    width: 16px;
    height: 16px;
    align-items: center;
`

export const TeamSelector = () => {
    const navigate = useNavigate()
    const { currentTeamId, teamRoles: teams, switchTeam } = useTeamSelection()
    const currentTeamRole = useMemo(
        () => teams?.find((team) => team.teamId === currentTeamId),
        [teams, currentTeamId]
    )
    const createTeam = useCreateTeam()
    const openNameInputModal = useNameInputModal()

    const orderedTeams = useMemo(() => {
        if (!teams) return []
        const personalTeam = teams.find((team) => team.team.personalTeamOf)
        if (!personalTeam) {
            console.error('Personal team not found')
            return []
        }
        const otherTeams = teams.filter((team) => !team.team.personalTeamOf)
        return [
            personalTeam,
            ...otherTeams.sort((a, b) =>
                a.team.name.localeCompare(b.team.name)
            ),
        ]
    }, [teams])

    return (
        <>
            <p className={cx('secondary', 'm-b-5')}>Your teams</p>
            <Dropdown
                trigger={({ onClick }) => (
                    <TeamDropdownButton onClick={onClick}>
                        {currentTeamRole?.team.name}
                        <Icon name="chevronDown" />
                    </TeamDropdownButton>
                )}
            >
                {(dismiss) => (
                    <DropdownContent>
                        {orderedTeams?.map((team) => (
                            <TeamOption
                                key={team.teamId}
                                type="button"
                                onClick={() => {
                                    dismiss()
                                    if (
                                        currentTeamRole?.teamId !== team.teamId
                                    ) {
                                        switchTeam(team.teamId)
                                        showToast({
                                            title: `Switched to ${team.team.name}`,
                                            type: ToastType.Success,
                                            autoCloseAfter: 5,
                                        })
                                    }
                                }}
                                className={cx({
                                    active:
                                        currentTeamRole?.teamId === team.teamId,
                                })}
                            >
                                {team.team.name}
                                {currentTeamRole?.teamId === team.teamId && (
                                    <Icon name="check" />
                                )}
                            </TeamOption>
                        ))}
                        <DropdownItemSeparator />
                        <TeamSettingButton
                            type="button"
                            onClick={() => {
                                dismiss()
                                navigate(route('teamSettings'))
                            }}
                        >
                            <TeamSettingButtonIcon name="settings" />
                            Team Settings
                        </TeamSettingButton>
                        <TeamSettingButton
                            type="button"
                            onClick={() => {
                                dismiss()
                                navigator.clipboard.writeText(
                                    currentTeamRole?.teamId ?? ''
                                )
                                showToast({
                                    title: 'Team ID copied',
                                    type: ToastType.Success,
                                })
                            }}
                        >
                            <TeamSettingButtonIcon name="copy" />
                            Copy team ID
                        </TeamSettingButton>
                        <TeamSettingButton
                            type="button"
                            onClick={() => {
                                dismiss()
                                openNameInputModal({
                                    onConfirm: async (name) => {
                                        await createTeam.mutateAsync({
                                            name,
                                        })
                                    },
                                    modalTitle: 'Create new team',
                                    buttonCtaText: 'Create team',
                                    buttonCtaSavingText: 'Creating...',
                                    additionalText:
                                        'After creating a team, you can invite others to join',
                                })
                            }}
                        >
                            <TeamSettingButtonIcon name="plus" />
                            Create new team
                        </TeamSettingButton>
                    </DropdownContent>
                )}
            </Dropdown>
        </>
    )
}
