import React from 'react'
import { AccessDeniedPage } from '../pages/AccessDeniedPage'
import { useAccessDenied } from '../providers/AccessDeniedProvider'

interface AccessDeniedHandlerProps {
    children: React.ReactNode
}

export const AccessDeniedHandler: React.FC<AccessDeniedHandlerProps> = ({
    children,
}) => {
    const { accessDenied } = useAccessDenied()

    if (accessDenied) {
        return <AccessDeniedPage />
    }

    return <>{children}</>
}
