import { BaseItem } from 'components/dropdowns/SearchDropdown/itemRenderers/BaseItem'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from 'components/Form/Select/SelectItemRenderer'
import { Icon as PrestyledIcon } from 'components/Icon'
import React, { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { queryClient } from 'utils/clients'
import { deleteRecentSearch } from 'utils/search'

const Icon = styled(PrestyledIcon)`
    svg {
        width: 20px;
        height: 20px;
    }
`

const DeleteButton = styled.button`
    appearance: none;
    background: none;
    border: 0;
    color: ${themeVariables.colors.secondary};
    height: 20px;
    outline: 0;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    width: 20px;
`

const Root = styled.div`
    position: relative;

    &:hover ${SelectBasicItem} {
        background: ${themeVariables.palettes.neutral200};
    }

    &:hover ${DeleteButton} {
        visibility: visible;
    }
`

const Value = styled.div`
    flex-grow: 1;
`

interface RecentSearchItemProps
    extends Omit<
        ButtonHTMLAttributes<HTMLButtonElement>,
        'children' | 'value'
    > {
    value: { id: string }
}

export function RecentSearchItem({
    value,
    onClick: onClickProp,
    ...rest
}: RecentSearchItemProps) {
    return (
        <Root>
            <BaseItem
                {...rest}
                onClick={(e) => {
                    onClickProp?.(e)
                }}
            >
                <SelectBasicItemIconWrap>
                    <Icon name="search" />
                </SelectBasicItemIconWrap>
                <Value>{value.id}</Value>
            </BaseItem>
            <DeleteButton
                type="button"
                onClick={() => {
                    deleteRecentSearch(value.id)

                    queryClient.invalidateQueries({
                        queryKey: ['search', ''],
                    })
                }}
            >
                <PrestyledIcon name="close" />
            </DeleteButton>
        </Root>
    )
}
