import queryString from 'query-string'
import { WritableFilter } from 'types/filters'
import {
    AssessmentAnswersFilter,
    ModelQuestionsFilter,
    flattenFilter,
} from './filters'

/**
 * @todo Replace any with something else and drop @typescript-eslint/no-explicit-any disabling.
 */

function formatRouteWithSearchParams(
    rawRoute: string,
    searchParams: Record<string, unknown> = {}
) {
    return ((qs: string) => `${rawRoute}${qs && `?${qs}`}`)(
        queryString.stringify(searchParams)
    )
}

const definitions = {
    home: '/',
    login: '/login',
    overview: '/overview',
    assessments: '/assessments',
    assessment: (id: string) => `/assessments/${id}`,
    assessmentAnswers: (
        id: string,
        filter: WritableFilter<AssessmentAnswersFilter> = {}
    ) =>
        formatRouteWithSearchParams(`/assessments/${id}/answers`, {
            filter: flattenFilter(filter),
        }),
    assessmentReports: (id: string) => `/assessments/${id}/reports`,
    assessmentCreate: (projectId?: string) =>
        formatRouteWithSearchParams('/assessments/create', {
            projectId,
        }),
    assessmentCreateQuestions: '/assessments/create/questions',
    answer: (assessmentId: string, answerId: string) =>
        `/assessments/${assessmentId}/answers/${answerId}`,
    document: (dataRoomId: string, documentId: string) =>
        `/dataRooms/${dataRoomId}/documents/${documentId}`,
    forgotPassword: '/forgotPassword',
    profile: '/profile',
    projects: '/projects',
    project: (id: string) => `/projects/${id}`,
    projectDataRoom: (id: string) => `/projects/${id}/dataRoom`,
    projectCreate: '/projects/create',
    dataRooms: '/dataRooms',
    dataRoom: (id: string) => `/dataRooms/${id}`,
    models: '/models',
    model: (id: string, filter: WritableFilter<ModelQuestionsFilter> = {}) =>
        formatRouteWithSearchParams(`/models/${id}`, {
            filter: flattenFilter(filter),
        }),
    reports: '/reports',
    report: (id: string) => `/reports/${id}`,
    reportTemplates: '/reportTemplates',
    reportTemplate: (id: string) => `/reportTemplates/${id}`,
    signUp: '/signUp/:signupCode',
    setNewPassword: (token: string) => `/newPassword/${token}`,
    termsAndConditions: '/termsAndConditions',
    teamSettings: '/teamSettings',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
} satisfies Record<string, string | ((...args: any) => string)>

type Definitions = typeof definitions

type RouteKey = keyof Definitions

type RouteParameters<T> = T extends (...args: infer P) => string ? P : []

export function route<T extends RouteKey, D extends Definitions[T]>(
    key: T,
    ...args: RouteParameters<D>
) {
    const fn = definitions[key]

    if (typeof fn === 'string') {
        return fn
    }

    return (fn as (...args: RouteParameters<D>) => string)(...args)
}
