import React from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import { FullPageLayout } from '../components/FullPageLayout'
import { Button } from '../components/Button'
import { useAuth } from '../providers/AuthProvider'
import { useCurrentUserQuery } from '../utils/queries'
import { useAcceptTermsAndConditions } from '../utils/mutations'

const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 660px;
    width: 100%;
    margin: 64px auto;
`

const TermsList = styled.ol`
    list-style: decimal;
    list-style-position: outside;
    padding-left: 25px;
    margin: 25px 0;
    li {
        margin-bottom: 20px;
    }
`

export const TermsAndConditionsPage = () => {
    const { logout } = useAuth()
    const { data: user = null } = useCurrentUserQuery()
    const acceptTerms = useAcceptTermsAndConditions()
    return (
        <FullPageLayout
            breadcrumbs={[]}
            headerCTAContent={
                <div className={cx('d-flex', 'align-items-center', 'g-10')}>
                    <Button $variant="secondary" onClick={() => logout(true)}>
                        Cancel
                    </Button>
                    <Button
                        disabled={
                            !!user?.termsAcceptedAt || acceptTerms.isPending
                        }
                        onClick={() => acceptTerms.mutate()}
                    >
                        Accept
                    </Button>
                </div>
            }
        >
            <Root>
                <h3>IMPORTANT: Disclaimer</h3>
                <div className="m-t-35">
                    <p>
                        By accessing and using this AI-powered analysis tool,
                        you acknowledge and agree to the following terms:
                    </p>
                    <TermsList>
                        <li>
                            Data Accuracy and Completeness: The analysis
                            provided by this tool is based on the data and
                            information supplied to the AI engine. The accuracy
                            and completeness of the analysis depend
                            significantly on the quality, completeness, and
                            accuracy of the data provided by your organization.
                        </li>
                        <li>
                            AI Limitations: The AI-driven analysis is generated
                            based on the structure of the questions presented
                            and the available data. While the AI strives to
                            provide accurate and useful insights, it may contain
                            errors, omissions, or misinterpretations due to the
                            inherent limitations of AI technology.
                        </li>
                        <li>
                            User Responsibility: This tool is designed to
                            facilitate, but not replace, your analysis of data.
                            It is your sole responsibility to review, validate,
                            and verify the accuracy and reliability of the
                            AI-generated analysis before using it for any
                            purpose. You should not rely solely on the AI
                            analysis for making critical decisions or
                            incorporating it into your official reports without
                            independent verification.
                        </li>
                        <li>
                            No Warranty: The AI analysis is provided &quot;as
                            is&quot; without any warranties of any kind, either
                            express or implied, including but not limited to the
                            implied warranties of merchantability, fitness for a
                            particular purpose, and non-infringement.
                        </li>
                        <li>
                            Liability: Silta Finance disclaims any liability for
                            any direct, indirect, incidental, special,
                            consequential, or punitive damages arising out of
                            your use of the AI analysis, including any
                            inaccuracies or errors in the information generated.
                        </li>
                    </TermsList>
                    <p>
                        By clicking &quot;Accept&quot; or using this tool, you
                        confirm that you understand and accept these terms and
                        conditions. If you do not agree with these terms, please
                        do not use the AI-powered analysis tool.
                    </p>
                </div>
            </Root>
        </FullPageLayout>
    )
}
