import cx from 'classnames'
import { Table } from 'components/Table'
import { DateRenderer } from 'components/renderers/DateRenderer'
import React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useReportsQuery } from 'utils/queries'
import { Button } from '../components/Button'
import { CircularProgressIndicator } from '../components/CircularProgressIndicator'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { Icon } from '../components/Icon'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { TableDataLoading } from '../components/TableDataLoading'
import { TableNoContent } from '../components/TableNoContent'
import { useCreateReportModal } from '../components/modals/CreateReportModal'
import { getProgressColor } from '../utils/reports'
import { route } from '../utils/routes'
import { LoadMoreButton } from '../components/LoadMoreButton'

export function ReportListPage() {
    const {
        data: reports,
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useReportsQuery({})

    const openCreateReportModal = useCreateReportModal()

    const navigate = useNavigate()

    const { t } = useTranslation()

    return (
        <Layout>
            <ListTopBar />
            <HorizontalLoadingIndicator
                loading={isLoading || isFetchingNextPage}
            />
            <div
                className={cx(
                    'p-l-40',
                    'p-r-40',
                    'p-t-30',
                    'p-b-15',
                    'd-flex',
                    'align-items-center',
                    'justify-space-between'
                )}
            >
                <h1>{t('report.plural')}</h1>
                <Button
                    $size="medium"
                    onClick={() => {
                        openCreateReportModal()
                    }}
                >
                    <div className="d-flex align-items-center">
                        <Icon name="plus" />
                        <span className="m-l-5">
                            Create {t('report.singular').toLowerCase()}
                        </span>
                    </div>
                </Button>
            </div>
            <Table
                columns={[
                    {
                        title: 'Name',
                        key: 'name',
                    },
                    {
                        title: t('project.singular'),
                        key: 'project',
                        render: (report) => report.assessment.project.name,
                    },
                    {
                        title: t('assessment.singular'),
                        key: 'assessmentName',
                        render: (report) => (
                            <span className="emphasized">
                                {report.assessment.name}
                            </span>
                        ),
                    },
                    {
                        title: t('reportTemplate.singular'),
                        key: 'template',
                        render: (report) => report.reportTemplate.name,
                    },
                    {
                        title: 'Last update',
                        key: 'updatedAt',
                        render: (report) => (
                            <DateRenderer value={report.updatedAt} />
                        ),
                    },
                    {
                        title: 'Progress',
                        key: 'progress',
                        render: (report) => (
                            <div
                                className={cx(
                                    'd-flex',
                                    'g-10',
                                    'align-items-center'
                                )}
                            >
                                <CircularProgressIndicator
                                    progress={report.progress / 100}
                                    color={getProgressColor(report.status)}
                                />
                                {report.progress}%
                            </div>
                        ),
                    },
                ]}
                data={reports}
                onRowClick={(report) => {
                    navigate(route('report', report.id))
                }}
                stickyHeader
            />
            {isLoading && <TableDataLoading />}
            {!isLoading && reports.length === 0 && (
                <TableNoContent
                    icon="barChart"
                    title={`Create first ${t('report.singular')}`}
                    description={`${t('report.plural')} are summaries of ${t('assessment.plural')} in downloadable document form.`}
                    buttonText={`Create ${t('report.singular')}`}
                    buttonOnClick={() => openCreateReportModal()}
                />
            )}
            {hasNextPage && (
                <LoadMoreButton
                    onClick={fetchNextPage}
                    isLoading={isFetchingNextPage}
                />
            )}
        </Layout>
    )
}
