import React from 'react'
import { AssessmentWithRelations } from 'silta-ai-backend'
import { getReviewedCount } from 'utils/answers'
import { getColorForProgress } from 'utils/assessments'
import { useExtract } from 'utils/misc'
import { CircularProgressIndicator } from './CircularProgressIndicator'
import { Icon } from './Icon'
import { DateRenderer } from './renderers/DateRenderer'
import { EntityProperties } from './EntityProperties'
import { UserAvatar } from './UserAvatar'
import { UserAvatarPlaceholder } from './UserAvatarPlaceholder'

interface AssessmentPropertiesProps {
    assessment: AssessmentWithRelations
}

export function AssessmentProperties({
    assessment,
}: AssessmentPropertiesProps) {
    const progress = useExtract(assessment, ({ answers }) =>
        answers.length ? getReviewedCount(answers) / answers.length : 0
    )
    return (
        <EntityProperties
            entityProps={[
                {
                    name: 'Answers',
                    value: {
                        content: assessment.answers.length,
                        icon: <Icon name="stack" />,
                    },
                },
                {
                    name: 'Progress',
                    value: {
                        content: `${Math.floor(progress * 100)}%`,
                        icon: (
                            <CircularProgressIndicator
                                progress={progress}
                                color={getColorForProgress(progress)}
                            />
                        ),
                    },
                },
                {
                    name: 'Lead',
                    value: {
                        content: assessment.assignedTo?.name || 'N/A',
                        icon: !assessment.assignedTo ? (
                            <Icon name="userCircle" />
                        ) : assessment.assignedTo.avatarBlobId ? (
                            <UserAvatar
                                avatarBlobId={
                                    assessment.assignedTo.avatarBlobId
                                }
                            />
                        ) : (
                            <UserAvatarPlaceholder
                                userName={assessment.assignedTo.name}
                            />
                        ),
                    },
                },
                {
                    name: 'Created',
                    value: {
                        content: <DateRenderer value={assessment.createdAt} />,
                        icon: <Icon name="calendar" />,
                    },
                },
                {
                    name: 'Reports',
                    value: {
                        content: assessment._count.reports,
                        icon: <Icon name="barChartSmall" />,
                    },
                },
            ]}
        />
    )
}
