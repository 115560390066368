import React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { themeVariables } from 'themes/themeVariables'
import { useAuth } from 'providers/AuthProvider'
import { Dropdown, DropdownContent } from './Dropdown'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from './Form/Select/SelectItemRenderer'
import { Icon } from './Icon'
import { Logo } from './Logo'

import { route } from '../utils/routes'
import { Separator } from './Separator'

const LogoContainer = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
`

const LogoChevron = styled(Icon)<{ $isOpen: boolean }>`
    color: ${themeVariables.colors.primary};
    transform: ${({ $isOpen }) =>
        $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 0.2s ease;
`

const StyledLogo = styled(Logo)`
    border-radius: 4px;
`

const StyledSeparator = styled(Separator)`
    margin: 8px 0;
`

export const SidebarDropdownMenu = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { logout } = useAuth()

    return (
        <Dropdown
            trigger={({ onClick, isOpen }) => (
                <LogoContainer onClick={onClick}>
                    <StyledLogo size="small" />
                    <span className={cx('large', 'emphasized')}>
                        {t('appName')}
                    </span>
                    <LogoChevron name="chevronDown" $isOpen={isOpen} />
                </LogoContainer>
            )}
        >
            <DropdownContent>
                <SelectBasicItem
                    onClick={() => {
                        navigate(route('profile'))
                    }}
                >
                    <SelectBasicItemIconWrap>
                        <Icon name="userCircle" />
                    </SelectBasicItemIconWrap>
                    Profile
                </SelectBasicItem>
                <StyledSeparator />
                <SelectBasicItem
                    onClick={() => {
                        logout(true)
                    }}
                >
                    <SelectBasicItemIconWrap>
                        <Icon name="signOut" />
                    </SelectBasicItemIconWrap>
                    Log out
                </SelectBasicItem>
            </DropdownContent>
        </Dropdown>
    )
}
