import React, { useCallback, useMemo, useState } from 'react'
import { toaster } from 'toasterhea'
import { string } from 'yup'
import cx from 'classnames'
import styled from 'styled-components'
import {
    CreatePermissionRequest,
    PermissionWithRelations,
    ResourcePointer,
} from 'silta-ai-backend'
import { BaseModal, BaseModalProps } from './BaseModal'
import { Button } from '../Button'
import { RejectionReason } from '../../utils/exceptions'
import { Icon } from '../Icon'
import { Layer } from '../../utils/layers'

import {
    EditorModalCloseButton,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'
import {
    usePermissionsQuery,
    useCurrentUserQuery,
    useTeamPermissionsForAllResourcesQuery,
    getUserPermissionsForResources,
} from '../../utils/queries'
import { SelectableOption } from '../../types/form'
import { InputWithAutoComplete } from '../Form/InputWithAutoComplete'
import { useTeamSelection } from '../../utils/useTeamSelection'
import { HorizontalLoadingIndicator } from '../HorizontalLoadingIndicator'
import { BaseButton } from '../BaseButton'
import { themeVariables } from '../../themes/themeVariables'
import { Dropdown, DropdownContent } from '../Dropdown'
import { SelectBasicItem } from '../Form/Select/SelectItemRenderer'
import { Separator } from '../Separator'
import {
    useCreatePermission,
    useRemovePermission,
    useUpdatePermission,
} from '../../utils/mutations'
import { confirmModal } from './ConfirmationModal'
import { Badge } from '../Badge'
import { LoadMoreButton } from '../LoadMoreButton'
import { RelatedResource } from '../../types/sharing'
import { useShareRelatedResourcesModal } from './ShareRelatedResourcesModal'

const InputContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 10px;
    & > *:first-child {
        flex: 1;
    }
`

const ShareButton = styled(Button)`
    padding: 3px 15px;
`

const RoleButton = styled(BaseButton)`
    background-color: ${themeVariables.colors.border};
    padding: 4px 8px;
    border-radius: 4px;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
`

const RotatingIcon = styled(Icon)`
    transition: transform 0.2s ease-in-out;
    &.isOpen {
        transform: rotate(180deg);
    }
`

const SelectItem = styled(SelectBasicItem)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const PermissionList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 420px;
    overflow-y: auto;
`

const PermissionEntryIconContainer = styled.div`
    background-color: ${themeVariables.colors.border};
    border-radius: 50%;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const PermissionEntryIcon = styled(Icon)`
    color: ${themeVariables.colors.secondary};
`

interface ShareResourceModalProps extends Omit<BaseModalProps, 'children'> {
    resourceName: string
    resourcePointer: ResourcePointer
    relatedResources?: RelatedResource[]
}

const emailValidator = string().email()

const ShareResourceModal = ({
    resourceName,
    resourcePointer,
    relatedResources,
    ...props
}: ShareResourceModalProps) => {
    const { currentTeamId } = useTeamSelection()
    const {
        data: permissions,
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = usePermissionsQuery(resourcePointer)
    const orderedPermissionsList = useMemo(() => {
        const usersPermissions =
            permissions
                ?.filter((permission) => {
                    return (
                        !!permission.invitedEmail ||
                        !!permission.team?.personalTeamOf
                    )
                })
                .sort((a, b) => {
                    const aName =
                        a.team?.personalTeamOf?.email || a.invitedEmail || ''
                    const bName =
                        b.team?.personalTeamOf?.email || b.invitedEmail || ''

                    return aName.localeCompare(bName)
                }) || []
        const teamsPermissions =
            permissions
                ?.filter((permission) => {
                    return !(
                        !!permission.invitedEmail ||
                        !!permission.team?.personalTeamOf
                    )
                })
                .sort((a, b) => {
                    const aName = a.team?.name || ''
                    const bName = b.team?.name || ''

                    return aName.localeCompare(bName)
                }) || []

        return [...teamsPermissions, ...usersPermissions]
    }, [permissions])
    const { mutate: createPermission, isPending: isCreatingPermission } =
        useCreatePermission()
    const { mutate: updatePermission, isPending: isUpdatingPermission } =
        useUpdatePermission()
    const { mutate: removePermission, isPending: isRemovingPermission } =
        useRemovePermission()
    const [currentlySelectedRole, setCurrentlySelectedRole] = useState<
        'Owner' | 'Editor' | 'Viewer'
    >('Viewer')
    const [inputValue, setInputValue] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [validationInProgress, setValidationInProgress] = useState(false)
    const [isCheckingNewUsersPermissions, setIsCheckingNewUsersPermissions] =
        useState(false)
    const { data: user } = useCurrentUserQuery()
    const { data: relatedResourcesPermissionsForCurrentTeam = {} } =
        useTeamPermissionsForAllResourcesQuery(
            relatedResources?.map((resource) => resource.pointer)
        )
    const relatedResourceIdsOwnedByCurrentTeam = useMemo(() => {
        return Object.keys(relatedResourcesPermissionsForCurrentTeam)
            .map((key) => ({
                id: key,
                permission: relatedResourcesPermissionsForCurrentTeam[key],
            }))
            .filter(({ permission }) => permission === 'Owner')
            .map(({ id }) => id)
    }, [relatedResourcesPermissionsForCurrentTeam])

    const getPermissionRecipientName = useCallback(
        (permission: PermissionWithRelations) =>
            permission.team?.personalTeamOf?.name ??
            permission.team?.name ??
            permission.invitedEmail ??
            '',
        []
    )

    const openShareRelatedResourcesModal = useShareRelatedResourcesModal()

    const validate = useCallback<
        (value: string) => Promise<{ isValid: boolean; errorMessage?: string }>
    >(
        async (value: string) => {
            const alreadyHasPermission = permissions?.find(
                (permission) =>
                    permission.teamId?.toLowerCase() === value.toLowerCase() ||
                    permission.invitedEmail?.toLowerCase() ===
                        value.toLowerCase() ||
                    permission.team?.personalTeamOf?.email?.toLowerCase() ===
                        value.toLowerCase()
            )

            if (alreadyHasPermission) {
                return {
                    isValid: false,
                    errorMessage: `${alreadyHasPermission.team?.personalTeamOf?.email ?? alreadyHasPermission.team?.name ?? alreadyHasPermission.invitedEmail} already has access`,
                }
            }

            if (emailValidator.isValidSync(value)) {
                return { isValid: true }
            }

            return {
                isValid: true,
            }
        },
        [permissions]
    )

    const userTeams = useMemo(() => {
        return user?.teamRoles
            .filter(
                (teamRole) =>
                    !(
                        teamRole.team.personalTeamOf !== null ||
                        teamRole.teamId === currentTeamId
                    )
            )
            .map((team) => ({
                name: team.team.name,
                id: team.team.id,
            }))
    }, [user, currentTeamId])

    const teamOptions = useMemo<SelectableOption[]>(() => {
        return (
            userTeams
                ?.filter((team) =>
                    team.name.toLowerCase().includes(inputValue.toLowerCase())
                )
                .filter((team) => {
                    return !permissions?.some(
                        (permission) => permission.teamId === team.id
                    )
                })
                .map((team) => ({
                    label: team.name,
                    value: team.id,
                })) || []
        )
    }, [userTeams, inputValue, permissions])

    const triggerOpenShareRelatedResourcesModal = useCallback(
        (permission: PermissionWithRelations) => {
            if (!relatedResources) {
                return
            }
            openShareRelatedResourcesModal(
                getPermissionRecipientName(permission),
                relatedResources,
                relatedResourceIdsOwnedByCurrentTeam,
                permission.teamId ?? undefined,
                permission.invitedEmail ?? undefined
            )
        },
        [
            openShareRelatedResourcesModal,
            relatedResources,
            relatedResourceIdsOwnedByCurrentTeam,
        ]
    )

    const handleSubmit = async (selectedValue?: string) => {
        setErrorMessage('')
        const valueToSubmit = selectedValue ?? inputValue
        if (valueToSubmit) {
            setValidationInProgress(true)
            const validationResult = await validate(valueToSubmit)
            setValidationInProgress(false)
            if (!validationResult.isValid) {
                setErrorMessage(
                    validationResult.errorMessage ?? 'Invalid value'
                )
                return
            }
            const payload: CreatePermissionRequest = {
                role: currentlySelectedRole,
                ...resourcePointer,
            }
            if (emailValidator.isValidSync(valueToSubmit)) {
                payload.email = valueToSubmit
            } else {
                payload.teamId = valueToSubmit
            }
            createPermission(payload, {
                onSuccess: async (permission) => {
                    setInputValue('')
                    if (!relatedResources) {
                        return
                    }

                    if (
                        relatedResourceIdsOwnedByCurrentTeam.length !==
                        relatedResources.length
                    ) {
                        triggerOpenShareRelatedResourcesModal(permission)
                        return
                    }
                    setIsCheckingNewUsersPermissions(true)
                    const permissions = await getUserPermissionsForResources(
                        relatedResources.map((resource) => resource.pointer),
                        permission.teamId ?? undefined,
                        permission.invitedEmail ?? undefined
                    )
                    if (
                        Object.values(permissions).some(
                            (permission) => !permission.role
                        )
                    ) {
                        triggerOpenShareRelatedResourcesModal(permission)
                    }
                    setIsCheckingNewUsersPermissions(false)
                },
                onError: () => {
                    setErrorMessage(
                        'Could not share access with the given email / team ID'
                    )
                    setIsCheckingNewUsersPermissions(false)
                },
            })
        }
    }

    return (
        <BaseModal {...props}>
            <EditorModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>
                        Share access to &quot;{resourceName}&quot;
                    </EditorModalTitle>
                    <EditorModalCloseButton
                        onClick={() =>
                            props.onReject?.(RejectionReason.CloseButton)
                        }
                    >
                        <Icon name="close2" />
                    </EditorModalCloseButton>
                </EditorModalTitleContainer>
                <EditorModalContentContainer className="p-b-20">
                    <HorizontalLoadingIndicator
                        loading={
                            isLoading ||
                            isFetchingNextPage ||
                            isCreatingPermission ||
                            isUpdatingPermission ||
                            isRemovingPermission ||
                            validationInProgress ||
                            isCheckingNewUsersPermissions
                        }
                    />
                    <InputContainer>
                        <InputWithAutoComplete
                            size="medium"
                            value={inputValue}
                            options={teamOptions}
                            onSelect={(value) => {
                                handleSubmit(value)
                            }}
                            disabled={isCreatingPermission}
                            errorMessage={errorMessage}
                            placeholder="Invite others by Team ID or email"
                            onInputChange={(value) => setInputValue(value)}
                            containerClassName="flex-1"
                            rightSideContent={
                                <Dropdown
                                    trigger={({ onClick, isOpen }) => (
                                        <RoleButton
                                            type="button"
                                            onClick={(event) => {
                                                event.stopPropagation()
                                                onClick()
                                            }}
                                            disabled={isCreatingPermission}
                                        >
                                            {currentlySelectedRole}
                                            <RotatingIcon
                                                className={cx({
                                                    isOpen,
                                                })}
                                                name="chevronDown"
                                            />
                                        </RoleButton>
                                    )}
                                >
                                    {(dismiss) => (
                                        <DropdownContent>
                                            <SelectItem
                                                type="button"
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    setCurrentlySelectedRole(
                                                        'Owner'
                                                    )
                                                    dismiss()
                                                }}
                                            >
                                                Owner
                                                {currentlySelectedRole ===
                                                    'Owner' && (
                                                    <Icon name="check" />
                                                )}
                                            </SelectItem>
                                            <SelectItem
                                                type="button"
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    setCurrentlySelectedRole(
                                                        'Editor'
                                                    )
                                                    dismiss()
                                                }}
                                            >
                                                Editor
                                                {currentlySelectedRole ===
                                                    'Editor' && (
                                                    <Icon name="check" />
                                                )}
                                            </SelectItem>
                                            <SelectItem
                                                type="button"
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    setCurrentlySelectedRole(
                                                        'Viewer'
                                                    )
                                                    dismiss()
                                                }}
                                            >
                                                Viewer
                                                {currentlySelectedRole ===
                                                    'Viewer' && (
                                                    <Icon name="check" />
                                                )}
                                            </SelectItem>
                                        </DropdownContent>
                                    )}
                                </Dropdown>
                            }
                        />
                        <ShareButton
                            $size="large"
                            onClick={async () => {
                                if (inputValue) {
                                    handleSubmit()
                                }
                            }}
                            type="button"
                            disabled={isCreatingPermission || !inputValue}
                        >
                            Share
                        </ShareButton>
                    </InputContainer>

                    {permissions && (
                        <div>
                            <p className={cx('secondary', 'm-t-30')}>
                                Who has access
                            </p>
                            <PermissionList>
                                {orderedPermissionsList.map((permission) => (
                                    <div
                                        className={cx(
                                            'd-flex',
                                            'align-items-center',
                                            'justify-content-between'
                                        )}
                                        key={permission.id}
                                    >
                                        <div
                                            className={cx(
                                                'd-flex',
                                                'align-items-center',
                                                'g-10'
                                            )}
                                        >
                                            <PermissionEntryIconContainer>
                                                <PermissionEntryIcon
                                                    name={
                                                        permission.team
                                                            ?.personalTeamOf ||
                                                        permission.invitedEmail
                                                            ? 'user'
                                                            : 'building'
                                                    }
                                                />
                                            </PermissionEntryIconContainer>
                                            <div
                                                className={cx(
                                                    'd-flex',
                                                    'g-10',
                                                    'align-items-center'
                                                )}
                                            >
                                                <span className="primary">
                                                    {permission.team
                                                        ?.personalTeamOf
                                                        ?.name ??
                                                        permission.team?.name ??
                                                        permission.invitedEmail}
                                                </span>
                                                {permission.invitedEmail && (
                                                    <Badge $variant="default">
                                                        Pending
                                                    </Badge>
                                                )}
                                            </div>
                                        </div>
                                        <Dropdown
                                            trigger={({ onClick, isOpen }) => (
                                                <BaseButton
                                                    onClick={(event) => {
                                                        event.stopPropagation()
                                                        onClick()
                                                    }}
                                                    className={cx(
                                                        'd-flex',
                                                        'align-items-center',
                                                        'g-5'
                                                    )}
                                                >
                                                    {permission.role}
                                                    <RotatingIcon
                                                        className={cx({
                                                            isOpen,
                                                        })}
                                                        name="chevronDown"
                                                    />
                                                </BaseButton>
                                            )}
                                        >
                                            {(dismiss) => (
                                                <DropdownContent>
                                                    <SelectItem
                                                        type="button"
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            dismiss()
                                                            if (
                                                                permission.role !==
                                                                'Owner'
                                                            ) {
                                                                updatePermission(
                                                                    {
                                                                        permissionId:
                                                                            permission.id,
                                                                        role: 'Owner',
                                                                    }
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        Owner
                                                        {permission.role ===
                                                            'Owner' && (
                                                            <Icon name="check" />
                                                        )}
                                                    </SelectItem>
                                                    <SelectItem
                                                        type="button"
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            dismiss()
                                                            if (
                                                                permission.role !==
                                                                'Editor'
                                                            ) {
                                                                updatePermission(
                                                                    {
                                                                        permissionId:
                                                                            permission.id,
                                                                        role: 'Editor',
                                                                    }
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        Editor
                                                        {permission.role ===
                                                            'Editor' && (
                                                            <Icon name="check" />
                                                        )}
                                                    </SelectItem>
                                                    <SelectItem
                                                        type="button"
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            dismiss()
                                                            if (
                                                                permission.role !==
                                                                'Viewer'
                                                            ) {
                                                                updatePermission(
                                                                    {
                                                                        permissionId:
                                                                            permission.id,
                                                                        role: 'Viewer',
                                                                    }
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        Viewer
                                                        {permission.role ===
                                                            'Viewer' && (
                                                            <Icon name="check" />
                                                        )}
                                                    </SelectItem>
                                                    <Separator
                                                        className={cx(
                                                            'm-t-5',
                                                            'm-b-5'
                                                        )}
                                                    />
                                                    {relatedResources &&
                                                        relatedResources.length >
                                                            0 && (
                                                            <SelectItem
                                                                type="button"
                                                                className={cx(
                                                                    'd-flex',
                                                                    'align-items-center',
                                                                    'justify-content-between'
                                                                )}
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.stopPropagation()
                                                                    dismiss()
                                                                    triggerOpenShareRelatedResourcesModal(
                                                                        permission
                                                                    )
                                                                }}
                                                            >
                                                                Related
                                                                permissions
                                                                <Icon name="chevronRight" />
                                                            </SelectItem>
                                                        )}
                                                    <SelectItem
                                                        type="button"
                                                        onClick={async (
                                                            event
                                                        ) => {
                                                            event.stopPropagation()
                                                            dismiss()
                                                            try {
                                                                const confirm =
                                                                    await confirmModal.pop(
                                                                        {
                                                                            confirmButtonText:
                                                                                'Remove',
                                                                            cancelButtonText:
                                                                                'Cancel',
                                                                            content:
                                                                                'Are you sure you want to remove access?',
                                                                            title: 'Remove access',
                                                                        }
                                                                    )
                                                                if (confirm) {
                                                                    removePermission(
                                                                        permission.id
                                                                    )
                                                                }
                                                            } catch (_) {
                                                                // do nothing
                                                            }
                                                        }}
                                                    >
                                                        Remove access
                                                    </SelectItem>
                                                </DropdownContent>
                                            )}
                                        </Dropdown>
                                    </div>
                                ))}
                                {hasNextPage && (
                                    <LoadMoreButton
                                        onClick={fetchNextPage}
                                        isLoading={isFetchingNextPage}
                                    />
                                )}
                            </PermissionList>
                        </div>
                    )}
                </EditorModalContentContainer>
            </EditorModalRoot>
        </BaseModal>
    )
}

const shareModal = toaster(ShareResourceModal, Layer.Modal)

export const useShareResourceModal = () => {
    return useCallback(
        async (
            resourceName: string,
            resourcePointer: ResourcePointer,
            relatedResources?: RelatedResource[]
        ) => {
            try {
                await shareModal.pop({
                    resourceName,
                    resourcePointer,
                    relatedResources,
                })
            } catch (e) {
                // do nothing
            }
        },
        []
    )
}
