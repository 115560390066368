import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { Icon } from './Icon'

export const DotdotdotButtonRoot = styled.button<{ $flip?: boolean }>`
    align-items: center;
    appearance: none;
    background: none;
    border: 0;
    color: ${themeVariables.palettes.neutral700};
    display: flex;
    height: 14px;
    justify-content: center;
    outline: 0 !important;
    width: 14px;

    &:active svg {
        transform: scale(0.95);
    }

    ${({ $flip = false }) =>
        $flip &&
        css`
            transform: rotate(90deg);
        `}
`

interface DotdotdotButtonProps
    extends Omit<HTMLAttributes<HTMLButtonElement>, 'children'> {
    flip?: boolean
    disabled?: boolean
}

export function DotdotdotButton({
    flip = false,
    ...props
}: DotdotdotButtonProps) {
    return (
        <DotdotdotButtonRoot {...props} $flip={flip}>
            <Icon name="dots" />
        </DotdotdotButtonRoot>
    )
}
