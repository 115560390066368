import { SearchDropdown } from 'components/dropdowns/SearchDropdown'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { themeVariables } from '../themes/themeVariables'

const TopBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 40px;
    border-bottom: 1px solid ${themeVariables.palettes.neutral300};
`

const InputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
`

interface ListTopBarProps {
    searchComponent?: ReactNode
}

const defaultSearchDropdown = <SearchDropdown />

export const ListTopBar = ({
    searchComponent = defaultSearchDropdown,
}: ListTopBarProps) => {
    return (
        <TopBarContainer>
            <InputContainer>{searchComponent}</InputContainer>
        </TopBarContainer>
    )
}
