import { BaseItem } from 'components/dropdowns/SearchDropdown/itemRenderers/BaseItem'
import React, { ButtonHTMLAttributes } from 'react'
import { useNavigate } from 'react-router'
import { Project } from 'silta-ai-backend'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { route } from 'utils/routes'

const Content = styled.div`
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const ProjectCustomId = styled.span`
    color: ${themeVariables.colors.secondary};
    margin-right: 0.75em;
`

interface ProjectItemProps
    extends Omit<
        ButtonHTMLAttributes<HTMLButtonElement>,
        'children' | 'value'
    > {
    value: Project
}

export function ProjectItem({
    value,
    onClick: onClickProp,
    ...rest
}: ProjectItemProps) {
    const navigate = useNavigate()

    return (
        <BaseItem
            {...rest}
            onClick={(e) => {
                onClickProp?.(e)

                navigate(route('project', value.id))
            }}
        >
            <Content>
                <ProjectCustomId>{value.customId}</ProjectCustomId>
                <strong>{value.name}</strong>
            </Content>
        </BaseItem>
    )
}
