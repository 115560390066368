import React, { useCallback, useState } from 'react'
import { toaster } from 'toasterhea'
import { Answer } from 'silta-ai-backend'
import { BaseModal, BaseModalProps } from './BaseModal'
import { Button } from '../Button'
import { RejectionReason } from '../../utils/exceptions'
import { Icon } from '../Icon'

import { Layer } from '../../utils/layers'
import { useUpdateAnswer } from '../../utils/mutations'
import { TextAreaField } from '../Form/TextAreaField'

import {
    EditorModalButtonsContainer,
    EditorModalCloseButton,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalSpinner,
    EditorModalSpinnerContainer,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'
import { useRunAnswerWithFaultyDocumentsHandling } from '../../utils/assessments'

interface EditQuestionModalProps extends Omit<BaseModalProps, 'children'> {
    answer: Answer
    onConfirm: (newQuestion: string) => Promise<void>
}

const EditQuestionModal = ({
    answer,
    onConfirm,
    ...props
}: EditQuestionModalProps) => {
    const [newQuestion, setNewQuestion] = useState(answer.question)
    const [isLoading, setIsLoading] = useState(false)

    return (
        <BaseModal {...props}>
            <EditorModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>Edit question</EditorModalTitle>
                    <EditorModalCloseButton
                        onClick={() =>
                            props.onReject?.(RejectionReason.CloseButton)
                        }
                    >
                        <Icon name="close2" />
                    </EditorModalCloseButton>
                </EditorModalTitleContainer>
                <EditorModalContentContainer>
                    <TextAreaField
                        value={newQuestion}
                        onChange={(e) => setNewQuestion(e.target.value)}
                        errorMessage={
                            !newQuestion ? 'Question is required' : undefined
                        }
                    />
                </EditorModalContentContainer>
                <EditorModalButtonsContainer>
                    <Button
                        $variant="secondary"
                        onClick={() =>
                            props.onReject?.(RejectionReason.CancelButton)
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setIsLoading(true)
                            onConfirm(newQuestion).then(() => {
                                setIsLoading(false)
                                props.onResolve?.()
                            })
                        }}
                        disabled={
                            !newQuestion ||
                            newQuestion === answer.question ||
                            isLoading
                        }
                    >
                        {isLoading ? (
                            <EditorModalSpinnerContainer>
                                Saving
                                <EditorModalSpinner name="spinner" />
                            </EditorModalSpinnerContainer>
                        ) : (
                            'Save and run question'
                        )}
                    </Button>
                </EditorModalButtonsContainer>
            </EditorModalRoot>
        </BaseModal>
    )
}

export const useEditQuestionModal = (answer: Answer) => {
    const updateAnswer = useUpdateAnswer(answer)
    const { handleRunAnswer } = useRunAnswerWithFaultyDocumentsHandling()

    const editModal = toaster(EditQuestionModal, Layer.Modal)

    return useCallback(async () => {
        try {
            await editModal.pop({
                answer,
                onConfirm: async (newQuestion) => {
                    await updateAnswer.mutateAsync({ question: newQuestion })
                    await handleRunAnswer(answer)
                },
            })
        } catch (e) {
            // do nothing
        }
    }, [editModal, updateAnswer])
}
